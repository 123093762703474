import { createSlice } from "@reduxjs/toolkit";

import {
  fetchAdminUsersList,
  fetchSafetyOfficersList,
} from "../actions/safetyOfficers";
import { InitialState } from "../../interfaces/ReduxState";
import { slices } from "../../constants/common";

// Define the initial state using that type
const adminUserState: InitialState = {
  loading: false,
  list: [],
  error: "",
};

const safetyOfficersState: InitialState = {
  loading: false,
  list: [],
  error: "",
};

//Slice for fetch admin users, safety officers list
const safetyOfficersReducer = createSlice({
  name: slices.safetyOfficers,

  initialState: {
    AdminUsers: adminUserState,
    SafetyOfficers: safetyOfficersState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAdminUsersList.pending, (state) => {
      state.AdminUsers.loading = true;
    });
    builder.addCase(fetchAdminUsersList.fulfilled, (state, action) => {
      state.AdminUsers.loading = false;
      state.AdminUsers.list = action.payload?.data;
      state.AdminUsers.error = "";
    });
    builder.addCase(fetchAdminUsersList.rejected, (state, action) => {
      state.AdminUsers.loading = false;
      state.AdminUsers.list = [];
      state.AdminUsers.error = action?.error?.message;
    });
    builder.addCase(fetchSafetyOfficersList.pending, (state) => {
      state.SafetyOfficers.loading = true;
    });
    builder.addCase(fetchSafetyOfficersList.fulfilled, (state, action) => {
      state.SafetyOfficers.loading = false;
      state.SafetyOfficers.list = action.payload?.data;
      state.SafetyOfficers.error = "";
    });
    builder.addCase(fetchSafetyOfficersList.rejected, (state, action) => {
      state.SafetyOfficers.loading = false;
      state.SafetyOfficers.list = [];
      state.SafetyOfficers.error = action?.error?.message;
    });
  },
});

export default safetyOfficersReducer.reducer;
