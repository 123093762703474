import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRallyPoints } from "../../services/rallyPointService";
import { FetchRallyPointsPayload } from "../../interfaces/RallyPoints";

// Action creator to call the get rally point function with a payload to invoke the API.
export const fetchRallyPointList = createAsyncThunk(
  "rallyPoints/fetchRallyPointList",
  (payload: FetchRallyPointsPayload) => {
    return getRallyPoints(payload);
  }
);
