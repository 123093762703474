const ICBack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <g
        id="Component_20_13"
        data-name="Component 20 – 13"
        transform="translate(28 28) rotate(180)"
      >
        <rect
          id="Rectangle_368"
          data-name="Rectangle 368"
          width="28"
          height="28"
          rx="5"
          fill="#86888e"
        />
        <path
          id="Path_2526"
          data-name="Path 2526"
          d="M6386.179,335.4l3.9,3.9-3.9,3.9"
          transform="translate(-6373.456 -324.639)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default ICBack;
