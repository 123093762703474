/**
 * @file   src\components\common\DeleteConfModal.tsx
 * @brief  Delete Confirmation.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Modal } from "react-bootstrap";
import { ViewDetails } from "../interfaces/Emergency";
import Strings from "../assets/strings/Strings.json";

const EmergencyViewDetailModal = (props: ViewDetails) => {
  const { issues, additionalDetails, show, onHide } = props;

  return (
    <Modal
      show={show}
      onHide={() => onHide()}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{Strings.EmergencyCheckIn.detailsTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="view-details">
        {issues && (
          <>
            <h6>{Strings.EmergencyCheckIn.issuesTitle}</h6> <p>{issues}</p>
          </>
        )}
        {additionalDetails && (
          <>
            <h6>{Strings.EmergencyCheckIn.addDetailsTitle}</h6>{" "}
            <p>{additionalDetails}</p>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EmergencyViewDetailModal;
