/**
 * @file   src\containers\Support.tsx
 * @brief  Support page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useEffect, useState } from "react";
import Strings from "../assets/strings/Strings.json";
import { Button, Col, Row } from "react-bootstrap";
import SLInput from "../components/SLInput";

import {
  HTTP_RESPONSE_STATUS_200,
  MAX_LENGTH_2000,
  USER_INFO_KEY,
} from "../constants/common";
import { toast } from "react-toastify";
import {
  ENTER_DESCRIPTION,
  SELECT_SUPPORT_REASON,
} from "../constants/validationMessages";
import {
  ReqPayload,
  SupportForm,
  SupportFormError,
} from "../interfaces/Support";
import { saveSupportDetails } from "../services/supportService";
import { ResponseObjects } from "../interfaces/AxiosResponse";
import { getLocalStorage, isMobileDevice } from "../helpers/common";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../constants/pageURLs";
const Support = () => {
  //Initial Form Data
  const initialData = {
    contactReason: "",
    desc: "",
  };

  //Initial Form Errors
  const initialErrors = {
    reasonError: "",
    descError: "",
  };
  const userInfoFromLS = getLocalStorage(USER_INFO_KEY);
  const navigate = useNavigate();
  // Support reason array list
  const supportReasons = [
    { class: "bug", text: Strings.Support.bug },
    { class: "billing", text: Strings.Support.billing },
    { class: "feedback", text: Strings.Support.feedback },
    { class: "other", text: Strings.Support.other },
  ];

  const [formData, setFormData] = useState<SupportForm>(initialData);
  const [formError, setFormError] = useState<SupportFormError>(initialErrors);

  // Description text box onchange event
  const onDescChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    setFormData((form) => ({
      ...form,
      desc: value,
    }));
  };

  // This useeffect is for restrict the support features
  useEffect(() => {
    if (userInfoFromLS?.isSuperAdminLogin) {
      navigate(pageURLs.dashboard);
    }
  }, []);

  // Check form validation while save.
  const validLinks = () => {
    setFormError(initialErrors);
    let isValid = true;
    const reason = formData?.contactReason?.trim();

    const description = formData?.desc?.trim();

    if (!description) {
      setFormError((error) => ({
        ...error,
        descError: ENTER_DESCRIPTION,
      }));
      isValid = false;
    }
    if (!reason) {
      setFormError((error) => ({
        ...error,
        reasonError: SELECT_SUPPORT_REASON,
      }));
      isValid = false;
    }
    return isValid;
  };

  //Custom toast message for submit ticket success
  // const successMessage = () => {
  //   return `hello  test`;
  // };
  const successMessage = () => (
    <div>
      {Strings.Support.successMessage}
      <a href={"mailto:" + Strings.Support.supportMail}>
        {Strings.Support.supportMail}
      </a>{" "}
      or call {isMobileDevice() && <br />}
      <a href={Strings.Support.contactMobile}>
        {Strings.Support.supportContact}
      </a>
    </div>
  );

  // Handle sent button click
  const handleSent = async () => {
    const isValid = validLinks();

    if (isValid) {
      //check the logged user is a super admin user
      if (!userInfoFromLS?.isSuperAdminLogin) {
        const payload: ReqPayload = {
          reason: formData?.contactReason,
          message: formData?.desc,
        };
        const response: ResponseObjects = await saveSupportDetails(payload);
        if (response?.status === HTTP_RESPONSE_STATUS_200) {
          toast.success(successMessage);
          clearForm();
        } else {
          toast.error(response?.message);
        }
      } else {
        alert("Only admins can sent a support ticket.");
      }
    }
  };

  // Clear form after successful sent response.
  const clearForm = () => {
    setFormData(initialData);
  };

  //Load contact reason list
  const loadReasons = () => {
    const reasonList = supportReasons.map(
      (reason: { class: string; text: string }, index: number) => {
        const className =
          formData.contactReason.trim() === reason.text
            ? `support-links ${reason.class} d-flex align-items-center active`
            : `support-links ${reason.class} d-flex align-items-center`;

        return (
          <Col
            md="6"
            xl="3"
            key={index}
            onClick={() => onSelectReason(reason.text)}
          >
            <div className={className}>{reason.text}</div>
          </Col>
        );
      }
    );
    return reasonList;
  };

  //Handle state updation on selection of a reason
  const onSelectReason = (selectedReason: string) => {
    setFormData((form) => ({
      ...form,
      contactReason: selectedReason,
    }));
  };

  return (
    <>
      <Row className="tilte-wrapper">
        <Col xl="12">
          <h1>
            <span className="text-uppercase">
              {Strings.Common.InnerSubTitle}
            </span>
            <br />
            {Strings.Support.Title}
          </h1>
        </Col>
      </Row>
      <div className="content-wrapper dashboard welcome">
        <ul className="mb-0">
          <li className="mt-0">{Strings.Support.Note}</li>
        </ul>
      </div>
      <h3>{Strings.Support.Subtitle}</h3>
      <Row
        className={
          formError.reasonError
            ? "support-links-wrap error"
            : "support-links-wrap"
        }
      >
        {loadReasons()}
        {formError.reasonError && (
          <div className="error-msg">{formError.reasonError}</div>
        )}
      </Row>
      <div className="content-wrapper">
        <Row>
          <Col xl="8" md="12">
            <SLInput
              name="description"
              id="desc"
              type="text"
              as="textarea"
              placeholder="Type your message..."
              value={formData.desc}
              onChange={onDescChange}
              length={formData.desc?.length}
              maxLength={MAX_LENGTH_2000}
              errorMessage={formError.descError}
              label={Strings.Support.message}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12" xl="8" className="text-end mt-3 btn-wrap">
            <Button onClick={handleSent}>{Strings.Support.sentBtn}</Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Support;
