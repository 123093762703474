import * as apiService from "./apiService";
import * as endPoints from "../constants/endPoints";
import { HTTP_GET, HTTP_POST } from "../constants/common";
import { buildResponse } from "../helpers/common";
import {
  AfterHourList,
  AfterHourSessionList,
  AfterHourDetails,
  UpdateAfterHourSession,
  DisableAfterHourCheckIn,
} from "../interfaces/AfterHour";

//Api call for fetch after hour lists for all the users in the organisation.
export async function afterHourListing(payload?: AfterHourList) {
  return await apiService.apiCall(
    endPoints.GET_AFTER_HOUR_LIST,
    HTTP_GET,
    payload
  );
}
//Api call for fetch after hour details for particular user, while clicking an item from the after hour list.
export async function afterHourDetails(payload?: AfterHourDetails) {
  return await apiService.apiCall(
    endPoints.GET_AFTER_HOUR_DETAILS_ID + `${payload.id}`,
    HTTP_GET,
    {}
  );
}

//Api call for fetch after hour session list(response from each notification) for particular user.
export async function afterHourSessionList(payload?: AfterHourSessionList) {
  return await apiService.apiCall(
    endPoints.GET_AFTER_HOUR_SESSION_LIST + `${payload.id}/sessions`,
    HTTP_GET,
    { pageNo: payload.pageNo, pageSize: payload.pageSize }
  );
}

//Api call for update notes added for a user.
export async function updateAfterHourNotes(payload?: UpdateAfterHourSession) {
  const res = await apiService.apiCall(
    endPoints.UPDATE_AFTER_HOUR_SESSION_LIST,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}

//Api call for disable after hour checkin session for a user.
export async function disableAfterHourCheckIn(
  payload?: DisableAfterHourCheckIn
) {
  const res = await apiService.apiCall(
    endPoints.DISABLE_AFTER_HOUR_CHECK_IN,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}

//Api call for get work alone mode check in details for CSV download.
export async function getWorkAloneModeDetailsForCSVDownload(
  payload: AfterHourDetails
) {
  const res = await apiService.apiCall(
    endPoints.GET_AFTER_HOUR_DETAILS_ID + `${payload.id}/export`,
    HTTP_GET,
    payload
  );
  return buildResponse(res);
}
