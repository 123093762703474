/**
 * @file   src\components\common\TableHeader.tsx
 * @brief  Table Columns.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

const TableHeader = (props: any) => {
  const { columns } = props;

  return (
    <thead>
      <tr>
        {columns &&
          columns?.length > 0 &&
          columns.map((colmn: any, key: any) => (
           colmn?.thClass ? <th key={key} className={colmn?.thClass}>{colmn?.title}</th>: 
           <th key={key}>{colmn?.title}</th>
          ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
