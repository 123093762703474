import { createSlice } from "@reduxjs/toolkit";
import { fetchDashboardMetrics } from "../actions/dashboard";
import { InitialState } from "../../interfaces/ReduxState";
import { slices } from "../../constants/common";

//Initial state
const initialState: InitialState = {
  loading: false,
  data: [],
  error: "",
};

//Slice for fetch dashboard metrics
const dashboard = createSlice({
  name: slices.dashboard,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDashboardMetrics.pending, (state) => {
      state.data = [];
      state.loading = true;
    });
    builder.addCase(fetchDashboardMetrics.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload?.data;
      state.error = "";
    });
    builder.addCase(fetchDashboardMetrics.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action?.error?.message;
    });
  },
});

export default dashboard.reducer;
