import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  afterHourListing,
  afterHourDetails,
  afterHourSessionList,
} from "../../services/afterHourServices";
import {
  AfterHourList,
  AfterHourSessionList,
  AfterHourDetails,
} from "../../interfaces/AfterHour";

// Action creator to call the afterHourListing function with a payload to invoke the after-hour listing API .
export const fetchAfterHourListing = createAsyncThunk(
  "anonymous/fetchAfterHourListing",
  (payload: AfterHourList) => {
    return afterHourListing(payload);
  }
);

// Action creator to call the afterHourDetails function with a payload to invoke the after-hour details API.
export const fetchAfterHourDetails = createAsyncThunk(
  "anonymous/fetchAfterHourDetails",
  (payload: AfterHourDetails) => {
    return afterHourDetails(payload);
  }
);

// Action creator to call the afterHourSessionList function with a payload to invoke the after-hour session listing API.
export const fetchAfterHourSessionList = createAsyncThunk(
  "anonymous/fetchAfterHourSessionList",
  (payload: AfterHourSessionList) => {
    return afterHourSessionList(payload);
  }
);
