const ICEmergencyNoticication = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g
        id="Group_494"
        data-name="Group 494"
        transform="translate(-917.503 -21.287)"
      >
        <rect
          id="Rectangle_464"
          data-name="Rectangle 464"
          width="22"
          height="22"
          transform="translate(917.503 21.287)"
          fill="none"
        />
        <path
          id="Vector_4_-_Outline"
          data-name="Vector_4 - Outline"
          d="M.464,5.976a.75.75,0,0,1-.75-.75A6.3,6.3,0,0,1,2.421.082a.75.75,0,0,1,.86,1.229A4.8,4.8,0,0,0,1.214,5.227.75.75,0,0,1,.464,5.976Z"
          transform="translate(920.536 23.676)"
          fill="#fff"
        />
        <path
          id="Vector_5_-_Outline"
          data-name="Vector_5 - Outline"
          d="M12.542,5.98a.75.75,0,0,1-.75-.75A4.847,4.847,0,0,0,9.777,1.306.75.75,0,1,1,10.652.087a6.351,6.351,0,0,1,2.64,5.142.75.75,0,0,1-.75.75Z"
          transform="translate(923.48 23.143) rotate(3)"
          fill="#fff"
        />
        <path
          id="Path_2580"
          data-name="Path 2580"
          d="M306.395,47.89h-5.638a1.441,1.441,0,0,1-1.524-1.211,1.482,1.482,0,0,1,.941-1.6.713.713,0,0,0,.46-.576c.117-.737.233-1.473.328-2.21.117-.883.146-1.78.321-2.655a4.678,4.678,0,0,1,1.634-2.772c.292-.241.642-.408.956-.62a1.237,1.237,0,0,0,.343-.3,2.687,2.687,0,0,1,4.384,0,.857.857,0,0,0,.321.284,4.787,4.787,0,0,1,2.619,3.4,17.871,17.871,0,0,1,.263,1.977c.109.883.2,1.772.314,2.655a1,1,0,0,0,.613.853,1.4,1.4,0,0,1,.817,1.714,1.375,1.375,0,0,1-1.094,1,2.208,2.208,0,0,1-.489.036h-5.565Z"
          transform="translate(622.148 -11.243)"
          fill="#fff"
        />
        <path
          id="Path_2581"
          data-name="Path 2581"
          d="M305.25,54.869h3.88c.554-.007,1.1,0,1.685,0a2.689,2.689,0,0,1-1.218,2.29,2.629,2.629,0,0,1-2.969.095,2.778,2.778,0,0,1-1.379-2.385Z"
          transform="translate(620.513 -16.667)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default ICEmergencyNoticication;
