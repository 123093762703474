/**
 * @file   src\components\TroubleLoginEmail.tsx
 * @brief  Trouble Login Form component.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Button } from "react-bootstrap";
import Strings from "../assets/strings/Strings.json";
import SLInput from "./SLInput";
import { ENTER_KEY_CODE, MAX_LENGTH_50 } from "../constants/common";

const TroubleLoginEmail = (props: any) => {
  const { onTextChange, error, value, handleSubmit } = props;

  // This function will trigger on enter key press after fill mandatory details
  const submitFormOnKeyEnter = (event: any) => {
    if (event.keyCode === ENTER_KEY_CODE) {
      event.preventDefault();
      handleSubmit();
    }
  };
  return (
    <>
      <SLInput
        id="email"
        name="email"
        type="email"
        placeholder={Strings.TroubleLogin.SendMail.Label1}
        onChange={onTextChange}
        errorMessage={error}
        value={value}
        onKeyDown={submitFormOnKeyEnter}
        maxLength={MAX_LENGTH_50}
      />
      <Button
        variant="primary"
        className="w-100"
        onClick={() => handleSubmit()}
      >
        {props?.isLoad ? (
          <output className="spinner-border sm"></output>
        ) : (
          Strings.TroubleLogin.SendMail.Button
        )}
      </Button>
    </>
  );
};
export default TroubleLoginEmail;
