import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import appReducers from "./appReducers";

const loggerMiddleware = createLogger({ duration: true, diff: false });

const middleware: any = [loggerMiddleware];

const rootReducer = (state: any, action: any) => {
  //For clear store while logout
  if (action.type?.includes("user/logout")) {
    state = undefined;
  }

  return appReducers(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
