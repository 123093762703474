import * as apiService from "./apiService";
import * as endPoints from "../constants/endPoints";
import { HTTP_GET, HTTP_POST } from "../constants/common";
import { buildResponse } from "../helpers/common";
import {
  CreateUpdateReqPayload,
  DeleteRallyPointPayload,
  FetchRallyPointsPayload,
} from "../interfaces/RallyPoints";

//Api call for get rally points list
export async function getRallyPoints(payload?: FetchRallyPointsPayload) {
  return await apiService.apiCall(
    endPoints.GET_RALLY_POINTS_API,
    HTTP_GET,
    payload
  );
}

//Api call for create a rally point
export async function createRallyPoint(payload?: CreateUpdateReqPayload) {
  const res = await apiService.apiCall(
    endPoints.ADD_RALLY_POINT_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}

//Api call for update a rally point
export async function updateRallyPoint(payload?: CreateUpdateReqPayload) {
  const res = await apiService.apiCall(
    endPoints.UPDATE_RALLY_POINT_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}

//Api call for delete a rally point
export async function deleteRallyPoint(payload?: DeleteRallyPointPayload) {
  const res = await apiService.apiCall(
    endPoints.DELETE_RALLY_POINT_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}
