/**
 * @file   src\components\SideBar.tsx
 * @brief  Sidebar component.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import "./SideBar.scss";
import { NavLink, useLocation } from "react-router-dom";
import Strings from "../assets/strings/Strings.json";
import ICDashboard from "../assets/img/icons/Dashboard";
import ICEmergency from "../assets/img/icons/Emergency";
import ICIncidentReport from "../assets/img/icons/Incident";
import ICAfterHours from "../assets/img/icons/AfterHours";
import ICAnonymous from "../assets/img/icons/Anonymous";
import ICUser from "../assets/img/icons/User";
import ICSafetyOfficer from "../assets/img/icons/SafetyOfficer";
import ICImportantLinks from "../assets/img/icons/ImportantLinks";
import ICOrganization from "../assets/img/icons/Organization";
import { pageURLs } from "../constants/pageURLs";
import ICMenuExpand from "../assets/img/icons/MenuExpand";
import ICSupport from "../assets/img/icons/Support";
import OverLayToolTip from "./common/OverLayToolTip";
import { Scrollbars } from "react-custom-scrollbars-2";
import ICRallyPoints from "../assets/img/icons/RallyPoints";
import { getLocalStorage } from "../helpers/common";
import { USER_INFO_KEY } from "../constants/common";

const SideBar = (props: any) => {
  const { setExpand, isExpanded, isResponsiveMode } = props;
  const SIDEBAR_POS_FIXED_CLASS = "sidebar position-fixed";
  const SLIDE_RIGHT = " slide-right";
  const EXPAND = " expand";
  const userDetails = getLocalStorage(USER_INFO_KEY);
  const mainDivClass = () => {
    if (isExpanded) {
      if (isResponsiveMode) {
        return `${SIDEBAR_POS_FIXED_CLASS} ${SLIDE_RIGHT}`;
      } else {
        return `${SIDEBAR_POS_FIXED_CLASS} ${EXPAND}`;
      }
    }
    return SIDEBAR_POS_FIXED_CLASS;
  };
  const location = useLocation();
  const pathName = location?.pathname?.toLowerCase();

  return (
    <div className={mainDivClass()}>
      <div className="sidebar-inner">
        <Scrollbars style={{ height: "100%" }}>
          <NavLink
            to={pageURLs.dashboard}
            className="d-flex align-items-center"
          >
            <span className="icon-wrap">
              <OverLayToolTip
                title={Strings.SideBar.dashboard}
                isExpanded={isExpanded}
                Icon={<ICDashboard />}
              />
            </span>
            <span className="link-item">{Strings.SideBar.dashboard}</span>
          </NavLink>
          <NavLink
            to={pageURLs.emergencyCheckIn}
            className={
              pathName?.includes(pageURLs.emergencyCheckInDetails)
                ? "d-flex align-items-center active"
                : "d-flex align-items-center"
            }
          >
            <span className="icon-wrap">
              <OverLayToolTip
                title={Strings.SideBar.emergencyChkIn}
                isExpanded={isExpanded}
                Icon={<ICEmergency />}
              />
            </span>
            <span className="link-item">{Strings.SideBar.emergencyChkIn}</span>
          </NavLink>
          <NavLink
            to={pageURLs.incidentReports}
            className="d-flex align-items-center"
          >
            <span className="icon-wrap">
              <OverLayToolTip
                title={Strings.SideBar.incidentRpt}
                isExpanded={isExpanded}
                Icon={<ICIncidentReport />}
              />
            </span>
            <span className="link-item">{Strings.SideBar.incidentRpt}</span>
          </NavLink>
          <NavLink
            to={pageURLs.workAloneModeIn}
            className={
              pathName?.includes(pageURLs.workAloneModeInDetails)
                ? "d-flex align-items-center active"
                : "d-flex align-items-center"
            }
          >
            <span className="icon-wrap">
              <OverLayToolTip
                title={Strings.SideBar.afterHourChkIn}
                isExpanded={isExpanded}
                Icon={<ICAfterHours />}
              />
            </span>
            <span className="link-item">{Strings.SideBar.afterHourChkIn}</span>
          </NavLink>
          <NavLink
            to={pageURLs.anonymoussuggestionbox}
            className="d-flex align-items-center"
          >
            <span className="icon-wrap">
              <OverLayToolTip
                title={Strings.SideBar.anonymousSuggestion}
                isExpanded={isExpanded}
                Icon={<ICAnonymous />}
              />
            </span>
            <span className="link-item">
              {Strings.SideBar.anonymousSuggestion}
            </span>
          </NavLink>
          <NavLink
            to={pageURLs.manageusers}
            className="align-items-center d-flex d-xl-none"
          >
            <span className="icon-wrap">
              <OverLayToolTip
                title={Strings.SideBar.users}
                isExpanded={isExpanded}
                Icon={<ICUser />}
              />
            </span>
            <span className="link-item">{Strings.SideBar.users}</span>
          </NavLink>
          <NavLink
            to={pageURLs.safetyOfficers}
            className="d-flex align-items-center"
          >
            <span className="icon-wrap">
              <OverLayToolTip
                title={Strings.SideBar.safetyOfficers}
                isExpanded={isExpanded}
                Icon={<ICSafetyOfficer />}
              />
            </span>
            <span className="link-item">{Strings.SideBar.safetyOfficers}</span>
          </NavLink>

          <NavLink
            to={pageURLs.importantlinks}
            className="d-flex align-items-center"
          >
            <span className="icon-wrap">
              <OverLayToolTip
                title={Strings.SideBar.impLinks}
                isExpanded={isExpanded}
                Icon={<ICImportantLinks />}
              />
            </span>
            <span className="link-item">{Strings.SideBar.impLinks}</span>
          </NavLink>
          <NavLink
            to={pageURLs.rallyPoints}
            className="d-flex align-items-center"
          >
            <span className="icon-wrap">
              <OverLayToolTip
                title={Strings.SideBar.rallyPoints}
                isExpanded={isExpanded}
                Icon={<ICRallyPoints />}
              />
            </span>
            <span className="link-item">{Strings.SideBar.rallyPoints}</span>
          </NavLink>
          <NavLink
            to={pageURLs.organization}
            className="align-items-center d-flex d-xl-none"
          >
            <span className="icon-wrap">
              <OverLayToolTip
                title={Strings.SideBar.organization}
                isExpanded={isExpanded}
                Icon={<ICOrganization />}
              />
            </span>
            <span className="link-item">{Strings.SideBar.organization}</span>
          </NavLink>
          {!userDetails?.isSuperAdminLogin && (
            <NavLink
              to={pageURLs.support}
              className="d-flex align-items-center support"
            >
              <span className="icon-wrap">
                <OverLayToolTip
                  title={Strings.SideBar.support}
                  isExpanded={isExpanded}
                  Icon={<ICSupport />}
                />
              </span>
              <span className="link-item">{Strings.SideBar.support}</span>
            </NavLink>
          )}
        </Scrollbars>
      </div>
      <span
        className="menu-slide d-flex align-items-center justify-content-center position-absolute collapse"
        onClick={() => setExpand(!isExpanded)}
      >
        <ICMenuExpand />
      </span>
    </div>
  );
};

export default SideBar;
