/**
 * @file   src\components\common\DeleteConfModal.tsx
 * @brief  Delete Confirmation.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import Strings from "../../assets/strings/Strings.json";
import { Button, Modal } from "react-bootstrap";
import { DeleteModal } from "../../interfaces/DeleteModal";

const DeleteConfModal = (props: DeleteModal) => {
  const { show, onHide, title, desc, handleDelete, isDisabled } = props;

  return (
    <Modal
      show={show}
      onHide={() => onHide()}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton={title !== ""}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="alert-info text-center">{desc}</Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={() => onHide()}>
          {Strings.deleteModal.cancelBtn}
        </Button>
        <Button
          variant="primary"
          disabled={isDisabled}
          onClick={() => handleDelete()}
        >
          {Strings.deleteModal.confBtn}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfModal;
