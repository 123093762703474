const ICAnonymous = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g id="Group_470" data-name="Group 470" transform="translate(-108 -1353)">
        <path
          id="Path_2554"
          data-name="Path 2554"
          d="M0,0H30V30H0Z"
          transform="translate(108 1353)"
          fill="#fff"
          opacity="0"
        />
        <path
          id="Path_2553"
          data-name="Path 2553"
          d="M25.646,8.266a.983.983,0,0,0-.069-.341c-.011-.028-.024-.054-.037-.081a.92.92,0,0,0-.046-.1L21.483,1.392A2.848,2.848,0,0,0,19.1,0H6.522A2.819,2.819,0,0,0,4.175,1.376L.154,7.743a1.013,1.013,0,0,0-.046.1c-.013.027-.026.053-.037.081A.978.978,0,0,0,0,8.266l0,.011V22.829a2.822,2.822,0,0,0,2.819,2.82h20.01a2.822,2.822,0,0,0,2.819-2.82V8.277l0-.011M2.814,7.277,5.887,2.41A.815.815,0,0,1,6.549,2h5.275V7.277ZM17.736,18.985a1,1,0,0,1-1,1H14.7a1,1,0,0,0-.689.275l-2.172,2.062V20.985a1,1,0,0,0-1-1H8.912a1,1,0,0,1-1-1v-4.55a1,1,0,0,1,1-1h7.824a1,1,0,0,1,1,1ZM13.824,7.277V2h5.249a.812.812,0,0,1,.688.41l.021.034,3.052,4.833Z"
          transform="translate(110 1355)"
        />
      </g>
    </svg>
  );
};

export default ICAnonymous;
