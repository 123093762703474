import { ReqPayload } from "../interfaces/Support";
import * as apiService from "./apiService";
import * as endPoints from "../constants/endPoints";
import { HTTP_POST } from "../constants/common";
import { buildResponse } from "../helpers/common";

//Api call for update support details(submit a ticket).
export async function saveSupportDetails(payload?: ReqPayload) {
  const res = await apiService.apiCall(
    endPoints.SUPPORT_TICKET,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}
