const ICMenuExpand = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.222"
      height="10.712"
      viewBox="0 0 5.222 10.712"
    >
      <path
        id="Path_19"
        data-name="Path 19"
        d="M77.312,110.722a1,1,0,0,1-.822-1.568l2.614-3.788-2.614-3.788a1,1,0,0,1,1.646-1.136l3.4,4.924-3.4,4.924A1,1,0,0,1,77.312,110.722Z"
        transform="translate(-76.313 -100.01)"
      />
    </svg>
  );
};

export default ICMenuExpand;
