const ICCopy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g id="ic-copy" transform="translate(-979.576 -230.576)">
        <rect
          id="Rectangle_455"
          data-name="Rectangle 455"
          width="22"
          height="22"
          transform="translate(979.576 230.576)"
          fill="none"
        />
        <path
          id="Union"
          d="M3.078,0A3.078,3.078,0,0,0,0,3.078v10.26a1.026,1.026,0,1,0,2.052,0V3.078A1.026,1.026,0,0,1,3.078,2.052h10.26a1.026,1.026,0,1,0,0-2.052ZM6.156,4.1A2.052,2.052,0,0,0,4.1,6.156V17.1a2.052,2.052,0,0,0,2.052,2.052H17.1A2.052,2.052,0,0,0,19.151,17.1V6.156A2.052,2.052,0,0,0,17.1,4.1Z"
          transform="translate(981 232)"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default ICCopy;
