/**
 * @file   src\components\NoteModal.tsx
 * @brief  Common component for add/Edit notes in different pages.
 * @date   Mar, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { Modal, Button } from "react-bootstrap";
import Strings from "../assets/strings/Strings.json";
import SLInput from "./SLInput";
import { MAX_LENGTH_2000 } from "../constants/common";
import { NoteModal } from "../interfaces/Note";

const AddEditNoteModal = (props: NoteModal) => {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      centered
      backdrop={true}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{Strings.IncidentReport.Modal.Title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="notes">
        <SLInput
          name="notes"
          id="notes"
          as="textarea"
          value={props.notes}
          onChange={props.onTextNoteChange}
          maxLength={MAX_LENGTH_2000}
          length={props.notes?.length}
          errorMessage={props.errorMessage}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={props.handleSave}
          className="mt-4"
          disabled={props.saveClicked}
        >
          {props.saveClicked ? (
            <output className="spinner-border spinner-border-sm"></output>
          ) : (
            Strings.IncidentReport.Modal.saveBtn
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEditNoteModal;
