const ICSearch = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.965"
      height="27.965"
      viewBox="0 0 27.965 27.965"
    >
      <g id="search" transform="translate(-0.464 -0.464)">
        <path
          id="round"
          d="M14.447,28.429A13.982,13.982,0,1,0,.464,14.447,13.982,13.982,0,0,0,14.447,28.429Z"
          transform="translate(0 0)"
          fill="#e1ae29"
          opacity="0.43"
        />
        <path
          id="Vector_2"
          d="M9.092,15.47A6.378,6.378,0,1,1,15.47,9.092,6.385,6.385,0,0,1,9.092,15.47Zm0-10.756A4.378,4.378,0,1,0,13.47,9.092,4.383,4.383,0,0,0,9.092,4.714Z"
          transform="translate(4.279 4.279)"
        />
        <path
          id="Vector_3"
          d="M11.4,12.4a1,1,0,0,1-.707-.293L6.972,8.386A1,1,0,0,1,8.386,6.972l3.721,3.721A1,1,0,0,1,11.4,12.4Z"
          transform="translate(9.499 9.499)"
        />
      </g>
    </svg>
  );
};

export default ICSearch;
