const ICImportantLinks = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g id="Group_469" data-name="Group 469" transform="translate(2 2)">
        <path
          id="Path_2551"
          data-name="Path 2551"
          d="M0,0H30V30H0Z"
          transform="translate(-2 -2)"
          fill="#fff"
          opacity="0"
        />
        <path
          id="Path_2549"
          data-name="Path 2549"
          d="M13,0A13,13,0,1,0,26,13,13,13,0,0,0,13,0m.873,17.7-.917,1.02a3.3,3.3,0,0,1-.365.363,3.471,3.471,0,0,1-2.27.84c-.09,0-.181,0-.272-.01A3.482,3.482,0,0,1,7.659,18.7a4.245,4.245,0,0,1,0-5.523l1.623-1.805a3.513,3.513,0,0,1,5.63.452,1,1,0,1,1-1.681,1.084,1.616,1.616,0,0,0-1.211-.738,1.541,1.541,0,0,0-1.24.526L9.161,14.5a2.231,2.231,0,0,0,.013,2.894,1.5,1.5,0,0,0,2.118.165,1.3,1.3,0,0,0,.162-.162l.933-1.037A1,1,0,1,1,13.873,17.7m4.466-4.951-1.622,1.805a3.55,3.55,0,0,1-2.662,1.2c-.08,0-.159,0-.239-.008A3.619,3.619,0,0,1,11.087,14.1a1,1,0,0,1,1.68-1.085,1.621,1.621,0,0,0,1.212.737,1.549,1.549,0,0,0,1.24-.528l1.621-1.8a2.233,2.233,0,0,0-.013-2.895,1.5,1.5,0,0,0-2.118-.165,1.549,1.549,0,0,0-.162.162l-.938,1.039a1,1,0,1,1-1.485-1.339L13.046,7.2a3.584,3.584,0,0,1,.36-.358,3.5,3.5,0,0,1,4.936.377,4.245,4.245,0,0,1,0,5.523"
        />
      </g>
    </svg>
  );
};

export default ICImportantLinks;
