const ICAddBulkUsers = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.493"
      height="19.821"
      viewBox="0 0 29.493 19.821"
    >
      <g id="ic_users" transform="translate(-1268 -121)">
        <path
          id="Vector"
          d="M8.9,13.161A6.332,6.332,0,1,0,2.57,6.83,6.332,6.332,0,0,0,8.9,13.161Z"
          transform="translate(1268.345 120.927)"
        />
        <path
          id="Vector_2"
          d="M19.254,15.095a9.71,9.71,0,0,0-18.493,0Z"
          transform="translate(1267.239 125.727)"
        />
        <path
          id="Vector_3"
          d="M19.254,15.095a9.71,9.71,0,0,0-18.493,0Z"
          transform="translate(1267.239 125.727)"
          fill="none"
        />
        <path
          id="Vector_4"
          d="M5.962,7.281A3.392,3.392,0,1,0,2.57,3.889,3.392,3.392,0,0,0,5.962,7.281Z"
          transform="translate(1271.285 123.868)"
        />
        <path
          id="Line_4"
          data-name="Line 4"
          d="M1,11H-1V0H1Z"
          transform="translate(1291.994 121)"
        />
        <path
          id="Line_5"
          data-name="Line 5"
          d="M1,11H-1V0H1Z"
          transform="translate(1297.494 126.5) rotate(90)"
        />
      </g>
    </svg>
  );
};

export default ICAddBulkUsers;
