const ICAfterHours = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g id="Group_460" data-name="Group 460" transform="translate(-95 -1266)">
        <rect
          id="Rectangle_436"
          data-name="Rectangle 436"
          width="30"
          height="30"
          transform="translate(95 1266)"
          fill="#fff"
          opacity="0"
        />
        <path
          id="Path_41"
          data-name="Path 41"
          d="M0,12.5A12.5,12.5,0,1,0,12.5,0,12.548,12.548,0,0,0,0,12.5M4.287,6.966l.863-.958a.285.285,0,0,1,.4-.02L12.425,12.2l3.943-4.067a.28.28,0,0,1,.4-.007l.926.9a.284.284,0,0,1,.007.4l-5,5.151a.278.278,0,0,1-.2.087.284.284,0,0,1-.19-.072l-8-7.229a.273.273,0,0,1-.092-.193.281.281,0,0,1,.072-.2"
          transform="translate(97 1269)"
        />
      </g>
    </svg>
  );
};

export default ICAfterHours;
