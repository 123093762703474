const ICLinkedin = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
    >
      <g id="ICLinkedin" transform="translate(-1297.855 -111.84)">
        <rect
          id="Rectangle_406"
          data-name="Rectangle 406"
          width="18"
          height="17"
          transform="translate(1297.855 111.84)"
          fill="none"
        />
        <g id="Group_418" data-name="Group 418">
          <rect
            id="Rectangle_16"
            data-name="Rectangle 16"
            width="3.47"
            height="10.421"
            transform="translate(1298.979 117.637)"
            fill="#fff"
          />
          <path
            id="Path_23"
            data-name="Path 23"
            d="M126.838,6.589a3.734,3.734,0,0,0-2.892-1.194,4.189,4.189,0,0,0-1.2.163,2.747,2.747,0,0,0-.915.458,3.925,3.925,0,0,0-1,1.1V5.637h-3.46l.01.505q.01.5.011,3.113t-.021,6.8h3.46V10.243a2.554,2.554,0,0,1,.115-.852,2.241,2.241,0,0,1,.668-.9,1.7,1.7,0,0,1,1.11-.363,1.5,1.5,0,0,1,1.33.626,3.042,3.042,0,0,1,.426,1.73v5.573h3.46V10.085a5.012,5.012,0,0,0-1.094-3.5"
            transform="translate(1187 112)"
            fill="#fff"
          />
          <path
            id="Path_24"
            data-name="Path 24"
            d="M113.735.621a1.978,1.978,0,0,0-1.414.51,1.694,1.694,0,0,0-.542,1.288,1.721,1.721,0,0,0,.526,1.283,1.894,1.894,0,0,0,1.388.515h.021a1.979,1.979,0,0,0,1.425-.515,1.664,1.664,0,0,0,.531-1.283,1.755,1.755,0,0,0-.536-1.288,1.925,1.925,0,0,0-1.4-.51"
            transform="translate(1187 112)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default ICLinkedin;
