import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBuildingList } from "../../services/manageBuildingService";

// Action creator to call the get rally point function with a payload to invoke the API.
export const fetchBuildingList = createAsyncThunk(
  "buildings/fetchBuildingList",
  () => {
    return getBuildingList();
  }
);
