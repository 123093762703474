import { createSlice } from "@reduxjs/toolkit";
import { fetchUserList, fetchUserDetails } from "../actions/manageUser";
import { InitialState } from "../../interfaces/ReduxState";
import { slices } from "../../constants/common";

// Define the initial state using that type
const initialState: InitialState = {
  loading: true,
  list: [],
  error: "",
  userDetails: null,
  userListIsLoading: true,
};

//Slice for user list, details based on userid
const manageUserList = createSlice({
  name: slices.manageUser,

  initialState,
  reducers: {
    resetUserDetails: (state) => {
      state.userDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.userDetails = action.payload?.data;
      state.error = "";
    });
    builder.addCase(fetchUserDetails.rejected, (state, action) => {
      state.loading = false;
      state.userDetails = null;
      state.error = action?.error?.message;
    });

    builder.addCase(fetchUserList.pending, (state) => {
      state.userDetails = null;
      state.userListIsLoading = true;
    });
    builder.addCase(fetchUserList.fulfilled, (state, action) => {
      state.userListIsLoading = false;
      state.userDetails = null;
      state.list = action.payload?.data;
      state.error = "";
    });
    builder.addCase(fetchUserList.rejected, (state, action) => {
      state.userListIsLoading = false;
      state.userDetails = null;
      state.list = [];
      state.error = action?.error?.message;
    });
  },
});
export const { resetUserDetails } = manageUserList.actions;

export default manageUserList.reducer;
