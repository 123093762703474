const ICHidePassword = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <g id="ClosedEye" transform="translate(-852.537 -447.5)">
        <rect
          id="Rectangle_457"
          data-name="Rectangle 457"
          width="28"
          height="28"
          transform="translate(852.537 447.5)"
          fill="none"
        />
        <path
          id="_1"
          data-name="1"
          d="M2.689,6.7A.75.75,0,1,0,1.311,7.3Zm12.9,6.624-.275-.7Zm-6.546.409a.75.75,0,0,0-1.257-.818Zm-2.67,1.353a.75.75,0,0,0,1.257.818ZM22.689,7.3A.75.75,0,0,0,21.311,6.7ZM19,11.129l-.513-.547h0Zm.97,2.03A.75.75,0,0,0,21.03,12.1ZM11.25,16.5a.75.75,0,0,0,1.5,0Zm5.121-.591a.75.75,0,0,0,1.257-.818ZM5.53,11.659A.75.75,0,1,0,4.47,10.6ZM2.97,12.1A.75.75,0,0,0,4.03,13.159ZM12,13.25A9.517,9.517,0,0,1,4.925,9.99a13.637,13.637,0,0,1-1.7-2.28c-.185-.311-.321-.568-.409-.745-.044-.088-.077-.157-.1-.2l-.022-.049,0-.01h0L2,7l-.689.3h0v0l0,.006.009.02.031.068c.027.058.066.14.117.243.1.206.256.5.462.841A15.134,15.134,0,0,0,3.825,11.01,11.014,11.014,0,0,0,12,14.75Zm3.313-.619A8.963,8.963,0,0,1,12,13.25v1.5a10.464,10.464,0,0,0,3.862-.723Zm-7.528.289L6.371,15.091l1.257.818,1.413-2.171ZM22,7l-.689-.3h0v0l-.006.013-.028.062c-.026.056-.067.142-.123.251-.112.22-.284.536-.517.911a13.3,13.3,0,0,1-2.149,2.639l1.027,1.094a14.8,14.8,0,0,0,2.4-2.939c.259-.416.451-.77.58-1.022.064-.126.113-.228.146-.3l.038-.084.011-.024,0-.007v0h0Zm-3.513,3.582a10.023,10.023,0,0,1-3.174,2.049l.549,1.4a11.521,11.521,0,0,0,3.651-2.351Zm-.017,1.077,1.5,1.5L21.03,12.1l-1.5-1.5ZM11.25,14v2.5h1.5V14Zm3.709-.262,1.413,2.171,1.257-.818L16.216,12.92ZM4.47,10.6l-1.5,1.5L4.03,13.159l1.5-1.5Z"
          transform="translate(854.287 449.25)"
          fill="#18345a"
        />
      </g>
    </svg>
  );
};

export default ICHidePassword;
