const ICAndroid = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="66"
      viewBox="0 0 56 66"
    >
      <g
        id="Group_417"
        data-name="Group 417"
        transform="translate(-756.77 -774.215)"
      >
        <rect
          id="Rectangle_399"
          data-name="Rectangle 399"
          width="56"
          height="66"
          transform="translate(756.77 774.215)"
          fill="none"
        />
        <g id="Group_30" data-name="Group 30" transform="translate(758 775)">
          <g id="Group_31" data-name="Group 31" transform="translate(0 0)">
            <path
              id="Rectangle_23"
              data-name="Rectangle 23"
              d="M4,0H30.878a4,4,0,0,1,4,4V27.561a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z"
              transform="translate(9.329 19.867)"
            />
            <rect
              id="Rectangle_24"
              data-name="Rectangle 24"
              width="34.947"
              height="14.385"
              transform="translate(9.296 19.867)"
            />
            <path
              id="Path_31"
              data-name="Path 31"
              d="M95.753,56.2a3.717,3.717,0,0,1-7.433,0V39.5a3.717,3.717,0,1,1,7.433,0Z"
              transform="translate(-42.214 -17.102)"
            />
            <path
              id="Path_32"
              data-name="Path 32"
              d="M7.433,56.2A3.717,3.717,0,0,1,0,56.2V39.5a3.717,3.717,0,1,1,7.433,0Z"
              transform="translate(0 -17.102)"
            />
            <path
              id="Subtraction_1"
              data-name="Subtraction 1"
              d="M841.147-757.263H806.489v-.005a16.853,16.853,0,0,1,2.3-7.364,14.119,14.119,0,0,1,5.136-5.066,19.492,19.492,0,0,1,9.8-2.3h.089a19.587,19.587,0,0,1,9.788,2.325,14.357,14.357,0,0,1,5.17,5.064,17.226,17.226,0,0,1,2.371,7.348Zm-9.723-9.635a2.089,2.089,0,0,0-2.086,2.086,2.089,2.089,0,0,0,2.086,2.086,2.089,2.089,0,0,0,2.086-2.086A2.089,2.089,0,0,0,831.424-766.9Zm-15.212,0a2.088,2.088,0,0,0-2.085,2.086,2.088,2.088,0,0,0,2.085,2.086,2.089,2.089,0,0,0,2.086-2.086A2.089,2.089,0,0,0,816.213-766.9Z"
              transform="translate(-797.049 775.072)"
              stroke="rgba(0,0,0,0)"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <path
              id="Path_36"
              data-name="Path 36"
              d="M32.628,5.06c.061.087.25.059.423-.064s.26-.29.2-.377L30.013.051c-.062-.088-.252-.058-.422.062s-.262.291-.2.378Z"
              transform="translate(-14.039 0)"
            />
            <path
              id="Path_37"
              data-name="Path 37"
              d="M66.359,5.06c-.061.087-.25.059-.423-.064s-.26-.29-.2-.377L68.974.051c.062-.088.252-.058.422.062s.262.291.2.378Z"
              transform="translate(-31.411 0)"
            />
            <path
              id="Path_38"
              data-name="Path 38"
              d="M65.332,97.605a3.717,3.717,0,0,1-7.433,0V80.9a3.717,3.717,0,0,1,7.433,0Z"
              transform="translate(-27.674 -36.893)"
            />
            <path
              id="Path_39"
              data-name="Path 39"
              d="M37.854,97.605a3.716,3.716,0,0,1-7.433,0V80.9a3.716,3.716,0,1,1,7.433,0Z"
              transform="translate(-14.54 -36.893)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ICAndroid;
