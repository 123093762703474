/**
 * @file   src\components\FooterLogin.tsx
 * @brief  Login Footer component.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import "./FooterLogin.scss";
import Logo from "../assets/img/Footer-logo.svg";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Strings from "../assets/strings/Strings.json";
import ICLinkedin from "../assets/img/icons/LinkedIn";
import { getCurrentYear, sendEmailContactSupport } from "../helpers/common";
import ICMail from "../assets/img/icons/Mail";
import { noMessage, noSubject } from "../constants/common";

const FooterLogin = () => {
  return (
    <footer className="login-footer">
      <Row>
        <Col xl="4" md="6" className="about">
          <img src={Logo} alt="" />
          <p>
            {Strings.LoginFooter.About.AboutInfo}
            <a
              href={Strings.LoginFooter.Navigation.linkdInLink}
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
          </p>
        </Col>
        <Col xl="3" md="6" className="navigation">
          <h4>{Strings.LoginFooter.Navigation.Title}</h4>
          <Link to={window.location.origin}>
            {Strings.LoginFooter.Navigation.home}
          </Link>
          <Link to={Strings.LoginFooter.Navigation.featureLink} target="_blank">
            {Strings.LoginFooter.Navigation.features}
          </Link>
          <Link to={Strings.LoginFooter.Navigation.pricingLink} target="_blank">
            {Strings.LoginFooter.Navigation.pricing}
          </Link>

          <Link to={Strings.LoginFooter.Navigation.contactLink} target="_blank">
            {Strings.LoginFooter.Navigation.contact}
          </Link>
          <Link
            to={Strings.LoginFooter.Navigation.spotLightSafetySolutionLink}
            target="_blank"
          >
            {Strings.LoginFooter.Navigation.spotlightSafety}
          </Link>
        </Col>
        <Col xl="3" md="6" className="get-in-touch">
          <h4>{Strings.LoginFooter.Getintouch.Title}</h4>
          <span className="mailing-address d-block">
            Mailing address:
            <br />
            955 Massachusetts Ave.Ste.
            <br />
            416 Cambridge, MA 02139
          </span>
          <span
            className="email d-block"
            onClick={() =>
              sendEmailContactSupport(
                Strings.LoginFooter.Getintouch.Email,
                noMessage,
                noSubject
              )
            }
          >
            <Link to="">{Strings.LoginFooter.Getintouch.Email}</Link>
          </span>
          <span className="phone d-block">
            {Strings.LoginFooter.Getintouch.Phone}
          </span>
        </Col>
        <Col xl="2" md="6" className="social">
          <h4>{Strings.LoginFooter.Followus.Title}</h4>
          <div className="d-flex">
            <span
              className="d-flex justify-content-center align-items-center"
              onClick={() =>
                window.open(
                  Strings.LoginFooter.Navigation.linkdInLink,
                  "_blank"
                )
              }
            >
              <ICLinkedin />
            </span>
            <span
              className="d-flex justify-content-center align-items-center"
              onClick={() =>
                sendEmailContactSupport(
                  Strings.LoginFooter.Getintouch.Email,
                  noMessage,
                  noSubject
                )
              }
            >
              <ICMail />
            </span>
          </div>
        </Col>
      </Row>
      <div className="login-copyright text-center">
        {Strings.Common.CopyRight?.replace("[YEAR]", getCurrentYear())}
      </div>
    </footer>
  );
};

export default FooterLogin;
