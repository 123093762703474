import { combineReducers } from "redux";
import incidentReducer from "./Slices/incidentReportSlice";
import anonymousReducer from "./Slices/anonymousBoxSlice";
import importantLinkReducer from "./Slices/importantLinksSlice";
import manageUserReducer from "./Slices/manageUserSlice";
import rallyPointsReducer from "./Slices/rallyPointsSlice";
import safetyOfficersReducer from "./Slices/safetyOfficersSlice";
import afterHourReducer from "./Slices/afterHourSlice";
import organizationReducer from "./Slices/organizationSlice";
import emergencyChkInReducer from "./Slices/emergencyCheckInSlice";
import myProfileReducer from "./Slices/myAccountSlice";
import dashboardReducer from "./Slices/dashboardSlice";
import buildingReducer from "./Slices/buildingSlice";

const appReducer = combineReducers({
  incidents: incidentReducer,
  anonymous: anonymousReducer,
  importantLinks: importantLinkReducer,
  manageUser: manageUserReducer,
  rallyPoints: rallyPointsReducer,
  safetyOfficers: safetyOfficersReducer,
  afterHour: afterHourReducer,
  organization: organizationReducer,
  emergency: emergencyChkInReducer,
  myAccount: myProfileReducer,
  dashboard: dashboardReducer,
  building: buildingReducer,
});

export default appReducer;
