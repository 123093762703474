const ICEdit = () => {
  return (
    <svg
      id="edit"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="Path_2535"
        data-name="Path 2535"
        d="M3255.462,2574.038v8.342l-24,.19v-24h24v2.573"
        transform="translate(-3231.462 -2558.57)"
        opacity="0"
      />
      <path
        id="Path_2536"
        data-name="Path 2536"
        d="M4.91,0H1.95A1.955,1.955,0,0,0,0,1.96v.77H6.87V1.96A1.963,1.963,0,0,0,4.91,0"
        transform="translate(16.661 3) rotate(45)"
        fillRule="evenodd"
      />
      <path
        id="Path_2537"
        data-name="Path 2537"
        d="M.09,14.88l2.89,4.2a.551.551,0,0,0,.91,0l2.88-4.2a.515.515,0,0,0,.09-.2H.01a.5.5,0,0,0,.08.2"
        transform="translate(16.661 3) rotate(45)"
        fillRule="evenodd"
      />
      <rect
        id="Rectangle_382"
        data-name="Rectangle 382"
        width="6.87"
        height="8.25"
        transform="translate(13.529 6.133) rotate(45)"
      />
      <rect
        id="Rectangle_383"
        data-name="Rectangle 383"
        width="6.87"
        height="19.32"
        transform="translate(16.661 3) rotate(45)"
        fill="none"
      />
    </svg>
  );
};

export default ICEdit;
