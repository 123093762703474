import * as apiService from "./apiService";
import * as endPoints from "../constants/endPoints";
import { HTTP_GET, HTTP_POST } from "../constants/common";
import { buildResponse } from "../helpers/common";
import { ChangePassword, LoginRequest } from "../interfaces/Login";

// Api call for user login
export async function login(payload?: LoginRequest) {
  const res = await apiService.apiCall(endPoints.LOGIN_API, HTTP_POST, payload);
  return buildResponse(res);
}

// Api call email sending while trouble login
export async function forgetPasswordEmailSend(payload?: ChangePassword) {
  const res = await apiService.apiCall(
    endPoints.FORGET_PWD_EMAIL_SEND_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}

// Api call for update password from trouble login screen
export async function createUpdatePassword(payload?: ChangePassword) {
  const res = await apiService.apiCall(
    endPoints.CHANGE_PWD_FROM_LOGIN_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}

//Api call for logout and clear the user tokens
export async function logout() {
  const res = await apiService.apiCall(endPoints.LOGOUT_API, HTTP_POST, {});
  return buildResponse(res);
}

// Api call for token validation in trouble login before updating the password
export async function tokenValidation(token: string) {
  const res = await apiService.apiCall(
    endPoints.TOKEN_VALIDATE_API + `/${token}`,
    HTTP_GET,
    {}
  );
  return buildResponse(res);
}

// Api call for sent otp to email id while login for 2FA
export async function send2FAOtp(payload?: LoginRequest) {
  const res = await apiService.apiCall(
    endPoints.SEND_2FA_OTP_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}

// Api call for login with 2FA credentials
export async function loginWith2FA(payload?: LoginRequest) {
  const res = await apiService.apiCall(
    endPoints.LOGIN_WITH_2FA_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}
