const ICCheckinArrowRight = () => {
  return (
    <svg
      id="Component_20_2"
      data-name="Component 20 – 2"
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <rect
        id="Rectangle_368"
        data-name="Rectangle 368"
        width="28"
        height="28"
        rx="5"
      />
      <path
        id="Path_2526"
        data-name="Path 2526"
        d="M6386.179,335.4l3.9,3.9-3.9,3.9"
        transform="translate(-6373.456 -324.639)"
        fill="none"
        strokeWidth="2"
      />
    </svg>
  );
};

export default ICCheckinArrowRight;
