import * as apiService from "./apiService";
import * as endPoints from "../constants/endPoints";
import { HTTP_GET, HTTP_POST } from "../constants/common";
import {
  GetOrgRequest,
  UpdateOrgRequestPayload,
} from "../interfaces/Organization";

//Api call fetch oragnization details
export async function getOrganization(payload?: GetOrgRequest) {
  return await apiService.apiCall(
    endPoints.GET_ORGANIZATION + `/${payload?.id}`,
    HTTP_GET,
    {}
  );
}

//Api call for update oragnization details
export async function updateOrganization(payload?: UpdateOrgRequestPayload) {
  return await apiService.apiCall(
    endPoints.UPDATE_ORGANIZATION,
    HTTP_POST,
    payload
  );
}
