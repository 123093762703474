import * as apiService from "./apiService";
import * as endPoints from "../constants/endPoints";
import { HTTP_GET, HTTP_POST } from "../constants/common";
import { buildResponse } from "../helpers/common";
import {
  FetchUsersPayload,
  GetUserDetailsPayload,
  Payload,
} from "../interfaces/ManageUsers";

//Api call for fetch users lists.
export async function userListing(payload?: FetchUsersPayload) {
  return await apiService.apiCall(
    endPoints.GET_USER_LIST_API,
    HTTP_GET,
    payload
  );
}
//Api call for update user details.
export async function updateUser(payload?: Payload) {
  const res = await apiService.apiCall(
    endPoints.UPDATE_USER_LIST_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}
//Api call for delete a user.
export async function deleteUser(payload?: Payload) {
  const res = await apiService.apiCall(
    endPoints.DELETE_USER_LIST_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}

//Api call for add a user.
export async function addUser(payload?: Payload) {
  const res = await apiService.apiCall(
    endPoints.ADD_USER_LIST_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}

//Api call for fetch a user details.
export async function userDetails(payload?: GetUserDetailsPayload) {
  return await apiService.apiCall(
    endPoints.GET_USERS_DETAILS_API + `/${payload.id}`,
    HTTP_GET,
    {}
  );
}

//Api call for resent reset password link to user email.
export async function resetPassword(payload?: { userId: number }) {
  return await apiService.apiCall(
    endPoints.SENT_RESET_PASSWORD_LINK_API,
    HTTP_POST,
    payload
  );
}
