import * as apiService from "./apiService";
import * as endPoints from "../constants/endPoints";
import { HTTP_GET, HTTP_POST } from "../constants/common";
import { buildResponse } from "../helpers/common";
import {
  SaveLinkPayload,
  DeleteLinkPayload,
} from "../interfaces/ImportantLinks";

//Api call for adding an important link details.
export async function addImportantLinks(payload?: SaveLinkPayload) {
  const res = await apiService.apiCall(
    endPoints.ADD_IMPORTANT_LINKS,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}

//Api call for fetch important link lists.
export async function getImpLinks() {
  return await apiService.apiCall(
    endPoints.GET_IMPORTANT_LINKS_API,
    HTTP_GET,
    {}
  );
}

//Api call for update important links order.
export async function updateLinkOrder(payload?: any) {
  const res = await apiService.apiCall(
    endPoints.UPDATE_IMPORTANT_LINKS_ORDER_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}

//Api call for update important link details.
export async function updateImpLink(payload?: SaveLinkPayload) {
  const res = await apiService.apiCall(
    endPoints.UPDATE_IMPORTANT_LINK_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}

//Api call for delete an important link.
export async function deleteImpLink(payload?: DeleteLinkPayload) {
  const res = await apiService.apiCall(
    endPoints.DELETE_IMPORTANT_LINK_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}
