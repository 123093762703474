import * as apiService from "./apiService";
import * as endPoints from "../constants/endPoints";
import { HTTP_GET, HTTP_POST } from "../constants/common";
import { buildResponse } from "../helpers/common";
import {
  EmergencyChkInList,
  EmergencySessionList,
  EmergencyDetails,
  UpdateEmergencySession,
  PushNotification,
} from "../interfaces/Emergency";

//Api call for fetch emergency check in lists for all the users in the organisation.
export async function emergencyListing(payload?: EmergencyChkInList) {
  return await apiService.apiCall(
    endPoints.EMERGENCY_CHK_IN_LIST,
    HTTP_GET,
    payload
  );
}
//Api call for fetch emergency check in details for particular user, while clicking an item from the emergency check in list.
export async function emergencyDetails(payload?: EmergencyDetails) {
  return await apiService.apiCall(
    endPoints.EMERGENCY_CHK_IN_DETAILS + `${payload.id}`,
    HTTP_GET,
    {}
  );
}

//Api call for fetch emergency check in session list(response from each notification) for particular user.
export async function emergencySessionList(payload?: EmergencySessionList) {
  return await apiService.apiCall(
    endPoints.EMERGENCY_CHK_IN_SESSION_LIST + `${payload.id}/sessions`,
    HTTP_GET,
    payload
  );
}

//Api call for update notes added for a user.
export async function updateEmergencyNotes(payload?: UpdateEmergencySession) {
  const res = await apiService.apiCall(
    endPoints.UPDATE_EMERGENCY_CHK_IN_NOTES,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}

//Api call for sent notification to unresponsive users.
export async function sentNotification(payload: PushNotification) {
  const res = await apiService.apiCall(
    endPoints.SENT_NOTIFICATION_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}

//Api call for get emergency check in details for CSV download.
export async function getEmergencyChkInDetails(payload: EmergencyDetails) {
  const res = await apiService.apiCall(
    endPoints.EMERGENCY_CHK_IN_DETAILS + `${payload.id}/export`,
    HTTP_GET,
    payload
  );
  return buildResponse(res);
}
