import * as apiService from "./apiService";
import * as endPoints from "../constants/endPoints";
import { HTTP_GET, HTTP_POST } from "../constants/common";
import { buildResponse } from "../helpers/common";
import { PasswordForm, ProfilePayload } from "../interfaces/MyAccount";

//Api call for update password.
export async function changePassword(payload?: PasswordForm) {
  const res = await apiService.apiCall(
    endPoints.CHANGE_PASSWORD_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}

//Api call for fetch user profile details.
export async function userProfileDetails(headers?: any) {
  return await apiService.apiCall(
    endPoints.GET_USER_PROFILE_API,
    HTTP_GET,
    {},
    headers
  );
}

//Api call for update user profile details.
export async function userProfileUpdate(payload?: ProfilePayload) {
  const res = await apiService.apiCall(
    endPoints.UPDATE_USER_PROFILE_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}
