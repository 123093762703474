const ICTableExpand = () => {
  return (
    <svg
      id="ICTableExpand"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <rect
        id="Rectangle_368"
        data-name="Rectangle 368"
        width="20"
        height="20"
        rx="5"
        fill="#86888e"
      />
      <path
        id="Path_2526"
        data-name="Path 2526"
        d="M6386.179,335.4l3.9,3.9-3.9,3.9"
        transform="translate(-6377.679 -328.899)"
        fill="none"
        stroke="#fff"
        strokeWidth="2"
      />
    </svg>
  );
};

export default ICTableExpand;
