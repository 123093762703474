import { createSlice } from "@reduxjs/toolkit";

import {
  fetchAfterHourListing,
  fetchAfterHourSessionList,
} from "../actions/afterHour";
import { InitialState } from "../../interfaces/ReduxState";
import { slices } from "../../constants/common";

//Initial state for list
const afterHourListState: InitialState = {
  loading: true,
  list: [],
  error: "",
};

//Initial state for detals and session list
const afterHourDetailsState: InitialState = {
  loading: true,
  list: [],
  error: "",
};

//Slice for fetching the after hour list, details and session list
const afterHourReducer = createSlice({
  name: slices.afterHour,

  initialState: {
    AfterHourList: afterHourListState,
    afterHourDetails: afterHourDetailsState,
  },
  reducers: {
    resetAfterHourDetailState: (state) => {
      state.afterHourDetails = afterHourDetailsState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAfterHourListing.pending, (state) => {
      state.AfterHourList.loading = true;
    });
    builder.addCase(fetchAfterHourListing.fulfilled, (state, action) => {
      state.AfterHourList.loading = false;
      state.AfterHourList.list = action.payload?.data;

      state.afterHourDetails.list = null;
      state.AfterHourList.error = "";
    });
    builder.addCase(fetchAfterHourListing.rejected, (state, action) => {
      state.AfterHourList.loading = false;
      state.AfterHourList.list = [];
      state.AfterHourList.error = action?.error?.message;
    });

    builder.addCase(fetchAfterHourSessionList.pending, (state) => {
      state.afterHourDetails.loading = true;
    });
    builder.addCase(fetchAfterHourSessionList.fulfilled, (state, action) => {
      state.afterHourDetails.loading = false;
      state.afterHourDetails.list = action.payload?.data;
      state.afterHourDetails.error = "";
    });
    builder.addCase(fetchAfterHourSessionList.rejected, (state, action) => {
      state.afterHourDetails.loading = false;
      state.afterHourDetails.list = [];
      state.afterHourDetails.error = action?.error?.message;
    });
  },
});

// Export auth actions.
export const { resetAfterHourDetailState } = afterHourReducer.actions;

export default afterHourReducer.reducer;
