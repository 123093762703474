const apiBaseURL: string = process.env.REACT_APP_API_URL;

export const SAMPLE_CSV_FILE_PATH = `https://spotlight-uploads.s3.amazonaws.com/${process.env.REACT_APP_ENV}/userTemplate.csv`;

export const LOGIN_API = `${apiBaseURL}users/adminLogin`;
export const FORGET_PWD_EMAIL_SEND_API = `${apiBaseURL}users/forgotPassword`;
export const CHANGE_PWD_FROM_LOGIN_API = `${apiBaseURL}users/updatePassword`;
export const REFRESH_TOKEN_API = `${apiBaseURL}users/requestTokens`;
export const CHANGE_PASSWORD_API = `${apiBaseURL}users/changePassword`;
export const LOGOUT_API = `${apiBaseURL}users/logout`;
export const GET_USER_PROFILE_API = `${apiBaseURL}users/getUserProfile`;
export const UPDATE_USER_PROFILE_API = `${apiBaseURL}users/updateUserProfile`;
export const GET_INCIDENT_REPORT_LIST_API = `${apiBaseURL}incidentReports`;
export const UPDATE_INCIDENT_REPORT_API = `${apiBaseURL}incidentReports/update`;
export const ADD_IMPORTANT_LINKS = `${apiBaseURL}importantlinks/addNewLink`;
export const GET_IMPORTANT_LINKS_API = `${apiBaseURL}importantlinks/getLinks`;
export const UPDATE_IMPORTANT_LINKS_ORDER_API = `${apiBaseURL}importantlinks/updatelinkorder`;
export const UPDATE_IMPORTANT_LINK_API = `${apiBaseURL}importantlinks/updateLink`;
export const DELETE_IMPORTANT_LINK_API = `${apiBaseURL}importantlinks/deleteLink`;
export const GET_ANONYMOUS_LIST_API = `${apiBaseURL}anonymousSuggestions`;
export const UPDATE_ANONYMOUS_SUGGESTION_API = `${apiBaseURL}anonymousSuggestions/update`;
export const TOKEN_VALIDATE_API = `${apiBaseURL}users/passwordTokenValidation`;
export const GET_USER_LIST_API = `${apiBaseURL}users/userList`;
export const UPDATE_USER_LIST_API = `${apiBaseURL}users/updateUser`;
export const DELETE_USER_LIST_API = `${apiBaseURL}users/deleteUser`;
export const ADD_USER_LIST_API = `${apiBaseURL}users/createUser`;
export const GET_RALLY_POINTS_API = `${apiBaseURL}rallyPoints/getRallyPoints`;
export const ADD_RALLY_POINT_API = `${apiBaseURL}rallyPoints/addRallyPoint`;
export const UPDATE_RALLY_POINT_API = `${apiBaseURL}rallyPoints/updateRallyPoint`;
export const DELETE_RALLY_POINT_API = `${apiBaseURL}rallyPoints/deleteRallyPoint`;
export const GET_ADMIN_USERS_API = `${apiBaseURL}safetyofficer/getAdminUsers`;
export const GET_USERS_DETAILS_API = `${apiBaseURL}users/getUserDetails`;
export const ADD_SAFETY_OFFICER_API = `${apiBaseURL}safetyofficer/createSafetyOfficer`;
export const GET_PRESIGNED_URL = `${apiBaseURL}common/getPresignedUrl`;
export const GET_SAFETY_OFFICER_LIST_URL = `${apiBaseURL}safetyofficer/getSafetyOfficers`;
export const UPDATE_SAFETY_OFFICER_URL = `${apiBaseURL}safetyofficer/updateSafetyOfficer`;
export const UPDATE_SAFETY_OFFICER_ORDER = `${apiBaseURL}safetyofficer/updateSafetyOfficerorder`;
export const DELETE_SAFETY_OFFICER_URL = `${apiBaseURL}safetyofficer/deleteSafetyOfficer`;
export const GET_ORGANIZATION = `${apiBaseURL}organization/getOrganization`;
export const UPDATE_ORGANIZATION = `${apiBaseURL}organization/updateUserOrganization`;
export const GET_AFTER_HOUR_LIST = `${apiBaseURL}afterHour/list`;
export const GET_AFTER_HOUR_DETAILS_ID = `${apiBaseURL}afterHour/getDetails/`;
export const GET_AFTER_HOUR_SESSION_LIST = `${apiBaseURL}afterHour/`;
export const UPDATE_AFTER_HOUR_SESSION_LIST = `${apiBaseURL}afterHour/sessions/updateNotes`;
export const DISABLE_AFTER_HOUR_CHECK_IN = `${apiBaseURL}afterHour/deactivateCheckin`;
export const STATE_LIST = `${apiBaseURL}common/stateList`;
export const SUPPORT_TICKET = `${apiBaseURL}common/submitSupportTicket`;
export const EMERGENCY_CHK_IN_LIST = `${apiBaseURL}emergencyCheckin/list`;
export const EMERGENCY_CHK_IN_DETAILS = `${apiBaseURL}V2/emergencyCheckin/getDetails/`;
export const EMERGENCY_CHK_IN_SESSION_LIST = `${apiBaseURL}emergencyCheckin/`;
export const UPDATE_EMERGENCY_CHK_IN_NOTES = `${apiBaseURL}emergencyCheckin/sessions/updateNotes`;
export const SENT_NOTIFICATION_API = `${apiBaseURL}emergencyCheckin/sendNotification`;
export const DASHBOARD_API = `${apiBaseURL}common/admin/metrics`;
export const BULK_USER_UPLOAD_API = `${apiBaseURL}users/bulkUpload`;
export const SENT_RESET_PASSWORD_LINK_API = `${apiBaseURL}users/sendResetPassword`;
export const SEND_2FA_OTP_API = `${apiBaseURL}users/adminSendOTP`;
export const LOGIN_WITH_2FA_API = `${apiBaseURL}users/adminOtplogin`;
export const GET_BUILDINGS_API = `${apiBaseURL}buildings/getBuildings`;
export const ADD_BUILDING_API = `${apiBaseURL}buildings/addBuilding`;
export const UPDATE_BUILDING_API = `${apiBaseURL}buildings/updateBuilding`;
export const DELETE_BUILDING_API = `${apiBaseURL}buildings/deleteBuilding`;
