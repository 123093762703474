export const pageURLs = Object.freeze({
  dashboard: "/dashboard",
  myaccount: "/myaccount",
  termsOfUse: "/terms-of-use",
  howToUseApp: "/how-to-use-app",
  troublelogin: "/troublelogin",
  incidentReports: "/incident-reports",
  importantlinks: "/importantlinks",
  anonymoussuggestionbox: "/anonymous-suggestionbox",
  manageusers: "/manage-users",
  rallyPoints: "/rallypoints",
  safetyOfficers: "/safetyofficers",
  support: "/support",
  organization: "/organization",
  createPassword: "/create-password",
  workAloneModeIn: "/work-alone-mode",
  emergencyCheckIn: "/emergency-checkin",
  workAloneModeInDetails: "/work-alone-mode-details",
  emergencyCheckInDetails: "/emergency-checkin-details",
  login: "/",
  notFound: "404-not-found",
});
