import { createAsyncThunk } from "@reduxjs/toolkit";
import { anonymousListing } from "../../services/anonymousService";
import { AnonymousListing } from "../../interfaces/Anonymous";

// Action creator to call the anonymousListing function with a payload to invoke the anonymous listing API.
export const fetchAnonymousListing = createAsyncThunk(
  "anonymous/fetchreports",
  (payload: AnonymousListing) => {
    return anonymousListing(payload);
  }
);
