import * as apiService from "./apiService";
import * as endPoints from "../constants/endPoints";
import { HTTP_GET, HTTP_POST } from "../constants/common";
import { buildResponse } from "../helpers/common";
import {
  SavePayload,
  SafetyListOrder,
  DeletePayload,
} from "../interfaces/SafetyOfficers";

//Api call for fetch admin-users lists.
export async function adminUsersListing() {
  return await apiService.apiCall(endPoints.GET_ADMIN_USERS_API, HTTP_GET, {});
}

//Api call for add a safety officer.
export async function addSafetyOfficer(payload?: SavePayload) {
  const res = await apiService.apiCall(
    endPoints.ADD_SAFETY_OFFICER_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}

//Api call for fetch safety officers lists.
export async function getSafetyOfficer() {
  return await apiService.apiCall(
    endPoints.GET_SAFETY_OFFICER_LIST_URL,
    HTTP_GET,
    {}
  );
}

//Api call for update safety officers order.
export async function updateSafetyOfficerOrder(payload?: SafetyListOrder) {
  const res = await apiService.apiCall(
    endPoints.UPDATE_SAFETY_OFFICER_ORDER,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}

//Api call for update a safety officer details.
export async function updateSafetyOfficer(payload?: SavePayload) {
  const res = await apiService.apiCall(
    endPoints.UPDATE_SAFETY_OFFICER_URL,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}

//Api call for delete a safety officer.
export async function deleteSafetyOfficer(payload?: DeletePayload) {
  const res = await apiService.apiCall(
    endPoints.DELETE_SAFETY_OFFICER_URL,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}
