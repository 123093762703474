import * as apiService from "./apiService";
import * as endPoints from "../constants/endPoints";
import { HTTP_GET, HTTP_POST } from "../constants/common";
import { buildResponse } from "../helpers/common";
import {
  IncidentReportListing,
  UpdateIncidentReport,
} from "../interfaces/IncidentInterface";

//Api call for fetch incident report lists.
export async function incidentReportListing(payload?: IncidentReportListing) {
  return await apiService.apiCall(
    endPoints.GET_INCIDENT_REPORT_LIST_API,
    HTTP_GET,
    payload
  );
}

//Api call for update incident report details.
export async function updateIncidentReport(payload?: UpdateIncidentReport) {
  const res = await apiService.apiCall(
    endPoints.UPDATE_INCIDENT_REPORT_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}
