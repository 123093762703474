/**
 * @file   src\components\common\OverLayTrigger.tsx
 * @brief  Tooltip.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { OverlayTrigger, Tooltip } from "react-bootstrap";

const OverLayToolTip = (props: any) => {
  const { title, Icon, placement, isExpanded } = props;

  return (
    <OverlayTrigger
      placement={placement || "right"}
      overlay={
        !isExpanded ? (
          <Tooltip id="tooltip-disabled">{title}</Tooltip>
        ) : (
          <span></span>
        )
      }
    >
      <span> {Icon}</span>
    </OverlayTrigger>
  );
};

export default OverLayToolTip;
