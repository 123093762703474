import { createSlice } from "@reduxjs/toolkit";

import {
  fetchEmergencyListing,
  fetchChkInDetails,
  fetchEmergencySessionList,
} from "../actions/emergencyCheckIn";
import { InitialState } from "../../interfaces/ReduxState";
import { slices } from "../../constants/common";

// Define the initial state using that type
const emergencyListState: InitialState = {
  loading: true,
  list: [],
  error: "",
};
const emergencyDetailState: InitialState = {
  loading: true,
  list: [],
  error: "",
};
const emergencySessionState: InitialState = {
  loading: true,
  list: [],
  error: "",
};

//Slice for fetch emergency list, details and session list.
const emergencyChkInReducer = createSlice({
  name: slices.emergencyCheckIn,

  initialState: {
    emergencyList: emergencyListState,
    emergencyDetail: emergencyDetailState,
    emergencySession: emergencySessionState,
  },
  reducers: {
    resetEmergencyDetailState: (state) => {
      state.emergencyDetail = emergencyDetailState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEmergencyListing.pending, (state) => {
      state.emergencyList.loading = true;
      state.emergencyDetail.list = null;
      state.emergencySession.list = [];
    });
    builder.addCase(fetchEmergencyListing.fulfilled, (state, action) => {
      state.emergencyList.loading = false;
      state.emergencyList.list = action.payload?.data;
      state.emergencyDetail.list = null;
      state.emergencySession.list = null;
      state.emergencyList.error = "";
    });
    builder.addCase(fetchEmergencyListing.rejected, (state, action) => {
      state.emergencyList.loading = false;
      state.emergencyList.list = [];
      state.emergencyList.error = action?.error?.message;
    });
    builder.addCase(fetchChkInDetails.pending, (state) => {
      state.emergencyDetail.list = [];
      state.emergencyList.list = [];
      state.emergencyDetail.loading = true;
    });
    builder.addCase(fetchChkInDetails.fulfilled, (state, action) => {
      state.emergencyDetail.loading = false;
      state.emergencyList.list = [];
      state.emergencyDetail.list = action.payload?.data;
      state.emergencyDetail.error = "";
    });
    builder.addCase(fetchChkInDetails.rejected, (state, action) => {
      state.emergencyDetail.loading = false;
      state.emergencyList.list = [];
      state.emergencyDetail.list = [];
      state.emergencyDetail.error = action?.error?.message;
    });
    builder.addCase(fetchEmergencySessionList.pending, (state) => {
      state.emergencySession.loading = true;
      state.emergencySession.list = [];
    });
    builder.addCase(fetchEmergencySessionList.fulfilled, (state, action) => {
      state.emergencySession.loading = false;
      state.emergencySession.list = action.payload?.data;
      state.emergencySession.error = "";
    });
    builder.addCase(fetchEmergencySessionList.rejected, (state, action) => {
      state.emergencySession.loading = false;
      state.emergencySession.list = [];
      state.emergencySession.error = action?.error?.message;
    });
  },
});

// Export auth actions.
export const { resetEmergencyDetailState } = emergencyChkInReducer.actions;

export default emergencyChkInReducer.reducer;
