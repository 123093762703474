import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ICDeleteLink = () => {
  return (
    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
      <svg
        id="delete"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <path
          id="Path_2565"
          data-name="Path 2565"
          d="M13.915,4.723H0V3.877A1.325,1.325,0,0,1,1.325,2.553H12.591a1.324,1.324,0,0,1,1.324,1.324Z"
          transform="translate(9 7.999)"
        />
        <path
          id="Path_2566"
          data-name="Path 2566"
          d="M9.8,1.692H3.926V.7a.7.7,0,0,1,.7-.7H9.1a.7.7,0,0,1,.7.7Z"
          transform="translate(9 7.999)"
        />
        <path
          id="Path_2567"
          data-name="Path 2567"
          d="M1.117,5.713l.725,9.539a1.489,1.489,0,0,0,1.485,1.376h6.958a1.488,1.488,0,0,0,1.483-1.359l.838-9.556Zm4.34,8.314a.59.59,0,0,1-1.18,0v-5.2a.59.59,0,1,1,1.18,0Zm3.91-.048a.591.591,0,0,1-1.181,0v-5.2a.591.591,0,1,1,1.181,0Z"
          transform="translate(9 7.999)"
        />
        <rect
          id="Rectangle_446"
          data-name="Rectangle 446"
          width="32"
          height="32"
          fill="none"
        />
      </svg>
    </OverlayTrigger>
  );
};

export default ICDeleteLink;
