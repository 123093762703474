/**
 * @file   src\components\ManageBuildings.tsx
 * @brief  Buildings CRUD.
 * @date   Sep, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import ICEditLink from "../assets/img/icons/EditLink";
import ICDeleteLink from "../assets/img/icons/DeleteLink";
import {
  HTTP_RESPONSE_STATUS_200,
  MAX_LENGTH_255,
  ZERO,
} from "../constants/common";
import { Button, Col, Row } from "react-bootstrap";
import SLInput from "../components/SLInput";
import Strings from "../assets/strings/Strings.json";
import { useState } from "react";
import {
  AddUpdteBuilding,
  DeleteBuilding,
  FormData,
  FormError,
} from "../interfaces/Buildings";
import { ENTER_BUILDING_NAME } from "../constants/validationMessages";
import DeleteConfModal from "../components/common/DeleteConfModal";

const ManageBuildings = (props: any) => {
  const {
    handleSaveBuilding,
    buildingList,
    handleDelete,
    buildingListIsLoading,
  } = props;
  const initialData = {
    name: "",
  };
  const initialError = {
    nameError: "",
  };
  //Initial state declarations
  const [formData, setFormData] = useState<FormData>(initialData);
  const [formError, setFormError] = useState<FormError>(initialError);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [isDisable, setIsDisabled] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteMdl] = useState<boolean>(false);

  //Validating form data
  const validateFormData = () => {
    setFormError(initialError);
    let isValid = true;
    const buildingName = formData?.name?.trim();

    if (!buildingName) {
      setFormError((error) => ({
        ...error,
        nameError: ENTER_BUILDING_NAME,
      }));
      isValid = false;
    }

    return isValid;
  };

  //Handle delete confirmarmation modal show/hide
  const handleDeleteModal = (state: boolean) => {
    if (!state) {
      setFormData(initialData);
    }
    setShowDeleteMdl(state || false);
  };

  //This will trigger in text box changes and update into respective state objects.
  const onTextBoxChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const currentId = e.currentTarget.id;
    setFormData((form: FormData) => ({
      ...form,
      [currentId]: value,
    }));
  };

  //Handle Add/Edit buidling click
  const handleSave = async () => {
    const isValid = validateFormData();
    if (isValid) {
      setIsLoad(true);
      const payload: AddUpdteBuilding = { name: formData?.name };
      //Edit and update flow
      if (formData?.buildingId) {
        payload.id = formData?.buildingId;
      }
      const res: any = await handleSaveBuilding(payload);
      if (res?.status === HTTP_RESPONSE_STATUS_200) {
        setFormData(initialData);
      }
    }
    setIsLoad(false);
  };

  //Handle delete buidling confirmation click
  const handleBuildngDelete = async () => {
    if (formData?.buildingId) {
      setIsDisabled(true);
      const payload: DeleteBuilding = { id: formData?.buildingId };

      const res: any = await handleDelete(payload);
      if (res?.status === HTTP_RESPONSE_STATUS_200) {
        setFormData(initialData);
        handleDeleteModal(false);
      }
    }
    setIsDisabled(false);
  };

  return (
    <>
      <h3>{Strings.buildings.mainTitle}</h3>
      <div>
        <Row>
          <Col xl="4" md="12">
            <SLInput
              id="name"
              label={Strings.buildings.addBuildng}
              type="text"
              value={formData?.name}
              onChange={onTextBoxChange}
              errorMessage={formError?.nameError}
              maxLength={MAX_LENGTH_255}
            />
          </Col>
        </Row>
        <Row>
          <Col xl="4" md="12" className="text-end mt-3 mb-3 btn-wrap">
            {formData?.buildingId && (
              <Button
                variant="secondary"
                onClick={() => setFormData(initialData)}
              >
                {Strings.common.cancelBtn}
              </Button>
            )}

            <Button
              variant="primary"
              onClick={() => {
                !isLoad && handleSave();
              }}
            >
              {isLoad ? (
                <output className="spinner-border sm"></output>
              ) : formData?.buildingId && !showDeleteModal ? (
                Strings.buildings.updateBtn
              ) : (
                Strings.buildings.addBtn
              )}
            </Button>
          </Col>
        </Row>
        <div>
          <Row>
            {buildingListIsLoading ? (
              <Col className="text-center no-record mt-5">
                <output className="spinner-border"></output>
              </Col>
            ) : (
              buildingList &&
              buildingList?.length > ZERO &&
              buildingList?.map((item: any, index: number) => {
                return (
                  <>
                    <Col lg="4">
                      <div
                        className="sortable-wrapper manage-buildings d-flex align-items-center"
                        key={index}
                      >
                        <div className="detail-wrap d-flex justify-content-between align-items-center">
                          <div className="link-dtls">
                            <span> {item?.name} </span>
                          </div>
                          <div className="link-actions d-flex">
                            <span
                              className="d-flex align-items-center justify-content-center"
                              onClick={() => {
                                setFormData({
                                  name: item?.name,
                                  buildingId: item?.id,
                                });
                                setFormError(initialError);
                              }}
                            >
                              <ICEditLink />
                            </span>
                            <span
                              className="d-flex align-items-center justify-content-center"
                              onClick={() => {
                                handleDeleteModal(true);
                                setFormData((form: FormData) => ({
                                  ...form,
                                  buildingId: item?.id,
                                }));
                              }}
                            >
                              <ICDeleteLink />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </>
                );
              })
            )}
          </Row>
        </div>
      </div>
      <DeleteConfModal
        onHide={handleDeleteModal}
        show={showDeleteModal}
        title={Strings.buildings.deleteModal.Title}
        desc={Strings.buildings.deleteModal.Info}
        handleDelete={handleBuildngDelete}
        isDisabled={isDisable}
      />
    </>
  );
};

export default ManageBuildings;
