/**
 * @file   src\containers\WorkAloneMode.tsx
 * @brief  Work Alone Mode CheckIn page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import Strings from "../assets/strings/Strings.json";
import { Button, Col, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import { fetchAfterHourListing } from "../Redux/actions/afterHour";
import { useAppSelector, useAppDispatch } from "../Redux/hooks";
import { csvDownload, ONE, PAGE_SIZE_20, ZERO } from "../constants/common";
import { downloadCSV, pushAndFormatCSVArray } from "../helpers/common";
import { pageURLs } from "../constants/pageURLs";
import Paginate from "../components/common/Paginate";
import Search from "../components/common/Search";
import SLCheckIn from "../components/SLCheckIn";
import { SearchRequest } from "../interfaces/Search";
import { useNavigate } from "react-router-dom";
import { resetAfterHourDetailState } from "../Redux/Slices/afterHourSlice";

const WorkAloneMode = () => {
  const [afterHourListing, setAfterHourListing] = useState<any>([]);
  const [csvData, setCsvData] = useState<any>([]);
  const [page, setPage] = useState<number>(ONE);
  const [perPage, setPerPage] = useState<number>(PAGE_SIZE_20);
  const [totalRecord, setTotalRecord] = useState<number>(ZERO);
  const [searchText, setSearchText] = useState<string>("");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const afterHourList = useAppSelector(
    (state: any) => state.afterHour?.AfterHourList?.list
  );
  const afterHourListLoading = useAppSelector(
    (state: any) => state.afterHour?.AfterHourList?.loading
  );

  // Handle to calling the after hour list API
  useEffect(() => {
    dispatch(
      fetchAfterHourListing({
        pageNo: page,
        pageSize: perPage,
        searchText: searchText,
      })
    );
  }, [page, perPage]);

  // Handle response from the after hour list api call
  useEffect(() => {
    if (!afterHourListLoading) {
      loadAfterHourList();
    }
  }, [afterHourList]);

  // Handle calling the after-hours list API with a search payload
  const onSearchClick = (payload: SearchRequest) => {
    dispatch(fetchAfterHourListing(payload));
  };

  // Handle setting the response from the after-hours list API call into state.
  const loadAfterHourList = async () => {
    const list = afterHourList?.list;
    if (list) {
      const csvArry: [] = pushAndFormatCSVArray(list, csvDownload.afterHour);
      setTotalRecord(afterHourList?.totalRecords);
      setCsvData(csvArry);
      setAfterHourListing(list);
    } else setTotalRecord(ZERO);
  };

  // page change and state change
  const pageChange = (page: any) => {
    if (page) {
      setPage(page);
    }
  };

  // page sizr change and state change
  const perPageChange = (page: any) => {
    if (page) {
      setPage(ONE);
      setPerPage(page);
    }
  };

  // handle navigation to details page
  const handleNavigationDetails = (id: number) => {
    dispatch(resetAfterHourDetailState());
    navigate(`${pageURLs.workAloneModeInDetails}/${id}`);
  };

  return (
    <>
      <Row className="tilte-wrapper">
        <Col lg="12">
          <h1>
            <span className="text-uppercase">
              {Strings.Common.InnerSubTitle}
            </span>
            <br />
            {Strings.workAloneModeChkIn.Title}
          </h1>
        </Col>
      </Row>
      <Row className="action-wrapper">
        <Col md="6" xl="4">
          <Search
            onClick={onSearchClick}
            pageSize={perPage}
            setPage={setPage}
            page={page}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </Col>
        <Col md="6" xl="8" className="text-end">
          <Button
            disabled={afterHourListing.length === ZERO}
            onClick={() => downloadCSV(csvData, csvDownload.afterHour)}
          >
            {Strings.common.dowloadCSV}
          </Button>
        </Col>
      </Row>
      {afterHourListing &&
        afterHourListing.length > ZERO &&
        afterHourListing.map((items: any, index: number) => (
          <SLCheckIn
            activeClass="active"
            key={index}
            itemKey={items?.checkin_Id}
            data={items}
            navigate={() => handleNavigationDetails(items.checkin_Id)}
          />
        ))}
      {!afterHourListLoading &&
      afterHourListing &&
      afterHourListing.length == ZERO ? (
        <Col className="text-center mt-3 mb-3">
          {Strings.workAloneModeChkIn.NoDataMessage}
        </Col>
      ) : (
        ""
      )}
      {afterHourListLoading && (
        <Col className="text-center no-record mt-5">
          <output className="spinner-border"></output>
        </Col>
      )}
      {totalRecord !== ZERO && (
        <Paginate
          totalRecords={totalRecord}
          currentPage={page}
          perPage={perPage}
          onClick={pageChange}
          onShowClick={perPageChange}
        />
      )}
    </>
  );
};

export default WorkAloneMode;
