/**
 * @file   src\containers\SafetyOfficers.tsx
 * @brief  SafetyOfficers page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useEffect, useState } from "react";
import Strings from "../assets/strings/Strings.json";
import { Button, Col, Row } from "react-bootstrap";
import SLInput from "../components/SLInput";
import SortableLinks from "../components/SortableLinks";
import {
  addSafetyOfficer,
  updateSafetyOfficer,
  deleteSafetyOfficer,
  updateSafetyOfficerOrder,
} from "../services/safetyOfficersServices";
//import { userDetails } from "../services/manageUserService";
import {
  emailValidation,
  phoneNumberValidation,
  phoneFormat,
  numberSpecialCharValidation,
  numberValidation,
  numberOnly,
} from "../helpers/validations";
import {
  SELECT_SAFETY_OFFICER,
  ENTER_VALID_PHONE,
  ENTER_EMAIL,
  ENTER_TITLE,
  ENTER_PHONE,
  INVALID_EMAIL,
  ENTER_FIRST_NAME,
  ENTER_LAST_NAME,
} from "../constants/validationMessages";
import {
  HTTP_RESPONSE_STATUS_200,
  EDIT,
  MAX_LENGTH_255,
  MAX_LENGTH_45,
  MAX_LENGTH_12,
  ZERO,
  ONE,
  HEIGHT_400,
  MAX_LENGTH_3,
  LIST_SAFETY_PAGE,
} from "../constants/common";
import DeleteConfModal from "../components/common/DeleteConfModal";
import { toast } from "react-toastify";
import SLSelect from "../components/SLSelect";
import {
  SafetyOfficersForm,
  SafetyOfficersFormError,
  SavePayload,
  adminList,
  SafetyListOrder,
} from "../interfaces/SafetyOfficers";

import {
  fetchAdminUsersList,
  fetchSafetyOfficersList,
} from "../Redux/actions/safetyOfficers";
import { fetchUserDetails } from "../Redux/actions/manageUser";
import { useAppSelector, useAppDispatch } from "../Redux/hooks";
import { ResponseObjects } from "../interfaces/AxiosResponse";
import { resetUserDetails } from "../Redux/Slices/manageUserSlice";

const SafetyOfficers = () => {
  //Initial form values
  const intialSafetyOfficerValue = {
    userId: ZERO,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    title: "",
    displayPhone: "",
    extension: "",
    displayEmail: "",
  };

  //Initial form errors
  const intialSafetyOfficerError = {
    titleError: "",
    displayPhoneError: "",
    displayEmailError: "",
    selectError: "",
    firstNameError: "",
    lastNameError: "",
  };

  const defaultDropdownText = "Other";
  const DISPLAYPHONE = "displayPhone";
  const EXTENSION = "extension";
  //Initial state declarations
  const [show, setShow] = useState<boolean>(false);
  const [saveClicked, setSaveClicked] = useState<boolean>(false);
  const [adminUsers, setAdminUsers] = useState<any>([]);
  const [userId, setUserId] = useState<number>(null);
  const [isSelectedAdmin, setIsAdminSelected] = useState<boolean>(false);
  const [safetyOfficerData, setSafetyOfficerData] =
    React.useState<SafetyOfficersForm>(intialSafetyOfficerValue);
  const [safetyOfficerError, setSafetyOfficerError] =
    React.useState<SafetyOfficersFormError>(intialSafetyOfficerError);
  const [safetyOfficersList, setSafetyOfficersList] = useState<any>([]);
  const [formatedPhone, setFormatedPhone] = useState<string>("");
  const [isDisable, setBtnDisable] = useState<boolean>(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Admin user list from redux store to fill the dropdown
  const adminUsersLists = useAppSelector(
    (state: any) => state.safetyOfficers?.AdminUsers?.list
  );

  //Safety officers list from redux store
  const safetyLists = useAppSelector(
    (state: any) => state.safetyOfficers?.SafetyOfficers?.list
  );

  //Safety officer details from redux store
  const userDetails = useAppSelector(
    (state: any) => state.manageUser?.userDetails
  );
  const dispatch = useAppDispatch();

  // Handle to calling the Admin user list and safety officers list API.
  useEffect(() => {
    dispatch(resetUserDetails());
    dispatch(fetchAdminUsersList());
    dispatch(fetchSafetyOfficersList());
  }, []);

  // Handle to setting the editing user details to state.
  useEffect(() => {
    setSafetyOfficerData((form) => ({
      ...form,
      userId: userDetails?.userId,
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
      phone: userDetails?.phone,
      email: userDetails?.email,
      title: userDetails?.user_title,
      displayPhone: userDetails?.phone,
      displayEmail: userDetails?.email,
      extension: userDetails?.extension,
    }));
  }, [userDetails]);

  // Handle to format the phone number.
  useEffect(() => {
    const phone =
      safetyOfficerData.displayPhone &&
      phoneFormat(safetyOfficerData.displayPhone);
    setFormatedPhone(phone);
  }, [safetyOfficerData.displayPhone]);

  // Handle response from the safety officers list api call.
  useEffect(() => {
    loadSafetyOfficersList();
  }, [safetyLists]);

  // Handle response from the admin User list api call.
  useEffect(() => {
    loadAdminUsersList();
  }, [adminUsersLists]);

  // Handle setting the response from the Admin user list API call into state.
  const loadAdminUsersList = async () => {
    const list = adminUsersLists;
    if (list) {
      const updatedList = [...list];
      const newArray: any = [];
      updatedList?.map((data: adminList) => {
        newArray.push({
          id: data?.id,
          label: data?.firstName + ` ${data?.lastName}`,
        });
      });
      setAdminUsers(newArray);
    }
  };

  // Handle setting the response from the safety officers list API call into state.
  const loadSafetyOfficersList = async () => {
    const list = safetyLists;
    if (list) {
      const updatedList = [...list];
      setSafetyOfficersList(updatedList);
    }
  };

  // Handle to calling the  user details  API and reset the form.
  const getUserDetails = async (selectedId: number) => {
    clearForm();
    setSafetyOfficerError(intialSafetyOfficerError);
    setIsAdminSelected(selectedId?.toString() === "0" ? false : true);
    dispatch(fetchUserDetails({ id: selectedId }));
  };

  // Handle to calling the  user add details  API and update the list.
  const addSafety = async (payload?: SavePayload) => {
    const response: ResponseObjects = await addSafetyOfficer(payload);
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      if (response?.message) {
        toast.success(response?.message);
        const copyList: any = [...adminUsers] || [];
        const updatedList = copyList?.filter(function (ls: SafetyListOrder) {
          return ls.id !== safetyOfficerData?.userId;
        });
        setIsAdminSelected(false);
        setAdminUsers(updatedList);
        clearForm();
        dispatch(fetchSafetyOfficersList());
      }
      setSaveClicked(false);
    } else {
      toast.error(response?.message);
      setSaveClicked(false);
    }
  };

  // Handle to calling the  user update details  API and update the list.
  const updateSafety = async (payload: SavePayload) => {
    const response: ResponseObjects = await updateSafetyOfficer(payload);
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      if (response?.message) {
        toast.success(response?.message);
        const copyList: any = [...adminUsers] || [];
        const updatedList = copyList?.filter(function (ls: SafetyListOrder) {
          return ls.id !== safetyOfficerData?.userId;
        });
        setAdminUsers(updatedList);
        setIsAdminSelected(false);
        setUserId(null);
        clearForm();
        dispatch(fetchSafetyOfficersList());
      }
      setSaveClicked(false);
    } else {
      toast.error(response?.message);
      setSaveClicked(false);
    }
  };

  // Handle to format the phone number from the API.
  const formatPhoneNumber = (phoneNumber?: string) => {
    if (phoneNumber !== null) {
      phoneNumber = phoneNumber?.replace(/^(\+1)/, "");
      phoneNumber = phoneNumber?.replace(/\D/g, "");
      return phoneNumber;
    }
  };

  // Handle onChange text change into the state.
  const onTextChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const currentId = e.currentTarget.id;
    if (currentId === DISPLAYPHONE) {
      if (numberSpecialCharValidation.test(value)) {
        const numbers = value.match(/\d+/g);
        const fullNumber = numbers.join("");
        setSafetyOfficerData((safetyOfficerData) => ({
          ...safetyOfficerData,
          [currentId]: fullNumber,
        }));
      } else if (!numberValidation.test(value)) {
        setSafetyOfficerData((safetyOfficerData) => ({
          ...safetyOfficerData,
          [currentId]: value,
        }));
      }
    } else if (currentId === EXTENSION) {
      if (value) {
        if (numberOnly.test(value)) {
          setSafetyOfficerData((safetyOfficerData) => ({
            ...safetyOfficerData,
            [currentId]: value,
          }));
        }
      } else {
        setSafetyOfficerData((safetyOfficerData) => ({
          ...safetyOfficerData,
          [currentId]: "",
        }));
      }
    } else {
      setSafetyOfficerData((safetyOfficerData) => ({
        ...safetyOfficerData,
        [currentId]: value,
      }));
    }
  };

  // Error validation of the form.
  const validateFormData = () => {
    setSafetyOfficerError(intialSafetyOfficerError);
    let isValid = true;
    const phone = safetyOfficerData?.displayPhone?.trim();
    const title = safetyOfficerData?.title?.trim();
    const email = safetyOfficerData?.displayEmail?.trim();
    const selectId = safetyOfficerData?.userId;

    if (!isSelectedAdmin) {
      const firstName = safetyOfficerData?.firstName;
      const lastName = safetyOfficerData?.lastName;
      if (!firstName) {
        setSafetyOfficerError((safetyOfficerError) => ({
          ...safetyOfficerError,
          firstNameError: ENTER_FIRST_NAME,
        }));
        isValid = false;
      }
      if (!lastName) {
        setSafetyOfficerError((safetyOfficerError) => ({
          ...safetyOfficerError,
          lastNameError: ENTER_LAST_NAME,
        }));
        isValid = false;
      }
    }
    if (phone && formatedPhone) {
      const phoneValidation = phoneNumberValidation(formatedPhone);
      if (!phoneValidation) {
        setSafetyOfficerError((safetyOfficerError) => ({
          ...safetyOfficerError,
          displayPhoneError: ENTER_VALID_PHONE,
        }));
        isValid = false;
      }
    }
    if (email) {
      if (!emailValidation(email)) {
        setSafetyOfficerError((safetyOfficerError) => ({
          ...safetyOfficerError,
          displayEmailError: INVALID_EMAIL,
        }));
        isValid = false;
      }
    }
    if (isSelectedAdmin) {
      if (!selectId || selectId === ZERO) {
        setSafetyOfficerError((safetyOfficerError) => ({
          ...safetyOfficerError,
          selectError: SELECT_SAFETY_OFFICER,
        }));
        isValid = false;
      }
    }

    if (!title) {
      setSafetyOfficerError((safetyOfficerError) => ({
        ...safetyOfficerError,
        titleError: ENTER_TITLE,
      }));
      isValid = false;
    }
    if (!phone) {
      setSafetyOfficerError((safetyOfficerError) => ({
        ...safetyOfficerError,
        displayPhoneError: ENTER_PHONE,
      }));
      isValid = false;
    }
    if (!email) {
      setSafetyOfficerError((safetyOfficerError) => ({
        ...safetyOfficerError,
        displayEmailError: ENTER_EMAIL,
      }));
      isValid = false;
    }

    return isValid;
  };

  // Handle the Add and update safety button fot user Add/Update API function.
  const handleSave = async () => {
    setSaveClicked(true);
    const isValid = validateFormData();

    const payload: SavePayload = {
      title: safetyOfficerData?.title,
      displayPhone: safetyOfficerData?.displayPhone,
      displayEmail: safetyOfficerData?.displayEmail,
      extension: safetyOfficerData?.extension || null,
      firstName: safetyOfficerData?.firstName,
      lastName: safetyOfficerData?.lastName,
    };
    if (isValid) {
      if (userId) {
        payload.soId = safetyOfficerData?.userId;
        updateSafety(payload);
      } else {
        if (isSelectedAdmin) {
          payload.adminId = safetyOfficerData?.userId;
        }
        addSafety(payload);
      }
    } else setSaveClicked(false);
  };

  // Handle to populate the selected safety officer data to text-box and scroll to the top of the screen
  const safetyAction = (data: adminList, action?: string) => {
    setSafetyOfficerError(intialSafetyOfficerError);
    if (userId) {
      const copyList: any = [...adminUsers] || [];
      const updatedList = copyList?.filter(function (ls: SafetyListOrder) {
        return ls.id !== userId;
      });
      setAdminUsers(updatedList);
    }

    setUserId(data?.id);
    if (action === EDIT) {
      const isAdmin = data?.userId !== null;
      let statesToBeUpdated: adminList;
      if (isAdmin) {
        const newArray: any = [...adminUsers] || [];
        newArray.push({
          id: data?.id,
          label: data?.user_firstName + ` ${data?.user_lastName}`,
        });

        setAdminUsers(newArray);
        statesToBeUpdated = {
          firstName: data?.user_firstName,
          lastName: data?.user_lastName,
          title: data?.title,
          phone: formatPhoneNumber(data?.user_phone),
          email: data?.user_email,
          displayEmail: data?.email,
          displayPhone: formatPhoneNumber(data?.phone),
          userId: data?.id,
          extension: data?.extension,
        };
      } else {
        statesToBeUpdated = {
          firstName: data?.firstName,
          lastName: data?.lastName,
          title: data?.title,
          displayEmail: data?.email,
          displayPhone: formatPhoneNumber(data?.phone),
          userId: data?.id,
          extension: data?.extension,
        };
      }

      setSafetyOfficerData((form) => ({
        ...form,
        ...statesToBeUpdated,
      }));
      setIsAdminSelected(isAdmin);
      window.scrollTo(ZERO, ZERO);
    } else {
      setBtnDisable(false);
      handleShow();
    }
  };

  // Handle to reset the safety officer state.
  const clearForm = () => {
    setSafetyOfficerData(intialSafetyOfficerValue);
  };

  // Handle to build the payload for the order update.
  const buildPayload = (updatedList: any) => {
    const updatedPayload = updatedList?.map((lst: SafetyListOrder) => {
      return {
        soId: lst?.id,
        orderNo: lst?.orderNo,
      };
    });
    return { soItems: updatedPayload };
  };

  // Handle to update the order of the safety officers list.
  const updateOrder = async (updatedList: any) => {
    const updatedItems = buildPayload(updatedList);
    const response: ResponseObjects =
      await updateSafetyOfficerOrder(updatedItems);
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
  };

  // Handle to  call the delete safety officers and update the safety officers list.
  const deleteSafety = async () => {
    if (userId) {
      clearForm();
      setBtnDisable(true);
      const response: ResponseObjects = await deleteSafetyOfficer({
        soId: userId,
      });
      if (response?.status === HTTP_RESPONSE_STATUS_200) {
        toast.success(response?.message);
        const copyList: any = [...safetyOfficersList];
        const updatedList = copyList?.filter(function (ls: SafetyListOrder) {
          return ls.id !== userId;
        });

        const deletedItem = copyList?.filter(function (ls: SafetyListOrder) {
          return ls.id === userId;
        });
        const deletedItemOrder = deletedItem[ZERO]?.orderNo;
        const finalList = updatedList?.map((itm: adminList) => {
          let currentOrder = itm?.orderNo;
          if (currentOrder > deletedItemOrder) {
            currentOrder = currentOrder - ONE;
          }

          return {
            user_email: itm?.user_email,
            email: itm?.email,
            firstName: itm?.firstName,
            lastName: itm?.lastName,
            orderNo: currentOrder,
            id: itm?.id,
            phone: itm?.phone,
            user_phone: itm?.user_phone,
            title: itm?.title,
            user_firstName: itm?.user_firstName,
            user_lastName: itm?.user_lastName,
            extension: itm?.extension,
            userId: itm?.userId,
          };
        });
        setSafetyOfficersList(finalList);
        handleClose();
        setUserId(null);
        dispatch(fetchAdminUsersList());
      } else {
        setBtnDisable(false);
        toast.error(response?.message);
      }
    }
  };

  return (
    <>
      <Row className="tilte-wrapper">
        <Col xl="12">
          <h1>
            <span className="text-uppercase">
              {Strings.Common.InnerSubTitle}
            </span>
            <br />
            {Strings.SafetyOfficers.Title}
          </h1>
        </Col>
      </Row>
      <div className="content-wrapper dashboard welcome">
        <h3>
          {Strings.SafetyOfficers.NoteTitle}{" "}
          <span> {Strings.SafetyOfficers.Note1}</span>
        </h3>
      </div>
      <div className="content-wrapper">
        <Row>
          <Col xl="8" md="12">
            <SLSelect
              DefaultItemText={defaultDropdownText}
              list={adminUsers}
              handleSelection={getUserDetails}
              selectedValue={safetyOfficerData?.userId}
              //errorMessage={safetyOfficerError?.selectError}
              label={Strings.SafetyOfficers.SelectSafetyOfficer}
              disabled={userId === safetyOfficerData?.userId}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="4" md="6">
            <SLInput
              label={Strings.SafetyOfficers.FirstName}
              value={safetyOfficerData?.firstName}
              disabled={isSelectedAdmin}
              id="firstName"
              onChange={onTextChange}
              errorMessage={safetyOfficerError?.firstNameError}
            />
          </Col>
          <Col lg="4" md="6">
            <SLInput
              label={Strings.SafetyOfficers.LastName}
              value={safetyOfficerData?.lastName}
              disabled={isSelectedAdmin}
              id="lastName"
              onChange={onTextChange}
              errorMessage={safetyOfficerError?.lastNameError}
            />
          </Col>
        </Row>
        {isSelectedAdmin && (
          <Row>
            <Col lg="4" md="6">
              <SLInput
                label={Strings.SafetyOfficers.Phone}
                value={
                  safetyOfficerData?.phone &&
                  phoneFormat(safetyOfficerData?.phone)
                }
                disabled={true}
              />
            </Col>
            <Col lg="4" md="6">
              <SLInput
                label={Strings.SafetyOfficers.Email}
                value={safetyOfficerData?.email}
                disabled={true}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col lg="8" md="12">
            <SLInput
              label={Strings.SafetyOfficers.title}
              id="title"
              onChange={onTextChange}
              value={safetyOfficerData?.title}
              errorMessage={safetyOfficerError?.titleError}
              maxLength={MAX_LENGTH_255}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="2" md="6">
            <SLInput
              label={Strings.SafetyOfficers.DisplayPhone}
              id={DISPLAYPHONE}
              type="tel"
              maxLength={MAX_LENGTH_12}
              onChange={onTextChange}
              value={formatedPhone || ""}
              errorMessage={safetyOfficerError?.displayPhoneError}
            />
          </Col>
          <Col lg="2" md="6">
            <SLInput
              label={Strings.SafetyOfficers.extension}
              id={EXTENSION}
              type="tel"
              maxLength={MAX_LENGTH_3}
              onChange={onTextChange}
              value={safetyOfficerData?.extension || ""}
            />
          </Col>
          <Col lg="4" md="6">
            <SLInput
              label={Strings.SafetyOfficers.DisplayEmail}
              id="displayEmail"
              type="email"
              onChange={onTextChange}
              value={safetyOfficerData?.displayEmail}
              errorMessage={safetyOfficerError?.displayEmailError}
              maxLength={MAX_LENGTH_45}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12" xl="8" className="text-end mt-3 btn-wrap">
            <Button disabled={saveClicked} onClick={handleSave}>
              {userId
                ? Strings.SafetyOfficers.btnUpdate
                : Strings.SafetyOfficers.btnSave}
            </Button>
          </Col>
        </Row>
      </div>
      {safetyOfficersList?.length > ZERO && safetyOfficersList?.[0]?.id && (
        <>
          <h3>
            {Strings.SafetyOfficers.displayOrderTitle}
            <span className="d-block">{Strings.SafetyOfficers.dragTitle}</span>
          </h3>
          <div>
            <div style={{ overflowY: "auto", height: HEIGHT_400 }}>
              <SortableLinks
                list={safetyOfficersList}
                action={safetyAction}
                setReorderList={setSafetyOfficersList}
                type={LIST_SAFETY_PAGE}
                updateOrder={updateOrder}
              />
            </div>
          </div>
        </>
      )}

      <DeleteConfModal
        onHide={handleClose}
        show={show}
        title="Delete Safety Officer"
        desc="Are you sure you want to delete this safety officer?"
        handleDelete={deleteSafety}
        isDisabled={isDisable}
      />
    </>
  );
};

export default SafetyOfficers;
