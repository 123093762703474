import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import Login from "./containers/Login";
import TroubleLogin from "./containers/TroubleLogin";
import GenericNotFound from "./containers/GenericNotFound";
import Layout from "./components/Layout";
import Dashboard from "./containers/Dashboard";
import MyAccount from "./containers/MyAccount";
import TermsOfUse from "./containers/TermsOfUse";
import ImportantLinks from "./containers/ImportantLinks";
import { useEffect } from "react";
import {
  convertToNumber,
  getLocalStorage,
  getValueFromObject,
  removeLocalStorage,
  setLocalStorage,
} from "./helpers/common";
import { HTTP_RESPONSE_STATUS_200, USER_INFO_KEY } from "./constants/common";
import { pageURLs } from "./constants/pageURLs";
import IncidentReports from "./containers/IncidentReports";
import AnonymousSuggestionBox from "./containers/AnonymousSuggestionBox";
import ManageUsers from "./containers/ManageUsers";
import RallyPoints from "./containers/RallyPoints";
import SafetyOfficers from "./containers/SafetyOfficers";
import Support from "./containers/Support";
import Organization from "./containers/Organization";
import WorkAloneMode from "./containers/WorkAloneMode";
import WorkAloneModeDetails from "./containers/WorkAloneModeDetails";
import EmergencyCheckIn from "./containers/EmergencyCheckIn";
import EmergencyCheckInDetails from "./containers/EmergencyCheckInDetails";
import CreatePassword from "./containers/CreatePassword";
import HowToUseApp from "./containers/HowToUseApp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userLogout } from "./Redux/actions/authentication";
import { useAppDispatch } from "./Redux/hooks";

import { userProfileDetails } from "./services/myAccountService";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const pathName = location?.pathname?.toLowerCase();
  const userDetails = getLocalStorage(USER_INFO_KEY);
  const isSuperAdminLogin = searchParams.get("isSuperAdminLogin");
  //This array contains the pages which don't need a accesstoken.
  const tokenExemptionPages: Array<string> = [
    pageURLs.troublelogin,
    pageURLs.createPassword,
  ]; // Used for checking the page is excempted from access token.

  useEffect(() => {
    // This snippets is for handling the login from super admin portal(launch now option from comapany listing page)
    if (isSuperAdminLogin) {
      handleLoginFromSuperAdminPortal();
    } else {
      userValidation();
    }

    window.scroll({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [pathName]);

  //This method is used for checking the user is logged in or not
  const userValidation = () => {
    const accessToken = getValueFromObject(userDetails, "accessToken");
    if (pathName !== pageURLs.termsOfUse && pathName !== pageURLs.howToUseApp) {
      if (accessToken) {
        if (
          pathName === pageURLs.login ||
          tokenExemptionPages.includes(pathName)
        ) {
          navigate(pageURLs.dashboard);
        }
      } else {
        if (!tokenExemptionPages.includes(pathName)) {
          dispatch(userLogout()); // For clear redux store
          removeLocalStorage(USER_INFO_KEY);
          navigate(pageURLs.login);
        }
      }
    }
  };

  //This method is used for managing the login from super admin portal(launch now functionality) and update the user details into local storage
  const handleLoginFromSuperAdminPortal = async () => {
    const accessToken: string = searchParams.get("accessToken");
    const refreshToken: string = searchParams.get("refreshToken");
    const firstName: string = searchParams.get("firstName");
    const lastName: string = searchParams.get("lastName");
    const orgId: string = searchParams.get("orgId");

    if (accessToken) {
      const response = await userProfileDetails({
        Authorization: `Bearer ` + accessToken,
        isFromSuperAdmin: true,
      });

      if (
        response?.status === HTTP_RESPONSE_STATUS_200 &&
        response?.data?.userId
      ) {
        const userInfo = {
          accessToken: accessToken?.trim(),
          refreshToken: refreshToken?.trim(),
          firstName: firstName?.trim(),
          lastName: lastName?.trim(),
          orgId: convertToNumber(orgId),
          userId: convertToNumber(response?.data?.userId),
          isSuperAdminLogin: true,
        };
        setLocalStorage(USER_INFO_KEY, userInfo);
        navigate(pageURLs.dashboard);
      } else {
        removeLocalStorage(USER_INFO_KEY);
        navigate(pageURLs.login);
      }
    } else {
      userValidation();
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-center"
        className="toast-message toast-message-width-500"
      />
      <Routes>
        {!userDetails?.accessToken && (
          <>
            <Route path="/" element={<Login />} />
            <Route path={pageURLs.troublelogin} element={<TroubleLogin />} />
            <Route
              path={pageURLs.createPassword}
              element={<CreatePassword />}
            />
          </>
        )}
        <Route path={pageURLs.termsOfUse} element={<TermsOfUse />} />
        <Route path={pageURLs.howToUseApp} element={<HowToUseApp />} />

        <Route element={<Layout />}>
          <Route path={pageURLs.dashboard} element={<Dashboard />} />
          <Route path={pageURLs.myaccount} element={<MyAccount />} />

          <Route
            path={pageURLs.incidentReports}
            element={<IncidentReports />}
          />
          <Route path={pageURLs.importantlinks} element={<ImportantLinks />} />
          <Route
            path={pageURLs.anonymoussuggestionbox}
            element={<AnonymousSuggestionBox />}
          />
          <Route path={pageURLs.manageusers} element={<ManageUsers />} />
          <Route path={pageURLs.rallyPoints} element={<RallyPoints />} />
          <Route path={pageURLs.safetyOfficers} element={<SafetyOfficers />} />
          <Route path={pageURLs.support} element={<Support />} />
          <Route path={pageURLs.organization} element={<Organization />} />

          <Route path={pageURLs.workAloneModeIn} element={<WorkAloneMode />} />
          <Route
            path={`${pageURLs.workAloneModeInDetails}/:ID`}
            element={<WorkAloneModeDetails />}
          />
          <Route
            path={pageURLs.emergencyCheckIn}
            element={<EmergencyCheckIn />}
          />
          <Route
            path={`${pageURLs.emergencyCheckInDetails}/:ID`}
            element={<EmergencyCheckInDetails />}
          />
        </Route>
        {!isSuperAdminLogin && (
          <Route path="/*" element={<GenericNotFound />} />
        )}
      </Routes>
    </>
  );
}

export default App;
