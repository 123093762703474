import * as apiService from "./apiService";
import * as endPoints from "../constants/endPoints";
import {
  CONTENT_TYPE_FORM_DATA,
  HTTP_POST,
  HTTP_PUT,
} from "../constants/common";
import { BulkUpload, PreSignedUrl } from "../interfaces/FileUpload";

//Api call for create a presigned url for the s3 file upload.
export async function getPresignedUrl(payload?: PreSignedUrl) {
  return await apiService.apiCall(
    endPoints.GET_PRESIGNED_URL,
    HTTP_POST,
    payload
  );
}

//Api call for upload a file to s3.
export const uploadToS3 = async (preSignedUrl: string, file: any) => {
  const method = HTTP_PUT;
  const header = {
    "Content-Type": CONTENT_TYPE_FORM_DATA,
  };

  const res = await uploadFileToS3(preSignedUrl, method, file, header);

  if (res) {
    return res;
  } else {
    return null;
  }
};

//Upload to S3
async function uploadFileToS3(
  preSignedUrl: string,
  method: string,
  fileData: File,
  header: any
) {
  const isFileUpload = true;
  return await apiService.apiCall(
    preSignedUrl,
    method,
    fileData,
    header,
    isFileUpload
  );
}

//Api call for bulk user upload.
export async function bulkUserUpload(payload: BulkUpload) {
  const header = {
    "Content-Type": CONTENT_TYPE_FORM_DATA,
  };

  return await apiService.apiCall(
    endPoints.BULK_USER_UPLOAD_API,
    HTTP_POST,
    payload,
    header
  );
}
