const ICSort = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="22"
      viewBox="0 0 28 22"
    >
      <g id="Sort">
        <path
          id="Line_8"
          data-name="Line 8"
          d="M28,1H0V-1H28Z"
          transform="translate(0 1)"
        />
        <path
          id="Line_9"
          data-name="Line 9"
          d="M28,1H0V-1H28Z"
          transform="translate(0 11)"
        />
        <path
          id="Line_10"
          data-name="Line 10"
          d="M28,1H0V-1H28Z"
          transform="translate(0 21)"
        />
      </g>
    </svg>
  );
};

export default ICSort;
