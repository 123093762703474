import * as apiService from "./apiService";
import * as endPoints from "../constants/endPoints";
import { HTTP_GET, HTTP_POST } from "../constants/common";
import { buildResponse } from "../helpers/common";
import { AnonymousListing, UpdateAnonymous } from "../interfaces/Anonymous";

//Api call for fetch anonymous suggestions lists fo the organisation.
export async function anonymousListing(payload?: AnonymousListing) {
  return await apiService.apiCall(
    endPoints.GET_ANONYMOUS_LIST_API,
    HTTP_GET,
    payload
  );
}

//Api call for update anonymous suggestions like notes and resolved status.
export async function updateAnonymousSuggestions(payload?: UpdateAnonymous) {
  const res = await apiService.apiCall(
    endPoints.UPDATE_ANONYMOUS_SUGGESTION_API,
    HTTP_POST,
    payload
  );
  return buildResponse(res);
}
