import { createSlice } from "@reduxjs/toolkit";

import { fetchMyProfile } from "../actions/myAccount";
import { InitialState } from "../../interfaces/ReduxState";
import { slices } from "../../constants/common";

// Define the initial state using that type
const initialState: InitialState = {
  loading: false,
  userProfile: null,
  error: "",
};

//Slice for fetching my account details
const myProfile = createSlice({
  name: slices.myAccount,

  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMyProfile.pending, (state) => {
      state.loading = true;
      state.userProfile = null;
    });
    builder.addCase(fetchMyProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.userProfile = action.payload;
      state.error = "";
    });
    builder.addCase(fetchMyProfile.rejected, (state, action) => {
      state.loading = false;
      state.userProfile = null;
      state.error = action?.error?.message;
    });
  },
});

export default myProfile.reducer;
