/**
 *@file SLSelect.tsx
 *@brief Reusable Select Component
 *@date Feb, 2023
 *@author ZCO Engineer
 *@copyright (c) 2024, ZCO
 */
import React from "react";
import { Form } from "react-bootstrap";
import "./SLSelect.scss";

const SLSelect = (props: any) => {
  const handleSelect = (id: string) => {
    props.handleSelection(id);
  };
  const errorClass: string =
    props.errorClass || props.errorMessage ? "form-error" : "";

  return (
    <>
      <Form.Group className="sl-select">
        {props.label && <Form.Label>{props.label}</Form.Label>}
        <Form.Select
          disabled={props.disabled}
          aria-label="Default select example"
          value={props.selectedValue}
          onChange={(e) => handleSelect(e.target.value)}
          className={errorClass}
        >
          <option value={0}>
            {props?.DefaultItemText || "Open this select menu"}
          </option>
          {props.list &&
            props.list.map((option: any, index: any) => (
              //'st_Code' and 'state' properties for statelist
              <option key={index} value={option.id || option?.st_Code}>
                {option.label || option?.state || option?.name}
              </option>
            ))}
        </Form.Select>

        <div className="d-flex">
          {props.errorMessage && (
            <div className="error-msg">{props.errorMessage}</div>
          )}
        </div>
      </Form.Group>
    </>
  );
};

export default SLSelect;
