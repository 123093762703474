const ICMore = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="24"
      viewBox="0 0 6 24"
    >
      <path
        id="more"
        d="M403-776a2.889,2.889,0,0,1-2.119-.881A2.889,2.889,0,0,1,400-779a2.889,2.889,0,0,1,.881-2.119A2.889,2.889,0,0,1,403-782a2.889,2.889,0,0,1,2.119.881A2.889,2.889,0,0,1,406-779a2.889,2.889,0,0,1-.881,2.119A2.889,2.889,0,0,1,403-776Zm0-9a2.889,2.889,0,0,1-2.119-.881A2.889,2.889,0,0,1,400-788a2.889,2.889,0,0,1,.881-2.119A2.889,2.889,0,0,1,403-791a2.889,2.889,0,0,1,2.119.881A2.889,2.889,0,0,1,406-788a2.889,2.889,0,0,1-.881,2.119A2.889,2.889,0,0,1,403-785Zm0-9a2.889,2.889,0,0,1-2.119-.881A2.889,2.889,0,0,1,400-797a2.889,2.889,0,0,1,.881-2.119A2.889,2.889,0,0,1,403-800a2.889,2.889,0,0,1,2.119.881A2.889,2.889,0,0,1,406-797a2.889,2.889,0,0,1-.881,2.119A2.889,2.889,0,0,1,403-794Z"
        transform="translate(-400 800)"
        fill="#f9fcff"
      />
    </svg>
  );
};

export default ICMore;
