const ICRallyPoints = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g id="Rally" transform="translate(-88 -1691)">
        <path
          id="Subtract"
          d="M4.5,13.5a12,12,0,0,1,24,0c0,4.3-2.219,7.917-4.631,10.586a30.734,30.734,0,0,1-6.3,5.234,2.007,2.007,0,0,1-2.138,0,30.751,30.751,0,0,1-6.3-5.234C6.719,21.417,4.5,17.8,4.5,13.5Zm12,4.923A4.923,4.923,0,1,0,11.577,13.5,4.923,4.923,0,0,0,16.5,18.423Z"
          transform="translate(86.5 1690.5)"
          fillRule="evenodd"
        />
        <rect
          id="Rectangle_438"
          data-name="Rectangle 438"
          width="30"
          height="30"
          transform="translate(88 1691)"
          opacity="0"
        />
      </g>
    </svg>
  );
};

export default ICRallyPoints;
