import { createAsyncThunk } from "@reduxjs/toolkit";
import { incidentReportListing } from "../../services/incidentService";
import { IncidentReportListing } from "../../interfaces/IncidentInterface";

// Action creator to call the incidentReportListing function with a payload to invoke the incident-Report listing API.
export const fetchIncidentReport = createAsyncThunk(
  "incidents/fetchreports",
  (payload: IncidentReportListing) => {
    return incidentReportListing(payload);
  }
);
