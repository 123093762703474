/**
 * @file   src\components\HeaderLogin.tsx
 * @brief  Login Header component.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import "./HeaderLogin.scss";
import Logo from "../assets/img/Logo.svg";
import { Button, NavDropdown } from "react-bootstrap";
import Strings from "../assets/strings/Strings.json";
import ICMore from "../assets/img/icons/More";
import ICDemo from "../assets/img/icons/Demo";
import { useLocation, useNavigate } from "react-router-dom";
import { pageURLs } from "../constants/pageURLs";
import { getLocalStorage } from "../helpers/common";
import { USER_INFO_KEY } from "../constants/common";

const HeaderLogin = () => {
  const location = useLocation();
  const pathName = location?.pathname?.toLowerCase();
  const navigate = useNavigate();
  const isLoginPage: boolean = pathName === pageURLs.login;
  const handleLogoClick = () => {
    const userDetails = getLocalStorage(USER_INFO_KEY);
    if (userDetails?.accessToken) {
      navigate(pageURLs.dashboard);
    } else {
      navigate(pageURLs.login);
    }
  };
  return (
    <header className="login-header d-flex justify-content-between align-items-center">
      <div className="logo-wrap">
        <img
          className={!isLoginPage ? "pe-pointer" : ""}
          src={Logo}
          alt=""
          onClick={() => (!isLoginPage ? handleLogoClick() : "")}
        />
      </div>
      <div className="menu-wrap">
        {isLoginPage && (
          <Button
            variant="secondary"
            onClick={() =>
              window.open(Strings.LoginHeader.reqDemoLink, "_blank")
            }
          >
            <ICDemo />
            {Strings.LoginHeader.reqDemoTitle}
          </Button>
        )}

        <NavDropdown
          title={
            <div className="more-wrap d-flex align-items-center justify-content-center">
              <ICMore />
            </div>
          }
        >
          {isLoginPage && (
            <NavDropdown.Item
              onClick={() =>
                window.open(Strings.LoginHeader.reqDemoLink, "_blank")
              }
            >
              <ICDemo /> {Strings.LoginHeader.reqDemoTitle}
            </NavDropdown.Item>
          )}
        </NavDropdown>
      </div>
    </header>
  );
};

export default HeaderLogin;
