const ICEmergency = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g id="Emergency" transform="translate(-95 -1126)">
        <rect
          id="Rectangle_437"
          data-name="Rectangle 437"
          width="30"
          height="30"
          transform="translate(95 1126)"
          fill="#fff"
          opacity="0"
        />
        <circle
          id="Ellipse_3"
          data-name="Ellipse 3"
          cx="3.312"
          cy="3.312"
          r="3.312"
          transform="translate(106.561 1144.664)"
        />
        <path
          id="Union_7"
          data-name="Union 7"
          d="M-16.03-1115.219h-6.006a1,1,0,0,1-1-1,1,1,0,0,1,1-1,1.541,1.541,0,0,0,1.538-1.539v-5.923a7.778,7.778,0,0,1,7.77-7.769,7.717,7.717,0,0,1,5.494,2.275,7.719,7.719,0,0,1,2.275,5.494v5.923a1.541,1.541,0,0,0,1.539,1.539,1,1,0,0,1,1,1,1,1,0,0,1-1,1H-9.679a3.313,3.313,0,0,1-3.175,2.365A3.314,3.314,0,0,1-16.03-1115.219Z"
          transform="translate(122.728 2264.142)"
        />
        <path
          id="Vector_4"
          d="M.464,9.938a1,1,0,0,1-1-1A11.18,11.18,0,0,1,4.112-.116,1,1,0,0,1,5.278,1.509a9.175,9.175,0,0,0-3.814,7.43A1,1,0,0,1,.464,9.938Z"
          transform="translate(98.536 1130.304)"
        />
        <path
          id="Vector_5"
          d="M14.445,9.938a1,1,0,0,1-1-1,9.175,9.175,0,0,0-3.814-7.43A1,1,0,1,1,10.8-.116a11.18,11.18,0,0,1,4.647,9.053A1,1,0,0,1,14.445,9.938Z"
          transform="translate(106.555 1130.304)"
        />
      </g>
    </svg>
  );
};

export default ICEmergency;
