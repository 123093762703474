import { createSlice } from "@reduxjs/toolkit";
import { fetchAnonymousListing } from "../actions/anonymousBox";
import { InitialState } from "../../interfaces/ReduxState";
import { slices } from "../../constants/common";

// Define the initial state using that type

//Initial state for list
const initialState: InitialState = {
  loading: true,
  list: [],
  error: "",
};

//Slice for fetching anonymous list
const anonymousBoxList = createSlice({
  name: slices.anonymous,

  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAnonymousListing.pending, (state) => {
      state.list = [];
      state.loading = true;
    });
    builder.addCase(fetchAnonymousListing.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload?.data;
      state.error = "";
    });
    builder.addCase(fetchAnonymousListing.rejected, (state, action) => {
      state.loading = false;
      state.list = [];
      state.error = action?.error?.message;
    });
  },
});

export default anonymousBoxList.reducer;
