/**
 * @file   src\components\Paginate.tsx
 * @brief  List pagination reusable component.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { Col, Pagination, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PaginateProps } from "../../interfaces/Paginate";
import {
  ONE,
  PAGE_SIZE_100,
  PAGE_SIZE_20,
  PAGE_SIZE_50,
  ZERO,
} from "../../constants/common";

const Paginate: React.FC<PaginateProps> = (props: PaginateProps) => {
  const totalRecords = props.totalRecords;
  const currentPage = props.currentPage;
  const perPage = props.perPage;

  const prevPage = currentPage >= ONE ? currentPage - ONE : ONE;
  const nextPage = currentPage >= ONE ? currentPage + ONE : ONE;

  const firstPage = ONE;
  const leftAndRight = 4;

  let startPage = ONE;
  let startMinus = ZERO;

  const totalPages =
    totalRecords % perPage === ZERO
      ? totalRecords / perPage
      : Math.ceil(totalRecords / perPage);
  let endPage = totalPages;

  if (totalPages <= ONE) {
    startPage = firstPage;
    endPage = totalPages;
  } else {
    const start = currentPage - leftAndRight;
    if (start < firstPage) {
      startMinus = -ONE * start;
    }

    startPage = start < firstPage ? firstPage : start;

    let end = currentPage + leftAndRight;
    // Adding left side to right side if < 0
    end = end + startMinus;
    endPage = end > totalPages ? totalPages : end;

    const endMinus = endPage - leftAndRight - leftAndRight;
    if (endMinus < startPage && endMinus > ZERO) startPage = endMinus;
  }

  const rightEllipsis =
    currentPage + 10 > totalPages ? totalPages : currentPage + 10;
  const leftEllipsis =
    currentPage - 10 < firstPage ? firstPage : currentPage - 10;

  const pageItem = () => {
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        i === currentPage ? (
          <Pagination.Item
            key={"Item" + i}
            active
            onClick={() => props.onClick(i)}
          >
            {i}
          </Pagination.Item>
        ) : (
          <Pagination.Item key={"Item" + i} onClick={() => props.onClick(i)}>
            {i}
          </Pagination.Item>
        )
      );
    }
    return pages;
  };

  return (
    <Row className="pagination-wrapper">
      <Col md="6" xl="6" className="show-pages">
        Show{" "}
        <Link
          onClick={() => props.onShowClick(PAGE_SIZE_20)}
          to=""
          className={props.perPage === PAGE_SIZE_20 && "active"}
        >
          {PAGE_SIZE_20}
        </Link>{" "}
        |{" "}
        <Link
          onClick={() => props.onShowClick(PAGE_SIZE_50)}
          to=""
          className={props.perPage === PAGE_SIZE_50 ? "active" : ""}
        >
          {PAGE_SIZE_50}
        </Link>{" "}
        |{" "}
        <Link
          onClick={() => props.onShowClick(PAGE_SIZE_100)}
          to=""
          className={props.perPage === PAGE_SIZE_100 ? "active" : ""}
        >
          {PAGE_SIZE_100}
        </Link>
      </Col>
      <Col md="6" xl="6" className="d-flex justify-content-end">
        <Pagination>
          <Pagination.Prev
            onClick={() => props.onClick(prevPage)}
            className="previous"
            disabled={currentPage === ONE}
          >
            &lt; Previous
          </Pagination.Prev>
          <span className="vertical-divider-start d-flex align-items-center">
            |
          </span>
          {firstPage === startPage ? (
            ""
          ) : (
            <>
              <Pagination.Item onClick={() => props.onClick(firstPage)}>
                {firstPage}{" "}
              </Pagination.Item>
            </>
          )}
          {startPage > 2 ? (
            <Pagination.Ellipsis onClick={() => props.onClick(leftEllipsis)} />
          ) : (
            ""
          )}
          {pageItem()}

          {totalPages - endPage > ONE ? (
            <Pagination.Ellipsis onClick={() => props.onClick(rightEllipsis)} />
          ) : (
            ""
          )}

          {totalPages === endPage ? (
            ""
          ) : (
            <Pagination.Item onClick={() => props.onClick(totalPages)}>
              {totalPages}{" "}
            </Pagination.Item>
          )}
          <span className="vertical-divider-end d-flex align-items-center">
            |
          </span>
          <Pagination.Next
            onClick={() => props.onClick(nextPage)}
            disabled={currentPage === endPage}
          >
            Next &gt;
          </Pagination.Next>
        </Pagination>
      </Col>
    </Row>
  );
};

export default Paginate;
