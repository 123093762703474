import { createSlice } from "@reduxjs/toolkit";

import { fetchImportantLinksListing } from "../actions/importantLinks";
import { InitialState } from "../../interfaces/ReduxState";
import { slices } from "../../constants/common";

// Define the initial state using that type
const initialState: InitialState = {
  loading: false,
  list: [],
  error: "",
};

//Slice for fetch important links list
const importantLinkList = createSlice({
  name: slices.importantLink,

  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchImportantLinksListing.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchImportantLinksListing.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload;
      state.error = "";
    });
    builder.addCase(fetchImportantLinksListing.rejected, (state, action) => {
      state.loading = false;
      state.list = [];
      state.error = action?.error?.message;
    });
  },
});

export default importantLinkList.reducer;
