import { createAsyncThunk } from "@reduxjs/toolkit";
import { getImpLinks } from "../../services/importantLinksService";

// Action creator to call the getImpLinks function to invoke the important listing API.
export const fetchImportantLinksListing = createAsyncThunk(
  "importantLink/fetchlists",
  () => {
    return getImpLinks();
  }
);
