import { createSlice } from "@reduxjs/toolkit";
import { fetchBuildingList } from "../actions/manageBuildings";
import { InitialState } from "../../interfaces/ReduxState";
import { slices } from "../../constants/common";

// Define the initial state using that type
const initialState: InitialState = {
  loading: true,
  list: [],
  error: "",
};

//Slice for fetch building list
const rallyPoints = createSlice({
  name: slices.buildings,

  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBuildingList.pending, (state) => {
      state.list = [];
      state.loading = true;
    });
    builder.addCase(fetchBuildingList.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload?.data?.list;
      state.error = "";
    });
    builder.addCase(fetchBuildingList.rejected, (state, action) => {
      state.loading = false;
      state.list = [];
      state.error = action?.error?.message;
    });
  },
});

export default rallyPoints.reducer;
