const ICUser = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g id="user" transform="translate(-84 -1408)">
        <rect
          id="Rectangle_432"
          data-name="Rectangle 432"
          width="30"
          height="30"
          transform="translate(84 1408)"
          opacity="0"
        />
        <path
          id="Union_9"
          data-name="Union 9"
          d="M-85.238-1398.76a1,1,0,0,1-.808-.41,1,1,0,0,1-.144-.895,12.466,12.466,0,0,1,4.555-6.307,12.472,12.472,0,0,1,7.4-2.412,12.472,12.472,0,0,1,7.4,2.412,12.466,12.466,0,0,1,4.555,6.307,1,1,0,0,1-.144.895,1,1,0,0,1-.808.41Z"
          transform="translate(173.238 2832.337)"
        />
        <path
          id="Union_10"
          data-name="Union 10"
          d="M-86.239-1403.749a5.041,5.041,0,0,1,5.035-5.036,5.041,5.041,0,0,1,5.035,5.036,5.041,5.041,0,0,1-5.035,5.035A5.041,5.041,0,0,1-86.239-1403.749Z"
          transform="translate(180.204 2820.784)"
        />
      </g>
    </svg>
  );
};

export default ICUser;
