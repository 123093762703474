import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  emergencyListing,
  emergencyDetails,
  emergencySessionList,
} from "../../services/emergencyCheckInService";
import {
  EmergencyChkInList,
  EmergencySessionList,
  EmergencyDetails,
} from "../../interfaces/Emergency";

// Action creator to call the emergency chekin list function with a payload to invoke the emergency chekin API .
export const fetchEmergencyListing = createAsyncThunk(
  "anonymous/fetchEmergencyListing",
  (payload: EmergencyChkInList) => {
    return emergencyListing(payload);
  }
);

// Action creator to call the emergency chekin details function with a payload to invoke the emergency chekin API.
export const fetchChkInDetails = createAsyncThunk(
  "anonymous/fetchEmergencyChkInDetails",
  (payload: EmergencyDetails) => {
    return emergencyDetails(payload);
  }
);

// Action creator to call the emergency chekin SessionList function with a payload to invoke the emergency chekin session listing API.
export const fetchEmergencySessionList = createAsyncThunk(
  "anonymous/fetchEmergencySessionList",
  (payload: EmergencySessionList) => {
    return emergencySessionList(payload);
  }
);
