/**
 * @file   src\components\AppStoreDetails.tsx
 * @brief  App stored details logo and link reusable component.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import ICAndroid from "../assets/img/icons/Android";
import ICApple from "../assets/img/icons/Apple";
import Strings from "../assets/strings/Strings.json";
import { ANDROID_STORE, APPLE_STORE } from "../constants/common";
import { openStore } from "../helpers/common";

const AppStoreDetails = () => {
  return (
    <div className="text-center m-auto download-wrap">
      <p>{Strings.Login.DownloadInfo}</p>
      <div className="download-links d-flex justify-content-center">
        <span
          className="d-flex align-items-center justify-content-center"
          onClick={() => openStore(APPLE_STORE)}
        >
          <ICApple />
        </span>
        <span
          className="d-flex align-items-center justify-content-center"
          onClick={() => openStore(ANDROID_STORE)}
        >
          <ICAndroid />
        </span>
      </div>
    </div>
  );
};

export default AppStoreDetails;
