const ICShare = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="Share" transform="translate(-1268.823 -18.945)">
        <rect
          id="Rectangle_442"
          data-name="Rectangle 442"
          width="24"
          height="24"
          transform="translate(1268.823 18.945)"
          fill="none"
        />
        <path
          id="Subtract"
          d="M0,3.714A2.464,2.464,0,0,1,2.464,1.25H20.536A2.464,2.464,0,0,1,23,3.714v.562L12.129,11.523a1.255,1.255,0,0,1-1.258,0L0,4.276ZM0,6.743V17.676A2.464,2.464,0,0,0,2.464,20.14H20.536A2.464,2.464,0,0,0,23,17.676V6.743l-9.737,6.492-.008.007a3.3,3.3,0,0,1-3.509,0l-.008-.007Z"
          transform="translate(1269.323 20.25)"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default ICShare;
