/**
 * @file   src\containers\AnonymousSuggestionBox.tsx
 * @brief  AnonymousSuggestionBox page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import Strings from "../assets/strings/Strings.json";
import { Button, Col, Row, Table, Form } from "react-bootstrap";
import ICTableExpand from "../assets/img/icons/TableExpand";
import ICShare from "../assets/img/icons/Share";
import { useState, useEffect } from "react";
import { updateAnonymousSuggestions } from "../services/anonymousService";
import {
  DATE_FORMAT_WITH_TIME,
  HTTP_RESPONSE_STATUS_200,
  ONE,
  PAGE_SIZE_20,
  NOTE,
  WIDTH_175,
  ZERO,
  csvDownload,
  tableHeaders,
  noEmail,
  emailBody,
} from "../constants/common";
import {
  sendEmailContactSupport,
  downloadCSV,
  pushAndFormatCSVArray,
  toggleCheck,
  dateConversion,
  buildEmailBody,
} from "../helpers/common";
import AddEditNote from "../components/common/AddEditNote";
import TableHeader from "../components/common/TableHeader";
import Paginate from "../components/common/Paginate";
import Search from "../components/common/Search";
import { fetchAnonymousListing } from "../Redux/actions/anonymousBox";
import { useAppSelector, useAppDispatch } from "../Redux/hooks";
import { ResponseObjects } from "../interfaces/AxiosResponse";
import { SearchRequest } from "../interfaces/Search";

const AnonymousSuggestionBox = () => {
  const [anonymousList, setAnonymousList] = useState<any[]>([]);
  const [csvData, setCsvData] = useState<any>([]);
  const [page, setPage] = useState<number>(ONE);
  const [perPage, setPerPage] = useState<number>(PAGE_SIZE_20);
  const [totalRecord, setTotalRecord] = useState<number>(ZERO);
  const [searchText, setSearchText] = useState<string>("");
  const anonymousLists = useAppSelector((state: any) => state.anonymous?.list);
  const anonymousListsLoading = useAppSelector(
    (state: any) => state.anonymous?.loading
  );
  const mailSubject = "SpotLight Saftey Anonymous Suggestion";
  const dispatch = useAppDispatch();

  const columns = [
    { title: tableHeaders.common.toggle, isSort: false },
    { title: tableHeaders.common.uniqueId, isSort: false },
    { title: tableHeaders.common.timestamp, isSort: false },
    { title: tableHeaders.common.message, isSort: false },
    { title: tableHeaders.common.notes, isSort: false },
    {
      title: tableHeaders.common.resolved,
      isSort: false,
      thClass: "text-center",
    },
    { title: tableHeaders.common.share, isSort: false, thClass: "text-center" },
  ];

  // Handle to calling the anonymous list API.
  useEffect(() => {
    dispatch(
      fetchAnonymousListing({
        pageNo: page,
        pageSize: perPage,
        searchText: searchText,
      })
    );
  }, [page, perPage]);

  // Handle response from the anonymous list  api call
  useEffect(() => {
    if (!anonymousListsLoading) {
      loadAnonymousList();
    }
  }, [anonymousLists]);

  // Handle setting the response from the anonymous list API call into state.
  const loadAnonymousList = async () => {
    const list = anonymousLists?.list;
    if (list) {
      const csvArry: [] = pushAndFormatCSVArray(
        list,
        csvDownload.anonymousSuggestion
      );
      setTotalRecord(anonymousLists?.totalRecords);
      setCsvData(csvArry);
      setAnonymousList(list);
      getToggleDisabled(list);
    } else setTotalRecord(ZERO);
  };

  // Handle calling the anonymous list API with a search payload
  const onSearchClick = (payload: SearchRequest) => {
    dispatch(fetchAnonymousListing(payload));
  };

  // Handle updating the note and isResolved state and calling the update anonymous suggestion API.
  const updateData = async (
    id: number,
    value: number | string,
    type: string
  ) => {
    let payload: any = {};
    if (type === NOTE) {
      payload = {
        id: id,
        notes: value,
      };
    } else {
      payload = {
        id: id,
        resolved: value,
      };
    }
    const response: ResponseObjects = await updateAnonymousSuggestions(payload);
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      const copyList = [...anonymousList];
      const newState = copyList.map((obj) => {
        if (obj.aS_Id === id) {
          if (type === NOTE) {
            return { ...obj, notes: value, toggleDisabled: false };
          } else {
            return { ...obj, isResolved: value };
          }
        }
        return obj;
      });
      const csvArry: [] = pushAndFormatCSVArray(
        newState,
        csvDownload.anonymousSuggestion
      );
      setAnonymousList(newState);
      setCsvData(csvArry);
    }
    return response?.status;
  };

  // Handle calling the update anonymous suggestion API for resolved switch payload.
  const handleResolvedSwitch = (id: number, value: number) => {
    updateData(id, value === ZERO ? ONE : ZERO, "");
  };

  // Handle toggle change to expand note and message.
  const handleToggleChange = (id: number, message: string, notes: string) => {
    if (message || notes) {
      const updatedArray = anonymousList.map((obj: any) => {
        if (obj.aS_Id === id) {
          return { ...obj, toggle: !obj.toggle };
        }
        return obj;
      });
      setAnonymousList(updatedArray);
    }
  };

  // page change and state change
  const pageChange = (page: number) => {
    if (page) {
      setPage(page);
    }
  };

  // page size change and state change
  const perPageChange = (page: number) => {
    if (page) {
      setPage(ONE);
      setPerPage(page);
    }
  };

  // Handle to disable the toggle for empty note and message anonymous list.
  const getToggleDisabled = (anonymousData: []) => {
    if (anonymousData) {
      setTimeout(() => {
        const updatedArray: any = [];
        anonymousData.map((obj: any) => {
          const [eHeight, nHeight] = toggleCheck(obj.aS_Id);
          if (!obj.notes && eHeight === nHeight) {
            updatedArray.push({ ...obj, toggleDisabled: true });
          } else {
            updatedArray.push({ ...obj, toggleDisabled: false });
          }
        });
        setAnonymousList(updatedArray);
      }, 100);
    }
  };

  // Bind anonymous list in the UI
  const bindAnonymousList = () => {
    if (anonymousListsLoading) {
      return (
        <Col className="text-center no-record mt-5">
          <output className="spinner-border"></output>
        </Col>
      );
    } else {
      if (anonymousList?.length > ZERO) {
        return (
          <Table responsive>
            <TableHeader columns={columns} />
            <tbody>
              {anonymousList.map((items: any) => (
                <tr key={items?.aS_Id}>
                  <td
                    className="toggle"
                    onClick={() => {
                      handleToggleChange(
                        items.aS_Id,
                        items?.message,
                        items?.notes
                      );
                    }}
                  >
                    <span
                      className={
                        items?.toggleDisabled
                          ? "disabled"
                          : !items.toggle
                            ? "tableExpand"
                            : "tableCollapse"
                      }
                    >
                      <ICTableExpand />
                    </span>
                  </td>
                  <td className="user" width={WIDTH_175}>
                    <span>{items?.uniqueId}</span>
                  </td>
                  <td width={WIDTH_175}>
                    {items.createdOn &&
                      dateConversion(items.createdOn, DATE_FORMAT_WITH_TIME)}
                  </td>
                  <td
                    data-id={items?.aS_Id}
                    className={!items.toggle ? "messages" : "messages expand"}
                  >
                    <span className="d-block">{items?.message}</span>
                  </td>

                  <AddEditNote
                    handleSave={updateData}
                    items={items}
                    id={items?.aS_Id}
                  />

                  <td className="tb-switch-center">
                    <Form.Check
                      type="switch"
                      value={items?.isResolved}
                      checked={items?.isResolved}
                      onChange={() =>
                        handleResolvedSwitch(items?.aS_Id, items?.isResolved)
                      }
                      id="custom-switch"
                    />
                  </td>
                  <td className="ic-yellow">
                    <a
                      role="button"
                      href={void 0}
                      onClick={() =>
                        sendEmailContactSupport(
                          noEmail,
                          buildEmailBody(items, emailBody.anonymous),
                          mailSubject
                        )
                      }
                    >
                      <ICShare />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        );
      } else {
        return (
          <Col className="text-center mt-3 mb-3">
            {Strings.AnonymousSuggestionBox.NoDataMessage}
          </Col>
        );
      }
    }
  };
  return (
    <>
      <Row className="tilte-wrapper">
        <Col lg="12">
          <h1>
            <span className="text-uppercase">
              {Strings.Common.InnerSubTitle}
            </span>
            <br />
            {Strings.AnonymousSuggestionBox.Title}
          </h1>
        </Col>
      </Row>
      <Row className="action-wrapper">
        <Col md="6" xl="4">
          <Search
            onClick={onSearchClick}
            pageSize={perPage}
            setPage={setPage}
            page={page}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </Col>
        <Col md="6" xl="8" className="text-end">
          <Button
            disabled={anonymousList?.length === ZERO}
            onClick={() =>
              downloadCSV(csvData, csvDownload.anonymousSuggestion)
            }
          >
            {Strings.common.dowloadCSV}
          </Button>
        </Col>
      </Row>

      {bindAnonymousList()}
      {totalRecord !== ZERO && (
        <Paginate
          totalRecords={totalRecord}
          currentPage={page}
          perPage={perPage}
          onClick={pageChange}
          onShowClick={perPageChange}
        />
      )}
    </>
  );
};

export default AnonymousSuggestionBox;
