const ICApple = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="66"
      viewBox="0 0 56 66"
    >
      <g
        id="Group_416"
        data-name="Group 416"
        transform="translate(-627.133 -774.215)"
      >
        <rect
          id="Rectangle_398"
          data-name="Rectangle 398"
          width="56"
          height="66"
          transform="translate(627.133 774.215)"
          fill="none"
        />
        <path
          id="Union_2"
          data-name="Union 2"
          d="M1562.658,62.14c-8.661-8.835-12.88-19.382-11.35-31.8a16.57,16.57,0,0,1,12.487-14.42,12.511,12.511,0,0,1,8.618.888,11.5,11.5,0,0,0,10.427,0,15.6,15.6,0,0,1,14.359.635,13,13,0,0,1,4.628,4.218,38.338,38.338,0,0,0-3.184,2.758A14.143,14.143,0,0,0,1602,46.352c1.419.841,1.506,1.511.947,2.876a40.608,40.608,0,0,1-7.328,11.767c-2.732,3.064-6.027,4.071-9.96,2.609-.646-.24-1.3-.456-1.937-.727a13,13,0,0,0-10.214-.116c-1.026.4-2.079.74-3.109,1.137a7.489,7.489,0,0,1-2.7.532A6.977,6.977,0,0,1,1562.658,62.14ZM1577.21,12.93c.124-5.408,4.5-11.286,11.127-12.839A9.779,9.779,0,0,1,1590.019,0c.964,6.455-4.748,13.941-10.935,14.6-.387.041-.7.068-.943.068C1577.242,14.665,1577.178,14.3,1577.21,12.93Z"
          transform="translate(-922 775)"
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default ICApple;
