const ICIncidentReport = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g id="Group_492" data-name="Group 492" transform="translate(-92 -1193)">
        <path
          id="Path_2560"
          data-name="Path 2560"
          d="M23.061,2.97a3.078,3.078,0,0,0-2.174-.891H18.034A3.087,3.087,0,0,0,15.124,0H8.846A3.079,3.079,0,0,0,5.935,2.079H3.092A3.085,3.085,0,0,0,0,5.151V25.935a3.085,3.085,0,0,0,3.092,3.072h17.79a3.086,3.086,0,0,0,3.093-3.071V5.139a3.042,3.042,0,0,0-.914-2.169M7.745,3.107a1.109,1.109,0,0,1,.093-.443,1.09,1.09,0,0,1,1.009-.676h6.278a1.09,1.09,0,0,1,1.092,1.085V4.109A1.091,1.091,0,0,1,15.124,5.2H15.1l-.015,0H11.327l-2.48,0h0a1.093,1.093,0,0,1-1.007-.682,1.111,1.111,0,0,1-.091-.44Zm7.073,18.342L6.636,17.072,14.818,12.7Z"
          transform="translate(95 1193.993)"
        />
        <rect
          id="Rectangle_439"
          data-name="Rectangle 439"
          width="30"
          height="30"
          transform="translate(92 1193)"
          fill="none"
        />
      </g>
    </svg>
  );
};

export default ICIncidentReport;
