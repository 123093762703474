/**
 * @file   src\containers\TroubleLogin.tsx
 * @brief  Trouble login page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import HeaderLogin from "../components/HeaderLogin";
import "../assets/styles/Login.scss";
import Strings from "../assets/strings/Strings.json";
import { Link, useSearchParams } from "react-router-dom";
import FooterLogin from "../components/FooterLogin";
import TroubleLoginEmail from "../components/TroubleLoginEmail";
import TroubleLoginPassword from "../components/TroubleLoginPassword";
import { useEffect, useRef, useState } from "react";
import { emailValidation, passwordValidation } from "../helpers/validations";
import { Alert } from "react-bootstrap";
import {
  forgetPasswordEmailSend,
  tokenValidation,
  createUpdatePassword,
} from "../services/loginService";
import {
  ALERT_DANGER,
  ALERT_SUCCESS,
  EMAIL,
  EMPTY_STRING,
  HTTP_RESPONSE_STATUS_200,
} from "../constants/common";
import { removeWhiteSpace } from "../helpers/common";
import { ResetForm, ResetFormError } from "../interfaces/ResetPassword";
import { ResponseObjects } from "../interfaces/AxiosResponse";
import {
  CONFIRM_PASSWORD_VALIDATION,
  ENTER_EMAIL,
  ENTER_PASSWORD,
  INVALID_EMAIL,
  PASSWORD_MISMATCH,
  RE_ENTER_PASSWORD,
} from "../constants/validationMessages";
import { ChangePassword } from "../interfaces/Login";
import { pageURLs } from "../constants/pageURLs";
import ReCAPTCHA from "react-google-recaptcha";
import AppStoreDetails from "../components/AppStoreDetails";

const TroubleLogin = () => {
  //Constant variable declarations
  const emailView = "emailView";
  const pwdView = "pwdView";
  const inValidTokenView = "inValidTokenView";
  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get("token");
  const [load, setLoad] = useState<boolean>(false); // Loader state

  //Initial form values
  const [resetForm, setResetForm] = useState<ResetForm>({
    email: "",
    newPassword: "",
    confPassword: "",
    activeView: "",
  });

  //Initialise recaptcha ref
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const deviceType = "web";

  //This useefect will responsible for checking the token is valida before render the respective UI and set the view.
  useEffect(() => {
    if (resetToken) {
      validateToken();
    } else {
      setResetForm((form) => ({
        ...form,
        activeView: emailView,
      }));
    }
  }, []);

  //Call the validate token API and based on the response the wll set the active view
  const validateToken = async () => {
    const response = await tokenValidation(resetToken);
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      setResetForm((form) => ({
        ...form,
        activeView: pwdView,
      }));
    } else {
      setResetForm((form) => ({
        ...form,
        activeView: inValidTokenView,
      }));
      setResetError((error) => ({
        ...error,
        tokenError: response?.message,
      }));
    }
  };

  const isChangePwdView = resetForm?.activeView === pwdView;
  //Initial form errors
  const [resetError, setResetError] = useState<ResetFormError>({
    emailError: EMPTY_STRING,
    newPasswordError: EMPTY_STRING,
    confPasswordError: EMPTY_STRING,
    generalError: EMPTY_STRING,
    successMessage: EMPTY_STRING,
    tokenError: EMPTY_STRING,
  });

  //Handle the text box changes and update into the state
  const onTextChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const currentId = e.currentTarget.id;
    setResetForm((form) => ({
      ...form,
      [currentId]: currentId !== EMAIL ? removeWhiteSpace(value) : value,
    }));
  };

  //Validate form data in submit
  const validateForm = () => {
    setResetError((error) => ({
      ...error,
      emailError: EMPTY_STRING,
      newPasswordError: EMPTY_STRING,
      confPasswordError: EMPTY_STRING,
      generalError: EMPTY_STRING,
    }));
    let isValid = true;
    if (isChangePwdView) {
      const newPwd: string = resetForm?.newPassword?.trim();
      const confPwd: string = resetForm?.confPassword?.trim();
      if (!newPwd && !confPwd) {
        setResetError((error) => ({
          ...error,
          newPasswordError: ENTER_PASSWORD,
          confPasswordError: CONFIRM_PASSWORD_VALIDATION,
        }));
        isValid = false;
      } else if (!newPwd) {
        setResetError((error) => ({
          ...error,
          newPasswordError: ENTER_PASSWORD,
        }));
        isValid = false;
      } else if (!confPwd) {
        setResetError((error) => ({
          ...error,
          confPasswordError: RE_ENTER_PASSWORD,
        }));
        isValid = false;
      } else if (newPwd) {
        const passWordError = passwordValidation(newPwd);
        if (passWordError) {
          setResetError((error) => ({
            ...error,
            newPasswordError: passWordError,
          }));
          isValid = false;
        } else {
          if (confPwd) {
            if (newPwd !== confPwd) {
              setResetError((error) => ({
                ...error,
                confPasswordError: PASSWORD_MISMATCH,
              }));
              isValid = false;
            }
          }
        }
      }
    } else {
      const email: string = resetForm?.email?.trim();
      if (email) {
        if (!emailValidation(email)) {
          setResetError((error) => ({
            ...error,
            emailError: INVALID_EMAIL,
          }));
          isValid = false;
        }
      } else {
        setResetError((error) => ({
          ...error,
          emailError: ENTER_EMAIL,
        }));
        isValid = false;
      }
    }
    return isValid;
  };

  //Handle form submit click
  const handleSubmit = async () => {
    let reqPayload: ChangePassword;
    const isValid: boolean = validateForm();
    if (isValid) {
      setLoad(true);
      let recaptchaToken = "";
      if (reCaptchaRef.current)
        recaptchaToken = await reCaptchaRef.current.executeAsync();

      if (isChangePwdView) {
        reqPayload = {
          uniqueId: resetToken,
          password: resetForm?.newPassword,
        };
        if (recaptchaToken) {
          reqPayload.recaptchaToken = recaptchaToken;
        }
        const response: ResponseObjects =
          await createUpdatePassword(reqPayload);
        if (response?.status === HTTP_RESPONSE_STATUS_200) {
          setResetError((error) => ({
            ...error,
            successMessage: response?.message,
          }));
          setResetForm((form) => ({
            ...form,
            newPassword: EMPTY_STRING,
            confPassword: EMPTY_STRING,
          }));
        } else {
          setResetError((error) => ({
            ...error,
            generalError: response?.message,
          }));
        }
      } else {
        reqPayload = {
          emailId: resetForm?.email.trim(),
        };
        if (recaptchaToken) {
          reqPayload.recaptchaToken = recaptchaToken;
          reqPayload.type = deviceType;
        }
        const response: ResponseObjects =
          await forgetPasswordEmailSend(reqPayload);
        if (response?.status === HTTP_RESPONSE_STATUS_200) {
          setResetError((error) => ({
            ...error,
            successMessage: response?.message,
          }));
          setResetForm((form) => ({
            ...form,
            email: EMPTY_STRING,
          }));
        } else {
          setResetError((error) => ({
            ...error,
            generalError: response?.message,
          }));
        }
      }
    }
    setLoad(false);
  };
  const currentActiveView = resetForm?.activeView;

  //This will set th active view, ie email only view for send email to the user with reset link and password view is for change password
  const setActiveView = () => {
    if (currentActiveView) {
      if (currentActiveView === inValidTokenView) {
        return <Alert variant={ALERT_DANGER}> {resetError?.tokenError}</Alert>;
      } else if (isChangePwdView) {
        return (
          <TroubleLoginPassword
            onTextChange={onTextChange}
            errors={resetError}
            values={resetForm}
            handleSubmit={handleSubmit}
            isLoad={load}
          />
        );
      } else {
        return (
          <TroubleLoginEmail
            onTextChange={onTextChange}
            error={resetError?.emailError}
            value={resetForm?.email}
            handleSubmit={handleSubmit}
            isLoad={load}
          />
        );
      }
    }
  };

  return (
    <>
      <HeaderLogin />
      <div className="login-wrap">
        <div className="form-wrap m-auto">
          <h1 className="text-center">
            {/* <span className="text-uppercase">{Strings.Login.Subtitle}</span>
            <br /> */}
            {currentActiveView === emailView
              ? Strings.Login.troubleLoginTitle
              : Strings.Login.resetPasswordTitle}
          </h1>
          {(resetError?.generalError || resetError?.successMessage) && (
            <Alert
              variant={resetError?.generalError ? ALERT_DANGER : ALERT_SUCCESS}
              dismissible
            >
              {" "}
              {resetError?.generalError || resetError?.successMessage}
            </Alert>
          )}

          {setActiveView()}

          <div className="w-100 text-center trouble-login">
            <Link to={pageURLs.login}>{Strings.TroubleLogin.LoginBack}</Link>
          </div>
        </div>
        <AppStoreDetails />
      </div>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
        size="invisible"
        ref={reCaptchaRef}
      />
      <FooterLogin />
    </>
  );
};

export default TroubleLogin;
