const ICOrganization = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_429"
            data-name="Rectangle 429"
            width="23.999"
            height="22.308"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect
            id="Rectangle_428"
            data-name="Rectangle 428"
            width="23.999"
            height="22.308"
            transform="translate(0 0)"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Organization" transform="translate(-88 -1610)">
        <rect
          id="Rectangle_435"
          data-name="Rectangle 435"
          width="30"
          height="30"
          transform="translate(88 1610)"
          fill="#fff"
          opacity="0"
        />
        <g
          id="Group_457"
          data-name="Group 457"
          transform="translate(91 1614)"
          clipPath="url(#clip-path)"
        >
          <g id="Group_456" data-name="Group 456">
            <g
              id="Group_455"
              data-name="Group 455"
              clipPath="url(#clip-path-2)"
            >
              <g id="Group_454" data-name="Group 454">
                <g id="Group_453" data-name="Group 453">
                  <g
                    id="Group_452"
                    data-name="Group 452"
                    clipPath="url(#clip-path-2)"
                  >
                    <path
                      id="Path_2550"
                      data-name="Path 2550"
                      d="M21.462,15.246v-2.4a2.694,2.694,0,0,0-2.691-2.692H13V7.061a2.688,2.688,0,0,0,2.539-2.677V2.691A2.694,2.694,0,0,0,12.848,0H11.153A2.7,2.7,0,0,0,8.462,2.69h0V4.384A2.688,2.688,0,0,0,11,7.06v3.093H5.232a2.7,2.7,0,0,0-2.693,2.692v2.4A2.691,2.691,0,0,0,0,17.922H0v1.692a2.7,2.7,0,0,0,2.692,2.692H4.386a2.694,2.694,0,0,0,2.691-2.692V17.923a2.688,2.688,0,0,0-2.538-2.677v-2.4a.694.694,0,0,1,.693-.692H11v3.093a2.691,2.691,0,0,0-2.538,2.676h0v1.692a2.7,2.7,0,0,0,2.693,2.693h1.693a2.7,2.7,0,0,0,2.691-2.692V17.923A2.688,2.688,0,0,0,13,15.246V12.153h5.771a.693.693,0,0,1,.691.692v2.4a2.688,2.688,0,0,0-2.539,2.677v1.692a2.7,2.7,0,0,0,2.692,2.692h1.693A2.694,2.694,0,0,0,24,19.615V17.923a2.688,2.688,0,0,0-2.537-2.677"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ICOrganization;
