/**
 *@file SLInput.tsx
 *@brief Reusable input component
 *@date Feb, 2023
 *@author ZCO Engineer
 *@copyright (c) 2024, ZCO
 */

import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import { InputProperty } from "../interfaces/GeneralInterface";
import "./SLInput.scss";
import ICInfo from "../assets/img/ICInfo.svg";
type IntInput = InputProperty;

const SLInput = (props: IntInput) => {
  const errorClass: string =
    props.errorClass || props.errorMessage ? "form-error" : "";

  const handleLengthNote = () => {
    const currentLength = props.length;
    return `${currentLength}/${props.maxLength}`;
  };
  const isShowLengthCount = props?.length !== undefined;

  const popover = (
    <Popover id="popover-info">
      <Popover.Body>{props.toolTipMessage}</Popover.Body>
    </Popover>
  );

  return (
    <>
      <Form.Group className="sl-input position-relative">
        {props.label && (
          <Form.Label>
            {props.label}
            {props.toolTipMessage && (
              <OverlayTrigger
                trigger={["hover", "hover"]}
                placement="top"
                overlay={popover}
              >
                <img src={ICInfo} alt="" />
              </OverlayTrigger>
            )}
          </Form.Label>
        )}
        <Form.Control
          id={props.id}
          name={props?.name}
          type={props.type}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          readOnly={props.readOnly}
          value={props.value}
          required={props.required}
          pattern={props.pattern}
          isInvalid={props.isInvalid}
          className={errorClass}
          disabled={props.disabled}
          as={props.as}
          rows={props.rows}
          maxLength={props.maxLength}
          autoComplete={props?.autoComplete}
        />
        <div className="d-flex">
          {props.errorMessage && (
            <div className="error-msg">{props.errorMessage}</div>
          )}
          {isShowLengthCount && (
            <div className="note-length">{handleLengthNote()}</div>
          )}
        </div>
      </Form.Group>
    </>
  );
};

export default SLInput;
