/**
 * @file   src\containers\EmergencyCheckInDetails.tsx
 * @brief  EmergencyCheckInDetails page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import ICBack from "../assets/img/icons/Back";
import { Badge, Accordion, Col, Row, Table, Button } from "react-bootstrap";
import Search from "../components/common/Search";
import TableHeader from "../components/common/TableHeader";
import ICTableExpand from "../assets/img/icons/TableExpand";
import { useEffect, useState } from "react";
import AddEditNote from "../components/common/AddEditNote";
import { ResponseObjects } from "../interfaces/AxiosResponse";
import {
  getEmergencyChkInDetails,
  sentNotification,
  updateEmergencyNotes,
} from "../services/emergencyCheckInService";
import { useAppSelector, useAppDispatch } from "../Redux/hooks";
import EmergencyViewDetailModal from "../components/EmergencyViewDetailModal";
import {
  DATE_FORMAT_WITH_TIME,
  DATE_FORMAT_WITH_TIME_FULL_YEAR,
  DATE_FORMAT_WITH_TIME_PREFIX,
  HTTP_RESPONSE_STATUS_200,
  ONE,
  PAGE_SIZE_20,
  SESSION_ACTIVE,
  ZERO,
  csvDownload,
  tableHeaders,
} from "../constants/common";
import {
  convertToNumber,
  dateConversion,
  //dateConversion,
  dateFormat,
  downloadCSVForChkInDetails,
} from "../helpers/common";
import {
  fetchChkInDetails,
  fetchEmergencySessionList,
} from "../Redux/actions/emergencyCheckIn";
import { useNavigate, useParams } from "react-router-dom";
import Paginate from "../components/common/Paginate";
import Strings from "../assets/strings/Strings.json";
import { pageURLs } from "../constants/pageURLs";
import {
  EmergencySessionList,
  RecordCounts,
  ViewDetails,
} from "../interfaces/Emergency";
import { toast } from "react-toastify";
import NotificationConfModal from "../components/common/DeleteConfModal";
import { phoneFormat } from "../helpers/validations";

const EmergencyCheckInDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { ID } = useParams();
  const active_session_title = "ACTIVE SESSION";
  const needAssistanceTitle = "Need Help";
  const noResponceTitle = "No Response";
  const safeTitle = "Safe";
  const needHelp_Class = "unresponsive";
  const safe_Class = "safe";
  const unResponsive_Class = "needassistance";

  //Table column headers
  const columns = [
    { title: tableHeaders.common.timestamp, isSort: false },
    { title: tableHeaders.common.user, isSort: false },
    { title: tableHeaders.emergency.location, isSort: false },
    { title: tableHeaders.common.notes, isSort: false },
    { title: tableHeaders.emergency.viewDetails, isSort: false },
    { title: tableHeaders.common.toggle, isSort: false },
  ];

  //Accordion each tab values
  const tabList = {
    markedSafe: "0",
    needAssistance: "1",
    noResponce: "2",
  };

  // Initial page records count for each tab
  const initialRecordCounts: RecordCounts = {
    totalRecords: ZERO,
    safeRecords: ZERO,
    assistanceRecords: ZERO,
    noResponseRecords: ZERO,
  };

  // Initialisation of various state variables
  const [page, setPage] = useState<number>(ONE);
  const [isLoad, setLoad] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(PAGE_SIZE_20);
  const [recordCounts, setTotalRecord] =
    useState<RecordCounts>(initialRecordCounts);
  const [searchText, setSearchText] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>(tabList.needAssistance);
  const [sessionList, setSessionsList] = useState<any>(null);
  const [showDetailsModal, setDetailsModal] = useState<boolean>(false);
  const [chkInDetails, setChkInDetails] = useState<ViewDetails>(null);
  const [showNotificationConfModal, setNotiFyConfModal] =
    useState<boolean>(false);

  //Emergency details from redux store
  const emergencyChInDetails = useAppSelector(
    (state: any) => state.emergency?.emergencyDetail?.list
  );

  //Emergency sessions list from redux store
  const emergencySessionList = useAppSelector(
    (state: any) => state.emergency?.emergencySession?.list
  );

  //Emergency sessions list loading flag from redux store
  const emergencySessionListIsLoading = useAppSelector(
    (state: any) => state.emergency?.emergencySession?.loading
  );

  // Handle accordion tab collapse
  const handleAccordionClick = (eventKey: any) => {
    setPage(ONE);
    setActiveTab(eventKey);
    setSessionsList(null);
  };

  // page change and state change
  const pageChange = (page: number) => {
    if (page) {
      setPage(page);
    }
  };

  // page size change and state change
  const perPageChange = (page: number) => {
    if (page) {
      setPage(ONE);
      setPerPage(page);
    }
  };

  // Details Modal show/hide
  const handleDetailsModal = (modalState: boolean) => {
    setDetailsModal(modalState);
  };

  // Set checkinDetails into stated
  const updateDetails = (issues: string, addDetails: string) => {
    setChkInDetails((details) => ({
      ...details,
      issues: issues,
      additionalDetails: addDetails,
    }));
  };

  // Fetch the emergency session list from redux store and set into the local state
  useEffect(() => {
    loadEmergencySessionList();
  }, [emergencySessionList]);

  // Handle to calling the emergency session list API
  useEffect(() => {
    if (activeTab) {
      getEmergencySessionList();
    }
  }, [page, perPage, activeTab]);

  //Dispatch emergency session list redux action for invoking the session list api
  const getEmergencySessionList = () => {
    dispatch(
      fetchEmergencySessionList({
        id: ID,
        pageNo: page,
        pageSize: perPage,
        responseStatus: convertToNumber(activeTab),
        searchText: searchText,
      })
    );
  };

  // Handle to calling the emergency details  API
  useEffect(() => {
    dispatch(
      fetchChkInDetails({
        id: ID,
        pageNo: page,
        pageSize: perPage,
      })
    );
  }, []);

  // Handle setting the response from the emergency session list API call into state.
  const loadEmergencySessionList = () => {
    const list = emergencySessionList?.list;
    if (list) {
      setSessionsList(list);
      setTotalRecord((cnt) => ({
        ...cnt,
        totalRecords: emergencySessionList?.totalRecords,
        safeRecords: emergencySessionList?.safeRecords,
        assistanceRecords: emergencySessionList?.assistanceRecords,
        noResponseRecords: emergencySessionList?.noResponseRecords,
      }));
    }
  };

  // Handle toggle change to expand the note.
  const handleToggleChange = (id: number, notes: string) => {
    if (notes) {
      const updatedArray = sessionList.map((obj: any) => {
        if (obj.sessionId === id) {
          return { ...obj, toggle: !obj.toggle };
        }
        return obj;
      });
      setSessionsList(updatedArray);
    }
  };
  // Handle updating the note state and calling the update emergency notes API.
  const updateNote = async (id: number, value: number | string) => {
    const payload = {
      sessionId: id,
      notes: value,
    };
    const response: ResponseObjects = await updateEmergencyNotes(payload);
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      const copyList = [...sessionList];
      const newState = copyList.map((obj) => {
        if (obj.sessionId === id) {
          return { ...obj, notes: value, toggleDisabled: false };
        }
        return obj;
      });
      setSessionsList(newState);
    }
    return response?.status;
  };

  // Handle calling emergency session list API with a search payload
  const onSearchClick = (payload: EmergencySessionList) => {
    const updatedPayload = payload;
    updatedPayload.id = ID;
    updatedPayload.responseStatus = convertToNumber(activeTab);
    dispatch(fetchEmergencySessionList(updatedPayload));
  };

  // Generate Html for each tab view
  const loadSessionList = () => {
    let className = "";

    if (activeTab === tabList.markedSafe) {
      className = safe_Class;
    } else if (activeTab === tabList.noResponce) {
      className = unResponsive_Class;
    } else if (activeTab === tabList.needAssistance) {
      className = needHelp_Class;
    }

    if (
      !emergencySessionListIsLoading &&
      sessionList &&
      sessionList?.length === ZERO
    ) {
      return (
        <Col className="text-center">{Strings.EmergencyCheckIn.noRecords}</Col>
      );
    }

    if (emergencySessionListIsLoading) {
      return (
        <Col className="text-center mt-5">
          <output className="spinner-border"></output>
        </Col>
      );
    }
    if (
      !emergencySessionListIsLoading &&
      sessionList &&
      sessionList?.length > ZERO
    ) {
      return (
        <Table responsive>
          <TableHeader
            columns={
              activeTab === tabList.needAssistance
                ? columns
                : columns?.filter(function (cl: any) {
                    return (
                      cl.title !== tableHeaders.emergency.location &&
                      cl.title !== tableHeaders.emergency.viewDetails
                    ); // Exclude location and view details column from array if the ative tab is not need help
                  })
            }
          />
          <tbody>
            {sessionList &&
              sessionList?.length > ZERO &&
              sessionList?.map((items: any) => (
                <tr key={items.sessionId}>
                  <td className={className}>
                    {" "}
                    {dateFormat(
                      items?.response === null || items?.response === ""
                        ? items?.createdOn
                        : items?.lastUpdatedTS,
                      DATE_FORMAT_WITH_TIME_FULL_YEAR
                    )}
                  </td>
                  <td
                    className={className}
                  >{`${items?.firstName} ${items?.lastName}`}</td>
                  {activeTab === tabList.needAssistance && (
                    <td className={className}>{items?.location}</td>
                  )}

                  <AddEditNote
                    items={items}
                    id={items?.sessionId}
                    handleSave={updateNote}
                  />
                  {activeTab === tabList.needAssistance && (
                    <td>
                      <Button
                        variant="primary"
                        onClick={() => {
                          handleDetailsModal(true);
                          updateDetails(items?.issues, items?.add_Info);
                        }}
                      >
                        {Strings.EmergencyCheckIn.viewDetalsBtn}
                      </Button>
                    </td>
                  )}
                  <td
                    className="toggle text-center"
                    onClick={() => {
                      handleToggleChange(items.sessionId, items?.notes);
                    }}
                  >
                    <span
                      className={
                        items?.notes === null
                          ? "disabled"
                          : !items.toggle
                            ? "tableExpand"
                            : "tableCollapse"
                      }
                    >
                      <ICTableExpand />
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      );
    }
  };

  // Handle push notification confirmation modal
  const handleNotificationConfModal = (state: boolean) => {
    setNotiFyConfModal(state);
  };

  // Handle push notification link click.
  const handlePushNotificationClick = async () => {
    handleNotificationConfModal(true);
  };

  // Handle push notification response to unresponsiv users.
  const NotificationConfirmation = async () => {
    const response = await sentNotification({ checkinId: convertToNumber(ID) });
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      handleNotificationConfModal(false);
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
  };

  // Handle CSV download.
  const handleCSVDownload = async () => {
    setLoad(true);
    const response = await getEmergencyChkInDetails({ id: ID });
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      const detailsData: any = {
        ...response?.data?.checkin,
        assistanceRecords: response?.data?.assistanceRecords,
        safeRecords: response?.data?.safeRecords,
        noResponseRecords: response?.data?.noResponseRecords,
      };
      const columnHeader = [
        {
          [tableHeaders.common.timestamp]: tableHeaders.common.timestamp,
          [tableHeaders.common.status]: tableHeaders.common.status,
          [tableHeaders.common.name]: tableHeaders.common.name,
          [tableHeaders.users.email]: tableHeaders.users.email,
          [tableHeaders.users.phone]: tableHeaders.users.phone,
          [tableHeaders.common.extension]: tableHeaders.common.extension,
          [tableHeaders.users.department]: tableHeaders.users.department,
          [tableHeaders.users.building]: tableHeaders.users.building,
          "User Type": "User Type",
          [tableHeaders.common.safetyTeamNotes]:
            tableHeaders.common.safetyTeamNotes,
        },
      ];

      const sessionList: any = [];

      if (response?.data?.sessions?.length > ZERO) {
        response?.data?.sessions?.map((data: any) => {
          sessionList.push({
            [tableHeaders.common.timestamp]: dateConversion(
              data?.response === null || data?.response === ""
                ? data?.createdOn
                : data?.lastUpdatedTS,
              DATE_FORMAT_WITH_TIME_PREFIX
            ),

            [tableHeaders.common.status]:
              data?.response === ZERO
                ? "Safe"
                : data?.response === ONE
                  ? "Need Help"
                  : "No response",
            [tableHeaders.common.name]: `${data?.firstName} ${data?.lastName}`,
            [tableHeaders.users.email]: data?.email,
            [tableHeaders.users.phone]: data?.phone
              ? phoneFormat(data?.phone)
              : "",
            [tableHeaders.common.extension]: data?.extension,
            [tableHeaders.users.department]: data?.department,
            [tableHeaders.users.building]: data?.building,
            "User Type": data?.isAdmin === ONE ? "Admin" : "General",

            [tableHeaders.common.safetyTeamNotes]: data?.notes,
          });
        });
      }

      downloadCSVForChkInDetails(
        detailsData,
        sessionList?.length > ZERO ? [...columnHeader, ...sessionList] : [],
        csvDownload.emergencyChkInDetails
      );
    }
    setLoad(false);
  };

  return (
    <>
      <Row className="tilte-wrapper">
        <Col lg="12">
          <div
            className="back"
            onClick={() => {
              navigate(pageURLs.emergencyCheckIn);
            }}
          >
            <ICBack />
          </div>
          <h1 className="d-flex align-items-center">
            {dateFormat(emergencyChInDetails?.createdOn, DATE_FORMAT_WITH_TIME)}
            {emergencyChInDetails?.isActive === SESSION_ACTIVE && (
              <>
                <Badge bg="secondary" className="emergency">
                  {active_session_title}
                </Badge>
                <span
                  className="end-session"
                  style={{ textDecoration: "none" }}
                >
                  <a
                    onClick={() => handlePushNotificationClick()}
                    href={void 0}
                    role="button"
                  >
                    {recordCounts?.noResponseRecords > 0 &&
                      Strings.EmergencyCheckIn.sentNotificationBtnTitle}
                  </a>
                </span>
              </>
            )}
          </h1>
        </Col>
      </Row>
      <Row className="action-wrapper">
        <Col md="6" xl="4">
          <Search
            onClick={onSearchClick}
            pageSize={perPage}
            setPage={setPage}
            page={page}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </Col>
        <Col md="6" xl="8" className="text-end">
          <Button
            disabled={!emergencyChInDetails}
            onClick={() => handleCSVDownload()}
          >
            {isLoad ? (
              <output className="spinner-border sm"></output>
            ) : (
              Strings.common.dowloadCSV
            )}
          </Button>
        </Col>
      </Row>
      <Accordion
        defaultActiveKey={tabList.needAssistance}
        onSelect={handleAccordionClick}
      >
        <Accordion.Item eventKey={tabList.needAssistance}>
          <Accordion.Header className="need-assistance">
            {needAssistanceTitle}{" "}
            <Badge>{recordCounts?.assistanceRecords}</Badge>
          </Accordion.Header>
          <Accordion.Body className="instructions">
            {loadSessionList()}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey={tabList.noResponce}>
          <Accordion.Header>
            {noResponceTitle} <Badge>{recordCounts?.noResponseRecords}</Badge>
          </Accordion.Header>
          <Accordion.Body className="instructions">
            {loadSessionList()}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey={tabList.markedSafe}>
          <Accordion.Header className="safe">
            {safeTitle} <Badge>{recordCounts?.safeRecords}</Badge>
          </Accordion.Header>
          <Accordion.Body className="instructions">
            {loadSessionList()}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {activeTab && recordCounts?.totalRecords !== ZERO && (
        <Paginate
          totalRecords={recordCounts?.totalRecords}
          currentPage={page}
          perPage={perPage}
          onClick={pageChange}
          onShowClick={perPageChange}
        />
      )}
      <EmergencyViewDetailModal
        issues={chkInDetails?.issues}
        additionalDetails={chkInDetails?.additionalDetails}
        show={showDetailsModal}
        onHide={handleDetailsModal}
      />
      <NotificationConfModal
        onHide={handleNotificationConfModal}
        show={showNotificationConfModal}
        title=""
        desc={Strings.EmergencyCheckIn.sentNotificationConfInfo}
        handleDelete={NotificationConfirmation}
      />
    </>
  );
};

export default EmergencyCheckInDetails;
