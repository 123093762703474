/**
 * @file   src\containers\MyAccount.tsx
 * @brief  MyAccount page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import SLInput from "../components/SLInput";
import SLInputIcon from "../components/SLInputIcon";
import Strings from "../assets/strings/Strings.json";
import { Button, Col, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import {
  passwordValidation,
  phoneNumberValidation,
  phoneFormat,
  nameValidation,
  numberSpecialCharValidation,
  numberValidation,
  numberOnly,
} from "../helpers/validations";
import {
  changePassword,
  userProfileUpdate,
} from "../services/myAccountService";
import {
  HTTP_RESPONSE_STATUS_200,
  USER_INFO_KEY,
  PASSWORD,
  NEW_PASSWORD,
  CONFIRM_PASSWORD,
  CURRENT_PASSWORD,
  PHONE,
  MAX_LENGTH_20,
  EMPTY_STRING,
  MAX_LENGTH_12,
  MAX_LENGTH_45,
  MAX_LENGTH_255,
  EXTENSION,
  MAX_LENGTH_3,
} from "../constants/common";
import { getLocalStorage, setLocalStorage } from "../helpers/common";
import { toast } from "react-toastify";
import {
  ENTER_VALID_PHONE,
  PASSWORD_MISMATCH,
  ENTER_CONFIRM_PASSWORD,
  ENTER_CURRENT_PASSWORD,
  ENTER_NEW_PASSWORD,
  ENTER_FIRST_NAME,
  ENTER_LAST_NAME,
  ENTER_PHONE,
} from "../constants/validationMessages";
import {
  PasswordForm,
  MyInformForm,
  PasswordError,
  MyInformFormError,
  ProfilePayload,
} from "../interfaces/MyAccount";

import { ResponseObjects } from "../interfaces/AxiosResponse";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../constants/pageURLs";
import { useAppDispatch, useAppSelector } from "../Redux/hooks";
import { fetchMyProfile } from "../Redux/actions/myAccount";

const MyAccount = () => {
  const STRING_NULL = "null";
  const navigate = useNavigate();
  const myProfile = useAppSelector(
    (state: any) => state?.myAccount?.userProfile?.data
  );

  const dispatch = useAppDispatch();

  //Initialise change password form values
  const intialPasswordValue = {
    currentPassword: EMPTY_STRING,
    newPassword: EMPTY_STRING,
    confirmPassword: EMPTY_STRING,
  };

  //Initialise change password form errors
  const intialPasswordError = {
    currentPasswordError: EMPTY_STRING,
    newPasswordError: EMPTY_STRING,
    confirmPasswordError: EMPTY_STRING,
  };
  //Initialise account details change form values
  const intialInformValue = {
    firstName: EMPTY_STRING,
    lastName: EMPTY_STRING,
    phone: EMPTY_STRING,
    email: EMPTY_STRING,
    title: EMPTY_STRING,
    extension: EMPTY_STRING,
  };
  //Initialise account details change form errors
  const intialInformError = {
    firstNameError: EMPTY_STRING,
    lastNameError: EMPTY_STRING,
    phoneError: EMPTY_STRING,
    emailError: EMPTY_STRING,
    titleError: EMPTY_STRING,
  };

  //Initialise page state variables
  const [passWordType, setPassWordType] = React.useState<string>("text");

  const [passwordData, setPasswordData] =
    React.useState<PasswordForm>(intialPasswordValue);
  const [passwordError, setPasswordError] =
    React.useState<PasswordError>(intialPasswordError);
  const [myInformData, setMyInformData] =
    React.useState<MyInformForm>(intialInformValue);
  const [informError, setInformError] =
    React.useState<MyInformFormError>(intialInformError);
  const [formatedPhone, setFormatedPhone] = React.useState<string>("");
  const [showCurrentPassword, setShowCurrentPassword] =
    React.useState<string>(STRING_NULL);
  const [showConfirmPassword, setShowConfirmPassword] =
    React.useState<string>(STRING_NULL);

  // Handle to dispatch action for the profile API.
  useEffect(() => {
    const userInfoFromLS = getLocalStorage(USER_INFO_KEY);
    if (!userInfoFromLS?.isSuperAdminLogin) {
      dispatch(fetchMyProfile());
    } else {
      navigate(pageURLs.dashboard); // To redirecting to dashboard if login from super admin site.
    }
  }, []);

  // Handle to format the phone number.
  useEffect(() => {
    const phone = phoneFormat(myInformData.phone);
    setFormatedPhone(phone);
  }, [myInformData.phone]);

  // Handle response from the user profile  api call
  useEffect(() => {
    loadProfileData();
  }, [myProfile]);

  // Handle setting the response from the user profile  API call into state.
  const loadProfileData = async () => {
    setMyInformData((myInformData) => ({
      ...myInformData,
      firstName: myProfile?.firstName,
      lastName: myProfile?.lastName,
      email: myProfile?.email,
      phone: myProfile?.phone,
      title: myProfile?.user_title,
      extension: myProfile?.extension,
    }));
  };

  // Handle onchange password text change to state.
  const onTextPasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const currentId = e.currentTarget.id;
    setPasswordData((form) => ({
      ...form,
      [currentId]: value,
    }));
    if (value) {
      if (currentId === CURRENT_PASSWORD) {
        if (showCurrentPassword === STRING_NULL) {
          setShowCurrentPassword(PASSWORD);
        }
      }
      if (currentId === CONFIRM_PASSWORD) {
        if (showConfirmPassword === STRING_NULL) {
          setShowConfirmPassword(PASSWORD);
        }
      }
      if (currentId === NEW_PASSWORD) {
        setPassWordType(PASSWORD);
      }
    }
  };

  // Handle onchange inform text change to state.
  const onTextInformChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const currentId = e.currentTarget.id;
    if (currentId === PHONE) {
      if (numberSpecialCharValidation.test(value)) {
        const numbers = value.match(/\d+/g);
        const fullNumber = numbers.join("");
        setMyInformData((myInformData) => ({
          ...myInformData,
          [currentId]: fullNumber,
        }));
      } else if (!numberValidation.test(value)) {
        setMyInformData((myInformData) => ({
          ...myInformData,
          [currentId]: value,
        }));
      }
    } else if (currentId === EXTENSION) {
      if (value) {
        if (numberOnly.test(value)) {
          setMyInformData((myInformData) => ({
            ...myInformData,
            [currentId]: value,
          }));
        }
      } else {
        setMyInformData((myInformData) => ({
          ...myInformData,
          [currentId]: "",
        }));
      }
    } else {
      setMyInformData((myInformData) => ({
        ...myInformData,
        [currentId]: value,
      }));
    }
  };

  // Handle error validation of my inform data.
  const validMyInfo = () => {
    setInformError(intialInformError);
    let isValid = true;
    const firstName = myInformData?.firstName?.trim();
    const lastName = myInformData?.lastName?.trim();
    const phone = myInformData?.phone?.trim();
    if (firstName) {
      const nameValid = nameValidation(firstName);
      if (nameValid !== "") {
        setInformError((informError) => ({
          ...informError,
          firstNameError: nameValid,
        }));
        isValid = false;
      }
    }
    if (lastName) {
      const nameValid = nameValidation(lastName);
      if (nameValid !== "") {
        setInformError((informError) => ({
          ...informError,
          lastNameError: nameValid,
        }));
        isValid = false;
      }
    }
    if (phone && formatedPhone) {
      const phoneValidation = phoneNumberValidation(formatedPhone);
      if (!phoneValidation) {
        setInformError((informError) => ({
          ...informError,
          phoneError: ENTER_VALID_PHONE,
        }));
        isValid = false;
      }
    }
    if (!firstName && !lastName && !phone) {
      setInformError((informError) => ({
        ...informError,
        firstNameError: ENTER_FIRST_NAME,
        lastNameError: ENTER_LAST_NAME,
        phoneError: ENTER_PHONE,
      }));
      isValid = false;
    } else if (!firstName) {
      setInformError((informError) => ({
        ...informError,
        firstNameError: ENTER_FIRST_NAME,
      }));
      isValid = false;
    }
    if (!lastName) {
      setInformError((informError) => ({
        ...informError,
        lastNameError: ENTER_LAST_NAME,
      }));
      isValid = false;
    }
    if (!phone) {
      setInformError((informError) => ({
        ...informError,
        phoneError: ENTER_PHONE,
      }));
      isValid = false;
    }

    return isValid;
  };

  // Handle error validation of password data.
  const validPassword = () => {
    setPasswordError(intialPasswordError);
    let isValid = true;
    const currentPwd = passwordData?.currentPassword?.trim();
    const newPwd = passwordData?.newPassword?.trim();
    const confirmPwd = passwordData?.confirmPassword?.trim();
    if (newPwd && confirmPwd) {
      if (newPwd !== confirmPwd) {
        setPasswordError((passwordError) => ({
          ...passwordError,
          confirmPasswordError: PASSWORD_MISMATCH,
        }));
        isValid = false;
      }
    }
    if (newPwd) {
      const passwordValidate = passwordValidation(newPwd);
      if (passwordValidate !== "") {
        setPasswordError((passwordError) => ({
          ...passwordError,
          newPasswordError: passwordValidate,
        }));
        isValid = false;
      }
    }
    if (!currentPwd && !newPwd && !confirmPwd) {
      setPasswordError((passwordError) => ({
        ...passwordError,
        currentPasswordError: ENTER_CURRENT_PASSWORD,
        newPasswordError: ENTER_NEW_PASSWORD,
        confirmPasswordError: ENTER_CONFIRM_PASSWORD,
      }));
      isValid = false;
    } else if (!currentPwd) {
      setPasswordError((passwordError) => ({
        ...passwordError,
        currentPasswordError: ENTER_CURRENT_PASSWORD,
      }));
      isValid = false;
    } else if (!newPwd) {
      setPasswordError((passwordError) => ({
        ...passwordError,
        newPasswordError: ENTER_NEW_PASSWORD,
      }));
      isValid = false;
    } else if (!confirmPwd) {
      setPasswordError((passwordError) => ({
        ...passwordError,
        confirmPasswordError: ENTER_CONFIRM_PASSWORD,
      }));
      isValid = false;
    }
    return isValid;
  };

  // Handle user profile update api call.
  const handleMyInformUpdate = async () => {
    const isValid = validMyInfo();
    if (isValid) {
      const payload: ProfilePayload = {
        firstName: myInformData?.firstName,
        lastName: myInformData?.lastName,
        phone: myInformData?.phone,
        user_title: myInformData?.title,
        extension: myInformData?.extension,
      };
      const response: ResponseObjects = await userProfileUpdate(payload);
      if (response?.status === HTTP_RESPONSE_STATUS_200) {
        const userDetailsFromStore = getLocalStorage(USER_INFO_KEY);
        setLocalStorage(USER_INFO_KEY, {
          accessToken: userDetailsFromStore?.accessToken,
          refreshToken: userDetailsFromStore?.refreshToken,
          firstName: myInformData?.firstName,
          lastName: myInformData?.lastName,
          userId: userDetailsFromStore?.userId,
          orgId: userDetailsFromStore?.orgId,
        });
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    }
  };

  // Handle change password api call.
  const handlePasswordSubmit = async () => {
    const isValid = validPassword();
    if (isValid) {
      const payload: PasswordForm = {
        currentPassword: passwordData.currentPassword,
        newPassword: passwordData.newPassword,
      };
      const response: ResponseObjects = await changePassword(payload);
      if (response?.status === HTTP_RESPONSE_STATUS_200) {
        setPasswordData(intialPasswordValue);
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    }
  };

  //Handle Password masking(eye icon)in current password text field while clicking the icon
  const onCurrentPassTypeClick = () => {
    setShowCurrentPassword(showCurrentPassword === "text" ? PASSWORD : "text");
  };

  //Handle  password masking(eye icon)in confirm password text field while clicking the icon
  const onConfirmTypeClick = () => {
    setShowConfirmPassword(showConfirmPassword === "text" ? PASSWORD : "text");
  };

  return (
    <>
      <Row className="tilte-wrapper">
        <Col lg="12">
          <h1>
            <span className="text-uppercase">
              {Strings.Common.InnerSubTitle}
            </span>
            <br />
            {Strings.MyAccount.Title}
          </h1>
        </Col>
      </Row>
      <h2>{Strings.MyAccount.SubHead1}</h2>

      <div className="content-wrapper">
        <Row>
          <Col md="6" xl="4">
            <SLInput
              name="firstName"
              id="firstName"
              type="text"
              value={myInformData.firstName}
              onChange={onTextInformChange}
              errorMessage={informError.firstNameError}
              label={Strings.MyAccount.fName}
              maxLength={MAX_LENGTH_45}
            />
          </Col>
          <Col md="6" xl="4">
            <SLInput
              name="lastName"
              id="lastName"
              type="text"
              value={myInformData.lastName}
              onChange={onTextInformChange}
              errorMessage={informError.lastNameError}
              label={Strings.MyAccount.lName}
              maxLength={MAX_LENGTH_45}
            />
          </Col>
        </Row>
        <Row>
          <Col md="4" xl="2">
            <SLInput
              name="phone"
              id="phone"
              type="tel"
              value={formatedPhone || ""}
              maxLength={MAX_LENGTH_12}
              onChange={onTextInformChange}
              errorMessage={informError.phoneError}
              label={Strings.MyAccount.phone}
              toolTipMessage={Strings.phoneInfo}
            />
          </Col>
          <Col md="2">
            <SLInput
              label={Strings.MyAccount.Extension}
              id={EXTENSION}
              type="tel"
              maxLength={MAX_LENGTH_3}
              onChange={onTextInformChange}
              value={myInformData?.extension || ""}
            />
          </Col>
          <Col md="6" xl="4">
            <SLInput
              name="email"
              id="email"
              type="text"
              disabled={true}
              value={myInformData.email}
              label={Strings.MyAccount.email}
              maxLength={MAX_LENGTH_45}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12" xl="8">
            <SLInput
              name="title"
              id="title"
              type="text"
              value={myInformData.title}
              onChange={onTextInformChange}
              errorMessage={informError.titleError}
              label={Strings.MyAccount.title}
              maxLength={MAX_LENGTH_255}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12" xl="8" className="text-end btn-wrap">
            <Button onClick={() => handleMyInformUpdate()}>
              {Strings.MyAccount.updateProfile}
            </Button>
          </Col>
        </Row>
      </div>
      <h2>{Strings.MyAccount.SubHead2}</h2>
      <div className="content-wrapper">
        <Row>
          <Col md="6" xl="4">
            <SLInputIcon
              name="currentPassword"
              id="currentPassword"
              type={showCurrentPassword}
              onChange={onTextPasswordChange}
              value={passwordData.currentPassword}
              errorMessage={passwordError.currentPasswordError}
              label={Strings.MyAccount.currPassword}
              onClick={onCurrentPassTypeClick}
              maxLength={MAX_LENGTH_20}
            />
            <SLInput
              name="newPassword"
              id="newPassword"
              type={passWordType}
              onChange={onTextPasswordChange}
              value={passwordData.newPassword}
              errorMessage={passwordError.newPasswordError}
              label={Strings.MyAccount.newPassword}
              maxLength={MAX_LENGTH_20}
            />
            <SLInputIcon
              name="confirmPassword"
              id="confirmPassword"
              type={showConfirmPassword}
              onChange={onTextPasswordChange}
              value={passwordData.confirmPassword}
              errorMessage={passwordError.confirmPasswordError}
              label={Strings.MyAccount.confNewPassword}
              onClick={onConfirmTypeClick}
              maxLength={MAX_LENGTH_20}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6" xl="4" className="text-end btn-wrap">
            <Button onClick={handlePasswordSubmit}>
              {Strings.MyAccount.updatePwd}
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MyAccount;
