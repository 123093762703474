/**
 * @file   src\containers\ImportantLinks.tsx
 * @brief  ImportantLinks page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useEffect, useState } from "react";
import Strings from "../assets/strings/Strings.json";
import { Button, Col, Row } from "react-bootstrap";
import SLInput from "../components/SLInput";
import SortableLinks from "../components/SortableLinks";
import {
  addImportantLinks,
  deleteImpLink,
  updateImpLink,
  updateLinkOrder,
} from "../services/importantLinksService";
import {
  HTTP_RESPONSE_STATUS_200,
  MAX_LENGTH_2000,
  EDIT,
  MAX_LENGTH_200,
  MAX_LENGTH_500,
  ONE,
  ZERO,
  HEIGHT_400,
} from "../constants/common";
import DeleteConfModal from "../components/common/DeleteConfModal";
import { toast } from "react-toastify";
import {
  ENTER_URL,
  ENTER_VALID_URL,
  ENTER_LINK,
  ENTER_DESCRIPTION,
} from "../constants/validationMessages";
import {
  ImportantForm,
  ImportantFormError,
  SaveLinkPayload,
  linkAction,
  UpdateOrderPayload,
} from "../interfaces/ImportantLinks";
import { fetchImportantLinksListing } from "../Redux/actions/importantLinks";
import { useAppSelector, useAppDispatch } from "../Redux/hooks";
import { ResponseObjects } from "../interfaces/AxiosResponse";

const ImportantLinks = () => {
  const intialImportantLinkValue = {
    linkText: "",
    url: "",
    description: "",
  };
  const intialImportantLinkError = {
    linkerror: "",
    urlError: "",
    descriptionError: "",
  };

  const [show, setShow] = useState<boolean>(false);
  const [saveClicked, setSaveClicked] = useState<boolean>(false);
  const [importantLinks, setImpLinks] = useState<string[]>([]);
  const [linkId, setLinkId] = useState<number>(null);
  const [importantLinkData, setImportantLinkData] =
    React.useState<ImportantForm>(intialImportantLinkValue);
  const [importantLinkError, setImportantLinkError] =
    React.useState<ImportantFormError>(intialImportantLinkError);
  const [isDisable, setBtnDisable] = useState<boolean>(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const importantLinksLists = useAppSelector(
    (state: any) => state.importantLinks?.list
  );
  const dispatch = useAppDispatch();

  // Handle to calling the Important-links list API.
  useEffect(() => {
    dispatch(fetchImportantLinksListing());
  }, []);

  // Handle response from the Important-links list  api call
  useEffect(() => {
    loadImportantLinks();
  }, [importantLinksLists]);

  // Handle setting the response from the Important-links list API call into state.
  const loadImportantLinks = async () => {
    const list = importantLinksLists?.data;
    if (list) {
      const updatedList = [...list];
      setImpLinks(updatedList);
    }
  };

  // Function to check Valid Http URL.
  function isValidHttpUrl(string: string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  // Handle onChange text change
  const onTextImportantChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const currentId = e.currentTarget.id;
    setImportantLinkData((form) => ({
      ...form,
      [currentId]: value,
    }));
  };

  // Error validation of important-link data
  const validLinks = () => {
    setImportantLinkError(intialImportantLinkError);
    let isValid = true;
    const link = importantLinkData?.linkText?.trim();
    const url = importantLinkData?.url?.trim();
    const description = importantLinkData?.description?.trim();
    const validUrl = isValidHttpUrl(url);

    if (validUrl === false) {
      setImportantLinkError((importantLinkError) => ({
        ...importantLinkError,
        urlError: ENTER_VALID_URL,
      }));
      isValid = false;
    }
    if (!link) {
      setImportantLinkError((importantLinkError) => ({
        ...importantLinkError,
        linkerror: ENTER_LINK,
      }));
      isValid = false;
    }
    if (!url) {
      setImportantLinkError((importantLinkError) => ({
        ...importantLinkError,
        urlError: ENTER_URL,
      }));
      isValid = false;
    }
    if (!description) {
      setImportantLinkError((importantLinkError) => ({
        ...importantLinkError,
        descriptionError: ENTER_DESCRIPTION,
      }));
      isValid = false;
    }
    return isValid;
  };

  // Handle the Save functionality and call the Important-links add or update API.
  const handleSaveLink = async () => {
    setSaveClicked(true);
    const isValid = validLinks();
    if (isValid) {
      const payload: SaveLinkPayload = {
        displayText: importantLinkData?.linkText,
        url: importantLinkData?.url,
        linkDesc: importantLinkData?.description,
        linkId: null,
      };
      if (linkId) {
        payload.linkId = linkId;
        const response: ResponseObjects = await updateImpLink(payload);
        formReset(response);
      } else {
        const response: ResponseObjects = await addImportantLinks(payload);
        formReset(response);
      }
    } else setSaveClicked(false);
  };

  // Reset the states to intial values.
  const formReset = (response: ResponseObjects) => {
    setSaveClicked(false);
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      toast.success(response?.message);
      setLinkId(null);
      dispatch(fetchImportantLinksListing());
      clearForm();
    } else {
      toast.error(response?.urlError?.isUrl);
    }
  };

  // Load the corresponding link Data to the state and textbox.
  const linkAction = (lnkDetails: linkAction, action?: string) => {
    setLinkId(lnkDetails?.id);
    if (action === EDIT) {
      setImportantLinkData((form) => ({
        ...form,
        linkText: lnkDetails?.displayText,
        url: lnkDetails?.url,
        description: lnkDetails?.linkDesc,
      }));

      window.scrollTo(ZERO, ZERO);
    } else {
      setBtnDisable(false);
      handleShow();
    }
  };

  // Reset the states to intial values
  const clearForm = () => {
    setImportantLinkData(intialImportantLinkValue);
  };

  // Build the payload for the update order API.
  const buildPayload = (updatedList: any[]) => {
    const updatedPayload = updatedList?.map((lnk: UpdateOrderPayload) => {
      return {
        linkId: lnk?.id,
        orderNo: lnk?.orderNo,
      };
    });
    return { links: updatedPayload };
  };

  // Update the order of the important-links list and call the update-Link order API.
  const updateOrder = async (updatedList: any[]) => {
    const updatedLinks = buildPayload(updatedList);
    const response: ResponseObjects = await updateLinkOrder(updatedLinks);
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
  };

  // Handle the Delete important link API Call.
  const deleteLink = async () => {
    if (linkId) {
      setBtnDisable(true);
      const response: ResponseObjects = await deleteImpLink({ linkId: linkId });
      if (response?.status === HTTP_RESPONSE_STATUS_200) {
        toast.success(response?.message);
        const copyList: any = [...importantLinks];
        const updatedList = copyList?.filter(function (ls: { id: number }) {
          return ls.id !== linkId;
        });
        const deletedItem = copyList?.filter(function (ls: { id: number }) {
          return ls.id === linkId;
        });
        const deletedItemOrder = deletedItem[ZERO]?.orderNo;
        const finalList = updatedList?.map((lnk: any) => {
          let currentOrder = lnk?.orderNo;
          if (currentOrder > deletedItemOrder) {
            currentOrder = currentOrder - ONE;
          }
          return {
            displayText: lnk?.displayText,
            url: lnk?.url,
            linkDesc: lnk?.linkDesc,
            orderNo: currentOrder,
            id: lnk?.id,
          };
        });
        setImpLinks(finalList);
        handleClose();
        clearForm();
      } else {
        setBtnDisable(false);
        toast.error(response?.message);
      }
    }
  };

  return (
    <>
      <Row className="tilte-wrapper">
        <Col xl="12">
          <h1>
            <span className="text-uppercase">
              {Strings.Common.InnerSubTitle}
            </span>
            <br />
            {Strings.ImportantLinks.Title}
          </h1>
        </Col>
      </Row>
      <div className="content-wrapper dashboard welcome">
        <h3>{Strings.ImportantLinks.InfoTitle}</h3>
        <ul className="mb-0">
          <li>{Strings.ImportantLinks.InfoText}</li>
        </ul>
      </div>
      <div className="content-wrapper">
        <Row>
          <Col md="6" xl="4">
            <SLInput
              name="link"
              id="linkText"
              type="text"
              maxLength={MAX_LENGTH_200}
              length={importantLinkData.linkText?.length}
              value={importantLinkData.linkText}
              onChange={onTextImportantChange}
              errorMessage={importantLinkError.linkerror}
              label={Strings.ImportantLinks.LinkDisplay}
              placeholder={Strings.ImportantLinks.linkTextPlaceHolder}
            />
          </Col>
          <Col md="6" xl="4">
            <SLInput
              name="url"
              id="url"
              type="text"
              maxLength={MAX_LENGTH_500}
              length={importantLinkData.url?.length}
              value={importantLinkData.url}
              onChange={onTextImportantChange}
              errorMessage={importantLinkError.urlError}
              label={Strings.ImportantLinks.URL}
              placeholder={Strings.ImportantLinks.urlPlaceHolder}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12" xl="8">
            <SLInput
              name="description"
              id="description"
              type="text"
              as="textarea"
              value={importantLinkData.description}
              onChange={onTextImportantChange}
              length={importantLinkData.description.length}
              maxLength={MAX_LENGTH_2000}
              errorMessage={importantLinkError.descriptionError}
              label={Strings.ImportantLinks.Description}
              placeholder={Strings.ImportantLinks.descPlaceHolder}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12" xl="8" className="text-end mt-3 btn-wrap">
            <Button disabled={saveClicked} onClick={handleSaveLink}>
              {Strings.ImportantLinks.saveLink}
            </Button>
          </Col>
        </Row>
      </div>
      {importantLinks?.length > ZERO && (
        <h3>
          {Strings.ImportantLinks.LinkOrder}
          <span className="d-block">{Strings.ImportantLinks.DragAdjust}</span>
        </h3>
      )}

      <div>
        <div style={{ overflowY: "auto", height: HEIGHT_400 }}>
          <SortableLinks
            action={linkAction}
            list={importantLinks}
            setReorderList={setImpLinks}
            updateOrder={updateOrder}
          />
        </div>
      </div>
      <DeleteConfModal
        onHide={handleClose}
        show={show}
        title={Strings.ImportantLinks.deleteModal.Title}
        desc={Strings.ImportantLinks.deleteModal.Info}
        handleDelete={deleteLink}
        isDisabled={isDisable}
      />
    </>
  );
};

export default ImportantLinks;
