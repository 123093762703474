import { OverlayTrigger, Tooltip } from "react-bootstrap";
const ICEditLink = () => {
  return (
    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit</Tooltip>}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <g id="edit" transform="translate(0 0)">
          <path
            id="Path_2535"
            data-name="Path 2535"
            d="M3263.462,2579.194v11.123l-32,.253v-32h32V2562"
            transform="translate(-3231.462 -2558.57)"
            fill="none"
          />
          <path
            id="Path_2536"
            data-name="Path 2536"
            d="M5.272.058,2.162.144A2.2,2.2,0,0,0,.055,2.261L.033,3.07l7.219-.2.022-.809a1.93,1.93,0,0,0-2-2"
            transform="translate(20.642 5.936) rotate(45)"
            fillRule="evenodd"
          />
          <path
            id="Path_2537"
            data-name="Path 2537"
            d="M.081.485,3,4.814a.58.58,0,0,0,.956-.027L7.1.29A.576.576,0,0,0,7.2.077L0,.277A.493.493,0,0,0,.081.485"
            transform="translate(9.57 16.573) rotate(45)"
            fillRule="evenodd"
          />
          <path
            id="Path_2573"
            data-name="Path 2573"
            d="M0,0H7.222V8.673H0Z"
            transform="translate(17.196 9.358) rotate(45)"
          />
        </g>
      </svg>
    </OverlayTrigger>
  );
};

export default ICEditLink;
