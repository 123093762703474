const ICDashboard = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g id="Group_475" data-name="Group 475" transform="translate(-129 -1052)">
        <path
          id="Path_2558"
          data-name="Path 2558"
          d="M0,0H30V30H0Z"
          transform="translate(129 1052)"
          fill="#fff"
          opacity="0"
        />
        <path
          id="Path_2559"
          data-name="Path 2559"
          d="M13.281.01A12.758,12.758,0,0,0,3.7,21.743a3.581,3.581,0,0,0,2.551,1.023H19.278a3.587,3.587,0,0,0,2.555-1.025,12.709,12.709,0,0,0,3.7-9.127A12.883,12.883,0,0,0,13.281.01m-5.2,19.2a1,1,0,0,1-1.414.054,8.854,8.854,0,0,1,7.639-15.2,1,1,0,0,1-.364,1.967A6.852,6.852,0,0,0,8.026,17.793a1,1,0,0,1,.054,1.413m4.686-3.972a2.319,2.319,0,1,1,.7-4.518l4.252-4.252a1,1,0,0,1,1.414,1.414l-4.208,4.207a2.3,2.3,0,0,1,.16.83,2.319,2.319,0,0,1-2.319,2.319m5.854,4.094a1,1,0,1,1-1.342-1.482,6.864,6.864,0,0,0,2.254-5.08,6.978,6.978,0,0,0-.15-1.435,1,1,0,0,1,1.957-.415,8.981,8.981,0,0,1,.193,1.85,8.864,8.864,0,0,1-2.912,6.562"
          transform="translate(131 1056)"
        />
      </g>
    </svg>
  );
};

export default ICDashboard;
