/**
 * @file   src\components\FooterInner.tsx
 * @brief  Inner Footer component.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import "./FooterInner.scss";
import { Col, Row } from "react-bootstrap";
import Strings from "../assets/strings/Strings.json";
import { getCurrentYear, sendEmailContactSupport } from "../helpers/common";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../constants/pageURLs";
import { noMessage, noSubject } from "../constants/common";

const FooterInner = () => {
  const navigate = useNavigate();
  return (
    <footer className="footer-inner">
      <Row>
        <Col lg="3">
          <a
            role="button"
            onClick={() =>
              sendEmailContactSupport(
                Strings.FooterInner.SupportMail,
                noMessage,
                noSubject
              )
            }
          >
            {Strings.FooterInner.SupportMail}
          </a>
        </Col>
        <Col lg="6" className="text-center">
          {Strings.Common.CopyRight?.replace("[YEAR]", getCurrentYear())}
        </Col>
        <Col lg="3" className="text-lg-end footer-links d-inline-block">
          <a role="button" onClick={() => navigate(pageURLs.termsOfUse)}>
            {Strings.FooterInner.terms}
          </a>
          <span>|</span>
          <a
            role="button"
            onClick={() =>
              window.open(Strings.FooterInner.privacyPolicyLink, "_blank")
            }
          >
            {Strings.FooterInner.privacyPolicy}
          </a>
        </Col>
      </Row>
    </footer>
  );
};

export default FooterInner;
