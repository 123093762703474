/**
 * @file   src\containers\TermsOfUse.tsx
 * @brief  terms and condition page.
 * @date   June, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { Col, Row } from "react-bootstrap";
import HeaderLogin from "../components/HeaderLogin";
import FooterInner from "../components/FooterInner";
import { useEffect, useState } from "react";
import { isMobileDevice } from "../helpers/common";
/* eslint-disable */
const TermsOfUse = () => {
  //Initial States
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isResponsiveDevice, setIsMobilDevice] = useState<boolean>(false);

  //This useeffect is user for checking the mobile device or not, if it is a mobile device then we have to hide header and footer
  useEffect(() => {
    setIsLoading(false);
    if (isMobileDevice()) {
      setIsMobilDevice(true);
    }
  }, []);
  return (
    <>
      {
        !isResponsiveDevice && (
          <HeaderLogin />
        ) /* Hide header in mobile devices */
      }
      {isLoading ? (
        <Col className="text-center no-record mt-5">
          <output className="spinner-border"></output>
        </Col>
      ) : (
        <div className="terms_conditions">
          <Row className="tilte-wrapper">
            <Col xl="12">
              <h1>
                <span className="text-uppercase">ADMIN PORTAL</span>
                <br />
                Terms and Conditions for use of SPOTLIGHT SAFETY
              </h1>
            </Col>
          </Row>
          <p>
            PLEASE READ THE FOLLOWING AGREEMENT CAREFULLY. IT IS A LEGAL
            AGREEMENT BETWEEN YOU AND SPOTLIGHT SAFETY, INC., THE MAKERS OF
            SPOTLIGHT SAFETY, SPOTLIGHT SAFETY, INC. COLLECTS DATA ABOUT USAGE
            OF OUR PRODUCTS. WE DON’T SELL YOUR DATA TO THIRD PARTIES. WE
            COLLECT IT TO IMPROVE THE PRODUCT. WHEN YOU ACCEPT THIS AGREEMENT
            YOU ARE CONSENTING TO SUCH DATA COLLECTION AND TO THE TERMS OF
            SPOTLIGHT SAFETY, INC.’S PRIVACY POLICY.
          </p>

          <div>
            <h3>1. Accepting this Agreement; Applicability</h3>
            <p>
              1.1. This Agreement (the “Agreement”) is entered into by you (the
              “User”) and Spotlight Safety, Inc. (“Spotlight”). User and
              Spotlight are each a “Party” to this Agreement and collectively
              are the “Parties.” You would like to use Spotlight Safety (the
              “App”), a software application provided by Spotlight as described
              in this Agreement, and Spotlight would like to enable you to do
              so. The parameters of your access may change from time to time.
              This Agreement applies to all versions and subscriptions to the
              App, including the free trial. Any new features which are added to
              the App shall also be subject to the terms of this Agreement. The
              terms of this Agreement apply to you at all times you are using
              the App for any reason.
            </p>
            <p>
              1.2. In order to lawfully use the App you must accept this
              Agreement. You accept this Agreement by creating an account or
              logging into Spotlight Safety. If you accept this Agreement, you
              do so on your own behalf and as a representative of your company,
              organization, education institution or government agency,
              instrumentality or department. If you accept this Agreement, you
              also accept the terms of Spotlight’s{" "}
              <a
                href="https://spotlightsafetyapp.com/privacy-policy/"
                target="_blank"
              >
                Privacy Policy
              </a>
              .
            </p>
            <p>
              1.3. Spotlight may change the terms of this Agreement at any time.
              If you continue using Spotlight Safety, you accept and agree to
              the new terms. If you do not agree to the new terms, your use of
              the App may be suspended or terminated. You agree that your
              acceptance of new terms may be signified as described in paragraph
              1.2.
            </p>

            <h3>2. The Parameters of Your Subscription to Spotlight Safety</h3>
            <p>
              2.1 When you accept these terms Spotlight grants you a
              non-exclusive, non-transferrable subscription to the version of
              the App to which Spotlight is providing access at the time. At
              Spotlight’s discretion, the subscription may be provided via web
              application administered by Spotlight, a.k.a. software as a
              service, or native mobile app provided through app stores of
              Spotlight's choosing. This subscription is for your purposes only.
              You shall not allow Third Parties to use your access Spotlight
              Safety. You are solely responsible for any fees or costs
              associated with Third Party software or services that you use in
              conjunction with Spotlight Safety.
            </p>
            <p>
              2.2 Technical support requests can be submitted via email to{" "}
              <a href="mailto:support@spotlightsolutions.com">
                support@spotlightsolutions.com
              </a>
              . Spotlight will make commercially reasonable efforts to correct
              bugs and implement software updates that Spotlight determines, in
              its sole and absolute discretion, materially affect the operation
              of Spotlight Safety as well as provide technical assistance to
              customers using Spotlight Safety. This shall not constitute a
              warranty of any kind, all such warranties having been disclaimed
              pursuant to this Agreement.
            </p>
            <p>
              2.3 Spotlight shall in no event provide maintenance or repair on
              the App necessitated by User’s alteration of the App or any part
              thereof, relocation of the App or any part thereof unless
              previously agreed upon by Spotlight, use of ancillary equipment or
              software not suitable for use with the App or any part thereof, or
              damage caused by virus, spyware, or lack of a firewall.
            </p>
            <p>
              2.4 Spotlight reserves the right at any time and without prior
              notice to temporarily limit User’s access in order to perform
              repairs, make modifications, or as a result of circumstances
              beyond Spotlight’s control. Spotlight may modify any aspect of the
              App at any time. No service levels or standards are guaranteed,
              except as expressly set forth in this Agreement.
            </p>

            <h3>3. Representations, Warranties, and Limitation of Liability</h3>
            <p>
              <strong>3.1 No Warranty.</strong> THE APP IS PROVIDED ‘AS IS’.
              CONSULTANT, ITS SUBSIDIARIES AND AFFILIATES, SUBCONTRACTORS AND
              SUPPLIERS MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE APP,
              EXPRESS OR IMPLIED, AND SPECIFICALLY DISCLAIM ANY WARRANTY OF
              MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. CONSULTANT
              FURTHER DISCLAIMS THAT THE APP MEETS STANDARDS FOR OPERATIONAL
              COMPLIANCE OR CERTIFICATION IN ANY PARTICULAR AREA, INCLUDING BUT
              NOT LIMITED TO GOVERNMENT OR INDUSTRY ASSOCIATION REQUIREMENTS.
              CONSULTANT DOES NOT PROVIDE ANY WARRANTY AS TO THE AVAILABILITY OF
              THE SERVICES OR THAT THE SERVICES WILL OPERATE WITHOUT
              INTERRUPTION OR BE ERROR FREE.
            </p>
            <p>
              <strong>3.2 Specific Disclaimers.</strong> Spotlight does not
              warrant that the App or any part thereof, is free of defects, will
              operate uninterrupted or error-free, or that non-material defects
              will be corrected. The User acknowledges that the App operates
              over the internet and/or an intranet. The User acknowledges that
              Spotlight does not control the transfer of data over the internet
              or User’s intranet, and that the App may be subject to
              limitations, delays, and other problems inherent in the use of the
              internet or User’s intranet. Spotlight is not responsible for any
              delays, delivery failures, or other damage resulting from such
              problems. The User further acknowledges that User Intellectual
              Property, User Data, User Data, and all Third Party Intellectual
              Property are the creation of the User, Users, or a Third Party and
              Spotlight makes no warranty with respect thereto. Spotlight does
              not guarantee that unauthorized Third Parties will never be able
              to defeat its security measures and that the App is subject to
              outages, attack, interruption, interference, viruses, and hacking.
              User acknowledges that all User Intellectual Property and Third
              Party Intellectual Property is used at User’s own risk.
            </p>
            <p>
              <strong>3.3 User Solely Responsible.</strong> User is solely
              responsible for its safety practices. Spotlight takes no
              responsibility for User’s the safety of User’s people or property.
              Spotlight takes no responsibility for the efficacy of emergency
              services in case of a safety incident. User is solely responsible
              for any defects in the App caused by a) the failure of User to
              provide a suitable operating environment; b) User's misuse, damage
              or unauthorized modification of the App or any element or part
              thereof; c) User's combination of any element or part of the App
              with any other hardware or software, other than as authorized in
              writing by Spotlight; d) User's use of the App, or any element or
              part thereof, as incorporated into a system other than as
              authorized in writing by Spotlight; e) User's negligence, willful
              misconduct, or violation of this Agreement.
            </p>
            <p>
              <strong>3.4 Indemnity.</strong> In addition to the indemnity
              obligations in the MSA, User shall indemnify, defend, and hold
              harmless Spotlight from all claims, actions, losses, expenses,
              costs or damages, including reasonable costs, expenses, and
              attorney fees, arising out of (I) User’s handling of its Users’
              personally identifiable information (“PII”), including any PII
              that User shares with Spotlight for purposes of the App or this
              SOW, (ii) User safety incidents, (iii) User’s failure to comply
              with these terms; (iv) any activities of a Third Party User’s
              access to the App; or (v) actions of emergency services requested
              or accessed using the App.
            </p>
            <p>
              <strong>3.5 Limitation of Liability.</strong> NEITHER PARTY SHALL
              BE LIABLE FOR INCIDENTAL, INDIRECT, SPECIAL, EXEMPLARY OR
              CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, SAVINGS OR REVENUES
              OF ANY KIND, WHETHER OR NOT SUCH PARTY HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES, OTHER THAN WITH RESPECT TO BREACHES
              OF OBLIGATIONS INTELLECTUAL PROPERTY THIS PROVISION SHALL APPLY
              EVEN IN THE EVENT OF THE FAILURE OF AN EXCLUSIVE REMEDY. WITHOUT
              LIMITING THE FOREGOING AND NOTWITHSTANDING ANY OTHER PROVISION OF
              THIS AGREEMENT, THE MAXIMUM AGGREGATE LIABILITY OF EITHER PARTY TO
              THE OTHER SHALL BE THE AMOUNTS PAID TO EXAPTIVE BY THE CUSTOMER IN
              THE PRIOR TWELVE (12) MONTHS, OTHER THAN WITH RESPECT TO
              OBLIGATIONS REGARDING INTELLECTUAL PROPERTY.
            </p>
            <p>
              <strong>3.6 Limitations Imposed by Law.</strong> To the extent
              anything in this Section 4 is limited by applicable law, such
              disclaimer of warranty or limitation of liability shall be limited
              only to the extent required by law.
            </p>
            <h3>4. User’s Obligations and Responsibilities.</h3>
            <p>
              4.1 <u>No Unauthorized Distribution of Spotlight Safety.</u>{" "}
              Except as otherwise set forth in this Agreement, User agrees not
              to rent, lease, lend, upload, host on any website or server, sell,
              redistribute, or sublicense Spotlight Safety, in whole or in part,
              or to enable others to do so. The User further agrees that it may
              not modify, make derivative works of, disassemble, access, review,
              reverse compile, or reverse engineer any part of Spotlight Safety,
              or access or use any part or element thereof, in order to build or
              support, or assist a Third Party in building or supporting, other
              products or services. In no event will User be entitled to access
              or review the object or source code of Spotlight Safety, unless it
              is made explicitly available for access and review by Spotlight at
              its sole discretion. The User agrees to make every reasonable
              effort to prevent unauthorized Third Parties from accessing and
              making unauthorized use of Spotlight Safety. User also agrees not
              to remove or destroy any proprietary markings or legends or any
              encrypted license keys or similar security devices placed upon or
              contained within any Spotlight Safety.
            </p>
            <p>
              4.2 <u>Lawful and Appropriate Use.</u> User may not use the App to
              connect to any APIs in any way that violates laws, regulations, or
              the terms of the API. User must comply with all applicable
              criminal, civil, and statutory laws and regulations, including but
              not limited to all applicable privacy and data collection laws and
              regulations with respect to any collection, use or disclosure of
              user or device data. User may not use the App for the purpose of
              harassing, abusing, spamming, stalking, threatening, or otherwise
              violating the legal rights of others. User agrees that it will not
              collect, disseminate, or use any such data for any unauthorized
              purpose. User may not upload User Data that contains any malware,
              malicious or harmful code, programs, or other internal components
              (e.g., computer viruses, trojan horses, “backdoors”) which could
              damage, destroy, or adversely affect the App or other software,
              firmware, hardware, data, systems, services, or networks.
            </p>
            <p>
              4.3 <u>Third-Party License(s).</u> If User Data includes any Third
              Party’s Intellectual Property, including free and open-source
              software (“FOSS”), User agrees to comply with all applicable
              licensing terms provided by the Third Party or a FOSS license. If
              User discovers any conflict between its User Data and a Third
              Party license, a FOSS license, or the terms of this Agreement,
              User agrees to immediately notify Spotlight of it and will
              cooperate with Spotlight to resolve such matter. User acknowledges
              that Spotlight may immediately cease distribution of or remove
              access to any User Data and may refuse to accept any subsequent
              submissions to the App until such matter is resolved to
              Spotlight’s satisfaction.
            </p>
            <p>
              4.4 <u>Minimum Age.</u> The App is not for use by anyone under the
              age of 13 (the “Minimum Age”). To use the App, Client agrees that:
              a) all Users must be the Minimum Age or older; and b) each User
              may only have one user profile, which must be in his or her real
              name. Notwithstanding the Minimum Age stated above, if law
              requires that Client’s User(s) must be older in order for
              Spotlight to lawfully provide the App to Client without parental
              consent then the Minimum Age is such older age. Without limiting
              any other termination rights that Spotlight has under this
              Agreement, Spotlight may immediately terminate this Agreement and
              Client’s access to the App if it determines that Client’s User(s)
              does not satisfy the Minimum Age requirement.
            </p>
            <p>
              4.5 <u>User Solely Responsible.</u> User is solely responsible for
              proper use of Third Party software or Intellectual Property it
              uses in conjunction with Spotlight Safety. User is solely
              responsible for providing, installing and maintaining at its own
              expense all equipment, facilities, and services necessary to
              enable its access and use of Spotlight Safety. Spotlight is not
              responsible for any damages User causes or laws User breaks. User
              agrees that it will not seek any regulatory marketing permissions
              or make any determinations that may result in any Spotlight
              products being deemed regulated or that may impose any obligations
              or limitations on Spotlight. User agrees to promptly notify
              Spotlight of any complaints or threats of complaints User Data in
              relation to any legal or regulatory requirements. User represents
              and warrants that it has all necessary rights to own or use the
              User’s Intellectual Property or any Third Party’s Intellectual
              Property used by User in connection with this Agreement.
            </p>

            <h3>5. Use of SMS Text Messaging</h3>
            <p>
              5.1 <u>SMS Messaging.</u> The Spotlight Safety App facilitates
              communication between users by allowing SMS text messages to be
              sent to recipients via their mobile devices. By using the
              Spotlight Safety App, you consent to sending and receiving texts
              sent through the app. You also agree to the following:
            </p>
            <p>
              5.2 <u>No Marketing Messages.</u> The App is designed solely for
              non-marketing communication purposes. It does not facilitate,
              support, or promote any marketing, promotional, or unsolicited
              messaging, in compliance with the latest regulations and standards
              set by mobile carriers and governing bodies.
            </p>
            <p>
              5.3 <u>Compliance with Carrier Rules.</u> You agree to use the SMS
              text messaging feature in accordance with all applicable laws,
              regulations, and carrier-specific rules. You acknowledge that
              sending marketing or promotional SMS messages through the App is
              strictly prohibited, and doing so may result in the suspension or
              termination of your access to the App.
            </p>
            <p>
              5.4 <u>User Responsibility:</u> You are solely responsible for the
              content of the SMS messages you send through the App. You agree
              not to use the App to send messages that are unlawful, defamatory,
              abusive, fraudulent, or that violate the rights of others. We
              reserve the right to monitor, review, and remove any messages that
              we believe violate these terms.
            </p>
            <p>
              5.5 <u>Consent to Texting:</u> By using the App to send SMS
              messages, you represent that you have obtained the necessary
              consent from the recipients to receive such messages, in
              compliance with applicable laws and regulations.
            </p>
            <p>
              5.6 <u>Prohibited Activities</u> In addition to the above, you
              agree not to use the App to:
              <ul className="mt-2">
                <li>
                  Engage in spamming, phishing, or other forms of unsolicited
                  communication.
                </li>
                <li>
                  Impersonate another person or entity or misrepresent your
                  affiliation with any person or entity.
                </li>
                <li>
                  Send messages that contain viruses, malware, or other harmful
                  components.
                </li>
              </ul>
            </p>
            <h3>6. Intellectual Property.</h3>
            <p>
              6.1 <u>Spotlight’s Intellectual Property.</u> User acknowledges
              and agrees that Spotlight shall retain exclusive ownership and
              rights to Spotlight Safety, and User has no right to use or
              license the Spotlight Safety, except as expressly set forth in
              this Agreement. In the event that User provides Spotlight
              comments, suggestions, or recommendations with respect to
              Spotlight Safety, User hereby grants to Spotlight an exclusive,
              worldwide, royalty free, irrevocable, perpetual license to use and
              otherwise incorporate any such comments, suggestions, and
              recommendations into the App without restriction.
            </p>
            <p>
              6.2 <u>User’s Intellectual Property.</u> Spotlight acknowledges
              and agrees that User shall retain exclusive ownership of all
              User’s Intellectual Property. Spotlight has no right or license to
              any of the User’s Intellectual Property, except as expressly set
              forth in this Agreement, including Spotlight’s Privacy Policy.
              User hereby acknowledges and accepts that, pursuant to this
              Agreement, Spotlight will be able to view all User Data, the
              contents of all of the foregoing, and any associated usage data or
              metadata. User hereby grants Spotlight a worldwide, royalty free,
              irrevocable, perpetual license to such usage data and metadata for
              use of any kind, including modification, making derivative works,
              and commercializing derivative works.
            </p>
            <p>
              6.3 <u>Nonexclusively; Costs.</u> User acknowledges that the
              rights granted to it under this Agreement are nonexclusive and
              that, without limiting the foregoing, nothing in this Agreement
              will be interpreted to restrict in any way Spotlight’s right to
              license, sell, or otherwise make available the App or services to
              Third Parties. Each party shall have the exclusive right and
              option to file and prosecute any patent applications and to
              maintain any patents that are its Intellectual Property. Each
              party shall bear its own costs and expenses in preparing, filing,
              prosecuting, maintaining and extending patent rights relating to
              this Agreement.
            </p>
            <h3>7. Confidential Information</h3>
            <p>
              7.1 <u>Confidentiality Obligations.</u> Each party will not use
              the other party’s Confidential Information, except as necessary
              for the performance of this Agreement and will not disclose such
              Confidential Information to any Third Party, except to those of
              its employees and subcontractors that need to know such
              Confidential Information for the performance of this Agreement,
              provided that each such employee and subcontractor is subject to a
              written agreement that includes binding use and disclosure
              restrictions that are at least as protective as those set forth
              herein. Each party will use all reasonable efforts to maintain the
              confidentiality of the other party’s Confidential Information in
              its possession or control, but in no event less than the efforts
              that it ordinarily uses with respect to its own confidential
              information of similar nature and importance. The foregoing
              obligations will not restrict either party from disclosing the
              other party’s Confidential Information or the terms and conditions
              of this Agreement: I) pursuant to the order or requirement of a
              court, administrative agency, or other governmental body, provided
              that the party required to make such a disclosure gives reasonable
              notice to the other party to enable it to contest such order or
              requirement; ii) on a confidential basis to its legal or
              professional financial advisors; iii) as required under applicable
              securities regulations; iv) on a confidential basis to present or
              future providers of venture capital and/or potential private
              investors in or acquirers of such party. The restrictions set
              forth in this Section will remain in effect during the term of
              this Agreement and for a period of three (3) years thereafter.
            </p>
            <p>
              7.2 <u>Confidentiality Exclusions.</u> These confidentiality
              obligations set forth in Section 7.1 will not apply to any
              Confidential Information that: a) is in or enters the public
              domain without breach of this Agreement by the receiving party;
              ii) the receiving party lawfully receives from a third party
              without restriction on use or disclosure; iii) the receiving party
              knew prior to receiving such information from the disclosing party
              without breach of a nondisclosure obligation; or iv) the receiving
              party independently develops without reference to the other
              party’s Confidential Information.{" "}
            </p>
            <p>
              7.3 <u>Third Party Services Used in Spotlight Safety.</u> User
              acknowledges and accepts that at times Spotlight uses Third-party
              software and services to perform functions within the App and that
              such software or services may view or obtain data or metadata as a
              result of User’s use of Spotlight Safety. Spotlight makes a
              good-faith effort to ensure use of such services does not pose any
              risk to User’s Confidential Information. Spotlight, however,
              cannot guarantee there is no risk, and User acknowledges that
              Spotlight is not responsible for breaches in confidentiality
              caused by third-parties. Those Third-party services are listed in
              Spotlight’s{" "}
              <a
                href="https://spotlightsafetyapp.com/privacy-policy/"
                target="_blank"
              >
                Privacy Policy.
              </a>{" "}
            </p>
            <p>
              7.4 <u>Equitable Relief.</u> The Parties acknowledge that a breach
              of any confidentiality provision of this Agreement may cause the
              other Party irreparable damage, for which the award of damages
              would not be adequate compensation. Consequently, the Parties may
              institute an action to enjoin the breaching party from any and all
              acts in violation of those provisions, which remedy shall be
              cumulative and not exclusive, and a party may seek the entry of an
              injunction enjoining any breach or threatened breach of those
              provisions, in addition to any other relief to which the
              non-breaching party may be entitled at law or in equity.
            </p>
            <h3>8. Term and Termination</h3>
            <p>
              8.1 <u>Term.</u> The term of this Agreement (the “Term”) shall
              commence on the Effective Date and continue in full force and
              effect for so long as the User has an account for the App or
              Spotlight terminates this Agreement, in its sole and absolute
              discretion, by ceasing to provide access to Spotlight Safety. User
              may delete its account for the App by either using user interface
              provided within the App for that purpose or emailing{" "}
              <a href="mailto:support@spotlightsolutions.com">
                support@spotlightsolutions.com
              </a>
              .
            </p>
            <p>
              8.2 <u>Effect of Termination.</u> Upon termination of this
              Agreement, all rights, licenses and authorizations granted to User
              hereunder will immediately terminate and User shall immediately
              cease all use of and other activities with respect to the App and
              deliver to Spotlight, or at Spotlight’s written request destroy
              and permanently erase from all devices and systems that User
              directly or indirectly controls, any instances of the App or parts
              thereof. At Spotlight’s request, User shall certify to Spotlight
              in a signed and notarized written instrument that it has complied
              with these requirements.
            </p>
            <p>
              8.3 <u>Survival.</u> termination of this Agreement shall not
              affect the accrued rights of either Spotlight or User arising
              under this Agreement. All provisions which based on their nature
              should survive shall survive such expiration or termination.
            </p>
            <h3>9. Miscellaneous</h3>
            <p>
              9.1 <u>Independent Status of Parties.</u> Each Party shall act as
              an independent contractor and shall not bind nor attempt to bind
              the other Party to any contract, or any performance of obligations
              outside of this Agreement. Nothing contained or done under this
              Agreement shall be interpreted as constituting either Party as the
              agent of the other Party in any sense of the term whatsoever
              unless expressly so stated, and neither Spotlight nor its
              employees are eligible for any User employee benefits.
            </p>
            <p>
              9.2 <u>Indemnification.</u> In the event that any claim or legal
              proceedings are brought by a Third Party against Spotlight for any
              reason arising out of User’s use of Spotlight Safety, including
              without limitation, any claim against Spotlight alleging that any
              User’s Intellectual Property or any Third Party’s Intellectual
              Property used by User violates or infringes upon such Third
              Party’s Intellectual Property rights, the User agrees to
              indemnify, defend, and hold harmless Spotlight for any damages
              arising out of any such claim or legal proceeding, and pay any
              costs, damages and reasonable attorneys’ fees arising out of or
              resulting from any such claim or legal proceeding.
            </p>
            <p>
              9.3 <u>Governing Law.</u> This Agreement shall be governed by and
              interpreted in accordance with the laws of the State of New York,
              without regard to its conflict of laws principles.{" "}
            </p>
            <p>
              9.4 <u>Notices.</u> Except as otherwise specified, all notices,
              instructions and other communications hereunder or in connection
              herewith shall be in writing and shall be sent by facsimile email
              address, in each case to the contact information set forth here:
              a) notices to Spotlight shall be addressed to{" "}
              <a href="mailto:support@spotlightsolutions.com">
                support@spotlightsolutions.com
              </a>
              ; (ii) notices to User to the email address provided by User for
              their the App account.
            </p>
            <p>
              9.5 <u>Entire Agreement.</u> This Agreement constitutes the full
              understanding of the parties with respect to the subject matter
              hereof and supersedes all prior understandings and writings
              relating thereto. No waiver, alteration or modification of any of
              the provisions hereof shall be binding unless made in writing and
              signed by the parties.
            </p>
            <p>
              9.6 <u>Construction.</u> Unless the context expressly requires
              otherwise, a) the word “or” shall be interpreted in the inclusive
              sense (i.e., “and/or”), b) the word “include(ing)” shall mean
              “include(Ing) without limitation” and c) the singular shall
              include the plural and vice versa. Headings in this Agreement are
              used for convenience only and shall not be used in the
              construction of the meaning of this Services Agreement. The
              language used in this Services Agreement shall be deemed to be the
              language chosen by the Parties to express their mutual intent, and
              no rule of strict construction shall be applied against either
              Party.
            </p>
            <p>
              9.7 <u>Severability.</u> In the event that any provision of this
              Agreement is held by a court of competent jurisdiction to be
              unenforceable because it is invalid or in conflict with any law of
              any relevant jurisdiction, the validity of the remaining provision
              shall be not be affected, and the court shall construe the
              provision, to the maximum extent possible, to accomplish the
              original business purpose.
            </p>
            <p>
              9.8 <u>Assignment.</u> Neither this Agreement nor any of the
              rights or obligations hereunder may be assigned by User without
              the prior consent of Spotlight.
            </p>
            <h3>10. Definitions</h3>
            <p>
              10.1 <u>Intellectual Property.</u> “Intellectual Property” shall
              include all: a) patents, patent applications, patent disclosures
              and all related continuation, continuation-in-part, divisional,
              reissue, reexamination, utility model, certificate of invention
              and design patents, patent applications, registrations and
              applications for registrations; b) trademarks, service marks,
              trade dress, Internet domain names, logos, trade names and
              corporate names and registrations and applications for
              registration thereof; c) copyrights and registrations and
              applications for registration thereof; d) mask works and
              registrations and applications for registration thereof; e)
              computer software, data and documentation; f) inventions, trade
              secrets and confidential business information, whether patentable
              or nonpatentable and whether or not reduced to practice, know-how,
              manufacturing and product processes and techniques, research and
              development information, copyrightable works, financial, marketing
              and business data, pricing and cost information, business and
              marketing plans and customer and supplier lists and information;
              and g) copies and tangible embodiments of any of the foregoing.
            </p>
            <p>
              10.2 <u>Confidential Information.</u> “Confidential Information”
              shall mean any of the following, whether in oral, written, or
              other tangible form: a) the terms of this Agreement, b) whether or
              not marked or designated as confidential by the disclosing party,
              the disclosing party’s financial plans, financial projections,
              product strategies, non-public product offerings, product or
              service specifications, data or datasets, scope or method of
              providing services, customer names or lists, target or actual
              markets for products or services, product or service developments,
              processes, designs, research, marketing methods or distribution
              plans, c) any other information the disclosing party clearly marks
              as its Confidential Information, or that the disclosing party
              verbally designates as its Confidential Information at the time of
              disclosure or within a reasonable time thereafter, and d)
              information that, due to the nature of the information and the
              circumstances of disclosure, a reasonable person clearly would
              understand to be the Confidential Information of the disclosing
              party.
            </p>
            <p>
              10.3 <u>User Data.</u> “User Data” shall mean any content or data
              that User uploads or inputs to the App, as well as any content or
              data that User’s employer (Spotlight’s Client) provides for or
              about User.{" "}
            </p>
          </div>
          {
            !isResponsiveDevice && (
              <FooterInner />
            ) /* Hide footer in mobile devices */
          }
        </div>
      )}
    </>
  );
};

export default TermsOfUse;
