const ICDemo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g id="demo_icn" transform="translate(458 122)">
        <rect
          id="Rectangle_451"
          data-name="Rectangle 451"
          width="30"
          height="30"
          transform="translate(-458 -122)"
          opacity="0"
        />
        <path
          id="Path_2575"
          data-name="Path 2575"
          d="M23.341,0H2.659A2.662,2.662,0,0,0,0,2.66V17.213a2.663,2.663,0,0,0,2.659,2.66h8.98v2.191H6.149a1,1,0,0,0,0,2H19.128a1,1,0,1,0,0-2H13.639V19.873h9.7A2.663,2.663,0,0,0,26,17.213V2.66A2.662,2.662,0,0,0,23.341,0M24,17.213a.66.66,0,0,1-.659.66H2.659A.66.66,0,0,1,2,17.213V2.66A.66.66,0,0,1,2.659,2H23.341A.66.66,0,0,1,24,2.66Z"
          transform="translate(-456 -119)"
        />
        <path
          id="Path_2576"
          data-name="Path 2576"
          d="M13,3.809a5.957,5.957,0,1,0,5.957,5.957A5.957,5.957,0,0,0,13,3.809m2.18,6.2-3.255,1.879a.531.531,0,0,1-.8-.459V7.674a.531.531,0,0,1,.8-.46L15.18,9.093a.531.531,0,0,1,0,.92"
          transform="translate(-456 -119)"
        />
        <rect
          id="Rectangle_452"
          data-name="Rectangle 452"
          width="26"
          height="24.064"
          transform="translate(-456 -119)"
          fill="none"
        />
      </g>
    </svg>
  );
};

export default ICDemo;
