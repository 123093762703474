const ICSafetyOfficer = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_423"
            data-name="Rectangle 423"
            width="24"
            height="23.995"
            transform="translate(0 0)"
          />
        </clipPath>
      </defs>
      <g id="SafetyOfficer" transform="translate(-89 -1470)">
        <rect
          id="Rectangle_433"
          data-name="Rectangle 433"
          width="30"
          height="30"
          transform="translate(89 1470)"
          opacity="0"
        />
        <g
          id="Group_443"
          data-name="Group 443"
          transform="translate(92 1473)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_2548"
            data-name="Path 2548"
            d="M21.308,0H2.693A2.7,2.7,0,0,0,0,2.693V7.651a17.341,17.341,0,0,0,4.2,11.3.83.83,0,0,0,.072.085,17.326,17.326,0,0,0,6.752,4.774h.005a2.68,2.68,0,0,0,1.939,0h.005a17.322,17.322,0,0,0,6.865-4.907c.014-.019.034-.034.047-.054A17.348,17.348,0,0,0,24,7.636V2.692A2.7,2.7,0,0,0,21.308,0M12,12.995a3.8,3.8,0,1,1,0-7.61v0a3.8,3.8,0,1,1,0,7.607m.247,8.954a.7.7,0,0,1-.494,0h0a15.254,15.254,0,0,1-5.514-3.691,7.462,7.462,0,0,1,11.522,0,15.228,15.228,0,0,1-5.514,3.691"
          />
        </g>
      </g>
    </svg>
  );
};

export default ICSafetyOfficer;
