const ICCheckinArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <g
        id="Group_493"
        data-name="Group 493"
        transform="translate(-1176.156 -536.343)"
      >
        <rect
          id="Rectangle_464"
          data-name="Rectangle 464"
          width="14"
          height="14"
          transform="translate(1176.156 536.343)"
          fill="none"
        />
        <path
          id="Path_39"
          data-name="Path 39"
          d="M83.162,45.346l7.77-5.049a.539.539,0,0,1,.809.525V51.28a.533.533,0,0,1-.8.525c-1.77-1.18-5.978-4.251-7.77-5.41a.644.644,0,0,1,0-1.049Z"
          transform="translate(1095.834 497.292)"
        />
      </g>
    </svg>
  );
};

export default ICCheckinArrow;
