/**
 * @file   src\containers\CreatePassword.tsx
 * @brief  Create Password  page.
 * @date   May, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import HeaderLogin from "../components/HeaderLogin";
import "../assets/styles/Login.scss";
import Strings from "../assets/strings/Strings.json";
import { Link, useSearchParams } from "react-router-dom";

import FooterLogin from "../components/FooterLogin";
import TroubleLoginPassword from "../components/TroubleLoginPassword";
import { useEffect, useRef, useState } from "react";
import { passwordValidation } from "../helpers/validations";
import { Alert } from "react-bootstrap";
import {
  tokenValidation,
  createUpdatePassword,
} from "../services/loginService";
import {
  ALERT_DANGER,
  ALERT_SUCCESS,
  EMAIL,
  EMPTY_STRING,
  HTTP_RESPONSE_STATUS_200,
  PASSWORD_CREATION_SUCCESS_REDIRECT_URL,
} from "../constants/common";
import { removeWhiteSpace } from "../helpers/common";
import {
  CreatePasswordForm,
  CreatePasswordFormError,
} from "../interfaces/ResetPassword";
import { ResponseObjects } from "../interfaces/AxiosResponse";
import {
  CONFIRM_PASSWORD_VALIDATION,
  ENTER_PASSWORD,
  PASSWORD_MISMATCH,
  RE_ENTER_PASSWORD,
} from "../constants/validationMessages";
import { ChangePassword } from "../interfaces/Login";
import { pageURLs } from "../constants/pageURLs";
import ReCAPTCHA from "react-google-recaptcha";
import AppStoreDetails from "../components/AppStoreDetails";

const CreatePassword = () => {
  //Constant variable declarations

  const pwdView = "pwdView";
  const inValidTokenView = "inValidTokenView";
  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get("token");

  //Initial form values
  const [form, setForm] = useState<CreatePasswordForm>({
    newPassword: "",
    confPassword: "",
    activeView: "",
  });

  //Initial form errors
  const [formError, setFormError] = useState<CreatePasswordFormError>({
    newPasswordError: EMPTY_STRING,
    confPasswordError: EMPTY_STRING,
    generalError: EMPTY_STRING,
    successMessage: EMPTY_STRING,
    tokenError: EMPTY_STRING,
  });
  const [load, setLoad] = useState<boolean>(false); // Loader state
  //Initialise recaptcha ref
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  //This useefect will responsible for checking the token is valid before render the respective UI and set the view.
  useEffect(() => {
    validateToken();
  }, []);

  //Call the validate token API and based on the response the wll set the active view
  const validateToken = async () => {
    const response = await tokenValidation(resetToken);
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      setForm((form) => ({
        ...form,
        activeView: pwdView,
      }));
    } else {
      setForm((form) => ({
        ...form,
        activeView: inValidTokenView,
      }));
      setFormError((error) => ({
        ...error,
        tokenError: response?.message,
      }));
    }
  };

  const isChangePwdView = form?.activeView === pwdView;

  //Handle the text box changes and update into the state
  const onTextChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const currentId = e.currentTarget.id;
    setForm((form) => ({
      ...form,
      [currentId]: currentId !== EMAIL ? removeWhiteSpace(value) : value,
    }));
  };

  //Validate form data in submit
  const validateForm = () => {
    setFormError((error) => ({
      ...error,

      newPasswordError: EMPTY_STRING,
      confPasswordError: EMPTY_STRING,
      generalError: EMPTY_STRING,
    }));
    let isValid = true;
    if (isChangePwdView) {
      const newPwd: string = form?.newPassword?.trim();
      const confPwd: string = form?.confPassword?.trim();
      if (!newPwd && !confPwd) {
        setFormError((error) => ({
          ...error,
          newPasswordError: ENTER_PASSWORD,
          confPasswordError: CONFIRM_PASSWORD_VALIDATION,
        }));
        isValid = false;
      } else if (!newPwd) {
        setFormError((error) => ({
          ...error,
          newPasswordError: ENTER_PASSWORD,
        }));
        isValid = false;
      } else if (!confPwd) {
        setFormError((error) => ({
          ...error,
          confPasswordError: RE_ENTER_PASSWORD,
        }));
        isValid = false;
      } else if (newPwd) {
        const passWordError = passwordValidation(newPwd);
        if (passWordError) {
          setFormError((error) => ({
            ...error,
            newPasswordError: passWordError,
          }));
          isValid = false;
        } else {
          if (confPwd) {
            if (newPwd !== confPwd) {
              setFormError((error) => ({
                ...error,
                confPasswordError: PASSWORD_MISMATCH,
              }));
              isValid = false;
            }
          }
        }
      }
    }
    return isValid;
  };

  //Handle form submit click
  const handleSubmit = async () => {
    let reqPayload: ChangePassword;
    const isValid: boolean = validateForm();
    if (isValid) {
      setLoad(true);
      if (isChangePwdView) {
        let recaptchaToken = "";
        if (reCaptchaRef.current)
          recaptchaToken = await reCaptchaRef.current.executeAsync();
        reqPayload = {
          uniqueId: resetToken,
          password: form?.newPassword,
        };
        if (recaptchaToken) {
          reqPayload.recaptchaToken = recaptchaToken;
        }
        const response: ResponseObjects =
          await createUpdatePassword(reqPayload);
        if (response?.status === HTTP_RESPONSE_STATUS_200) {
          setForm((form) => ({
            ...form,
            newPassword: EMPTY_STRING,
            confPassword: EMPTY_STRING,
          }));
          //Redirecting to the spotlight site for successful password updation
          window.open(PASSWORD_CREATION_SUCCESS_REDIRECT_URL, "_self");
        } else {
          setFormError((error) => ({
            ...error,
            generalError: response?.message,
          }));
        }
      }
    }
    setLoad(false);
  };
  const currentActiveView = form?.activeView;

  //This will set th active view in the UI
  const setActiveView = () => {
    if (currentActiveView) {
      if (currentActiveView === inValidTokenView) {
        return <Alert variant={ALERT_DANGER}> {formError?.tokenError}</Alert>;
      } else if (isChangePwdView) {
        return (
          <TroubleLoginPassword
            onTextChange={onTextChange}
            errors={formError}
            values={form}
            handleSubmit={handleSubmit}
            isLoad={load}
          />
        );
      }
    }
  };

  return (
    <>
      <HeaderLogin />
      <div className="login-wrap">
        <div className="form-wrap m-auto">
          <h1 className="text-center">
            {/* <span className="text-uppercase">{Strings.Login.Subtitle}</span>
            <br /> */}
            {Strings.Login.createPasswordTitle}
          </h1>
          {(formError?.generalError || formError?.successMessage) && (
            <Alert
              variant={formError?.generalError ? ALERT_DANGER : ALERT_SUCCESS}
              dismissible
            >
              {" "}
              {formError?.generalError || formError?.successMessage}
            </Alert>
          )}

          {setActiveView()}

          <div className="w-100 text-center trouble-login">
            <Link to={pageURLs.login}>{Strings.TroubleLogin.LoginBack}</Link>
          </div>
        </div>
        <AppStoreDetails />
      </div>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
        size="invisible"
        ref={reCaptchaRef}
      />
      <FooterLogin />
    </>
  );
};

export default CreatePassword;
