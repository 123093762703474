/**
 *@file SLFileUpload.tsx
 *@brief Reusable File Upload Component
 *@date Feb, 2023
 *@author ZCO Engineer
 *@copyright (c) 2024, ZCO
 */
import React from "react";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import { InputProperty } from "../interfaces/GeneralInterface";
import "./SLFileUpload.scss";
import ICInfo from "../assets/img/ICInfo.svg";
type IntInput = InputProperty;

const SLFileUpload = (props: IntInput) => {
  const handleLengthNote = () => {
    const currentLength = props.length;
    return `${currentLength}/${props.maxLength}`;
  };

  const popover = (
    <Popover id="popover-info">
      <Popover.Body>{props.toolTipMessage}</Popover.Body>
    </Popover>
  );

  return (
    <>
      <Form.Group className="sl-input-file">
        {props.label && (
          <Form.Label>
            {props.label}
            <OverlayTrigger
              trigger={["hover", "hover"]}
              placement="top"
              overlay={popover}
            >
              <img src={ICInfo} alt="" />
            </OverlayTrigger>
          </Form.Label>
        )}
        <div className="position-relative browse-wrap">
          <Form.Control
            id={props.id}
            name={props?.name}
            type="file"
            placeholder={props.placeholder}
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            readOnly={props.readOnly}
            value={props.value}
            required={props.required}
            pattern={props.pattern}
            disabled={props.disabled}
            accept={props.accept}
          />

          <div
            className={
              props.errorMessage ? "custom-upload form-error" : "custom-upload"
            }
          >
            <span className="d-block text-nowrap">{props?.fileName}</span>
          </div>
          <div className="browse position-absolute">Browse</div>
        </div>
        <div className="d-flex">
          {props.errorMessage && (
            <div className="error-msg">{props.errorMessage}</div>
          )}
          {props.maxLength && (
            <div className="note-length">{handleLengthNote()}</div>
          )}
        </div>
      </Form.Group>
    </>
  );
};

export default SLFileUpload;
