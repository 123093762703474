/**
 * @file   src\components\TroubleLoginEmail.tsx
 * @brief  Trouble Login Form component.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Button } from "react-bootstrap";
import Strings from "../assets/strings/Strings.json";
import SLInput from "./SLInput";
import SLInputIcon from "./SLInputIcon";
import { MAX_LENGTH_20 } from "../constants/common";

const TroubleLoginPassword = (props: any) => {
  const { onTextChange, errors, values, handleSubmit } = props;
  return (
    <>
      <SLInput
        id="newPassword"
        name="newpassword"
        type="password"
        placeholder={Strings.TroubleLogin.ResetForm.newPassword}
        onChange={onTextChange}
        errorMessage={errors?.newPasswordError}
        value={values?.newPassword}
        maxLength={MAX_LENGTH_20}
      />

      <SLInputIcon
        id="confPassword"
        name="confpassword"
        placeholder={Strings.TroubleLogin.ResetForm.confPassword}
        onChange={onTextChange}
        errorMessage={errors?.confPasswordError}
        value={values?.confPassword}
        maxLength={MAX_LENGTH_20}
      />
      <Button
        variant="primary"
        className="w-100"
        onClick={() => handleSubmit()}
      >
        {props?.isLoad ? (
          <output className="spinner-border sm"></output>
        ) : (
          Strings.TroubleLogin.ResetForm.Button
        )}
      </Button>
    </>
  );
};
export default TroubleLoginPassword;
