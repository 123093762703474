/**
 *@file SLInput.tsx
 *@brief Reusable input component
 *@date Feb, 2023
 *@author ZCO Engineer
 *@copyright (c) 2024, ZCO
 */
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { InputProperty } from "../interfaces/GeneralInterface";
import "./SLInput.scss";
import ICShowPassword from "../assets/img/icons/PasswordShow";
import ICHidePassword from "../assets/img/icons/PasswordHide";
type IntInputIcon = InputProperty;

const SLInputIcon = (props: IntInputIcon) => {
  const passWord = "password";
  const textType = "text";
  const [passwordType, setPwdType] = useState<string>(passWord);
  const isPasswordType = passwordType === passWord;
  const passwordIconClick = () => {
    if (isPasswordType) {
      setPwdType(textType);
    } else {
      setPwdType(passWord);
    }
  };
  const errorClass: string =
    props.errorClass || props.errorMessage ? "form-error" : "";
  return (
    <Form.Group
      className={
        props?.label
          ? "sl-input position-relative has-icon with-label"
          : "sl-input position-relative has-icon"
      }
    >
      {props.label && <Form.Label>{props.label}</Form.Label>}
      <Form.Control
        id={props.id}
        name={props.name}
        type={props.type || passwordType}
        placeholder={props.placeholder}
        onChange={props.onChange}
        readOnly={props.readOnly}
        value={props.value}
        required={props.required}
        pattern={props.pattern}
        isInvalid={props.isInvalid}
        className={errorClass}
        onKeyDown={props.onKeyDown}
        maxLength={props?.maxLength}
      />
      <div
        onClick={() =>
          (props.onClick && props.onClick()) || passwordIconClick()
        }
        className="icon-wrap position-absolute d-flex align-items-center justify-content-center"
      >
        {props.icon ||
          (isPasswordType ? <ICShowPassword /> : <ICHidePassword />)}
      </div>
      {props.errorMessage && (
        <div className="error-msg">{props.errorMessage}</div>
      )}
    </Form.Group>
  );
};

export default SLInputIcon;
