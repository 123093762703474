import axiosClient from "../config/axiosConfig";
import { INVALID_SIGNATURE } from "../constants/validationMessages";
import { clearUserSession } from "../helpers/common";

export async function apiCall(
  url: string,
  method: string,
  data: any,
  headers: any = null,
  isFileUpload: any = false
) {
  try {
    const response = await axiosClient({
      url,
      method,
      params: method === "GET" && data ? data : {},
      data,
      headers,
    });
    if (response) {
      if (response?.data === INVALID_SIGNATURE) {
        clearUserSession();
      } else {
        if (isFileUpload) {
          return response;
        }
        return { status: response?.status, ...response?.data };
      }
    }
  } catch (error: any) {
    return { status: error?.response?.status, ...error?.response?.data };
  }
}
