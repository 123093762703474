const ICSupport = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g id="support" transform="translate(-90 -1776)">
        <rect
          id="Rectangle_449"
          data-name="Rectangle 449"
          width="30"
          height="30"
          transform="translate(90 1776)"
          opacity="0"
        />
        <path
          id="Path_2568"
          data-name="Path 2568"
          d="M23.745,8.66h-.578A10.219,10.219,0,0,0,13.085,0H12.66A10.22,10.22,0,0,0,2.585,8.617H1.894A1.894,1.894,0,0,0,0,10.511v4.723a1.894,1.894,0,0,0,1.894,1.894H3.341c.529,0,1.127-.276,1.106-1.468V10.213A8.223,8.223,0,0,1,12.66,2h.425A8.223,8.223,0,0,1,21.3,10.213V15.66a8.223,8.223,0,0,1-8.213,8.213,1,1,0,0,0,0,2,10.221,10.221,0,0,0,10.089-8.7h.571a1.894,1.894,0,0,0,1.894-1.894V10.554A1.894,1.894,0,0,0,23.745,8.66"
          transform="translate(92 1779)"
        />
        <path
          id="Path_2569"
          data-name="Path 2569"
          d="M12.787,21.6h0a6.83,6.83,0,0,1-6.83-6.83v-4.3a6.83,6.83,0,0,1,13.66,0v4.3a6.83,6.83,0,0,1-6.83,6.83"
          transform="translate(92 1779)"
        />
        <rect
          id="Rectangle_447"
          data-name="Rectangle 447"
          width="25.638"
          height="25.873"
          transform="translate(92 1779)"
          fill="none"
        />
      </g>
    </svg>
  );
};

export default ICSupport;
