import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDashboardMetrics } from "../../services/dashboardService";

// Action creator to call the dashboard metrics function with the payload to invoke the API.
export const fetchDashboardMetrics = createAsyncThunk(
  "dashboard/fetchMetrics",
  () => {
    return getDashboardMetrics();
  }
);
