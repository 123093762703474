/**
 * @file   src\components\HeaderInner.tsx
 * @brief  Inner Header component.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import "./HeaderInner.scss";
import Logo from "../assets/img/Logo.svg";
import ProileDummy from "../assets/img/ProfileDummy.svg";
import { NavDropdown } from "react-bootstrap";
import Strings from "../assets/strings/Strings.json";
import { pageURLs } from "../constants/pageURLs";
import { logout } from "../services/loginService";
import { getLocalStorage, removeLocalStorage } from "../helpers/common";
import { HTTP_RESPONSE_STATUS_200, USER_INFO_KEY } from "../constants/common";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ICEmergencyNoticication from "../assets/img/icons/EmergencyNotification";
import { useAppDispatch, useAppSelector } from "../Redux/hooks";
import { useEffect, useState } from "react";
import { fetchMyProfile } from "../Redux/actions/myAccount";

const HeaderInner = (props: any) => {
  const userDetails = getLocalStorage(USER_INFO_KEY);
  const { setExpand, isExpanded, hamburgerRef } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location?.pathname?.toLowerCase();
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector(
    (state: any) => state?.myAccount?.userProfile?.data
  );
  const [isEmergencyChkInActive, setEmergencyChkIn] = useState<boolean>(false);

  // Handle to dispatch action for the profile API for checking the emergency checkin active or not for showing the notification in the header
  useEffect(() => {
    if (pathName !== pageURLs.myaccount) {
      dispatch(fetchMyProfile());
    }
  }, [pathName]);

  //Set the emergency check in status into the local state
  useEffect(() => {
    if (userProfile?.emergencyCheckin) {
      setEmergencyChkIn(true);
    } else {
      setEmergencyChkIn(false);
    }
  }, [userProfile]);

  //This function will trigget while user click logout and clear localstorage and redux store.
  const handleLogout = async () => {
    if (confirm("Are you sure you want to logout?")) {
      const response = await logout();
      if (response?.status === HTTP_RESPONSE_STATUS_200) {
        removeLocalStorage(USER_INFO_KEY);
        navigate(pageURLs.login);
      }
    }
  };

  return (
    <header className="inner-header d-flex justify-content-between align-items-center position-fixed w-100">
      <div className="logo-wrap d-flex">
        <span
          ref={hamburgerRef}
          onClick={() => setExpand(!isExpanded)}
          className={isExpanded ? "close-icon" : ""}
        ></span>
        <img src={Logo} alt="" onClick={() => navigate(pageURLs.dashboard)} />
      </div>
      <div className="dropdown-wrap d-flex">
        <div className="menu-wrap d-flex align-items-center">
          <NavLink to={pageURLs.dashboard}>
            {Strings.HeaderInner.Dashboard}
          </NavLink>
          <NavLink to={pageURLs.manageusers}>
            {Strings.HeaderInner.Users}
          </NavLink>
          <NavLink to={pageURLs.organization}>
            {Strings.HeaderInner.Organization}
          </NavLink>
          {!userDetails?.isSuperAdminLogin && ( //Hide support option when login from admin launch from super admin portal.
            <NavLink to={pageURLs.support}>
              {Strings.HeaderInner.Support}
            </NavLink>
          )}
        </div>
        <NavDropdown
          title={
            <div className="profile-img">
              <img src={ProileDummy} />
            </div>
          }
        >
          <span className="username text-center d-block">{`${userDetails?.firstName}  ${userDetails?.lastName}`}</span>
          {!userDetails?.isSuperAdminLogin && (
            <NavDropdown.Item onClick={() => navigate(pageURLs.myaccount)}>
              {Strings.HeaderInner.myAccount}
            </NavDropdown.Item>
          )}

          <NavDropdown.Item onClick={() => handleLogout()}>
            {Strings.HeaderInner.logout}
          </NavDropdown.Item>
        </NavDropdown>
      </div>
      {isEmergencyChkInActive && (
        <span className="emergency-icon position-absolute d-flex align-items-center justify-content-center">
          <span>{Strings.HeaderInner.Active}</span>
          <ICEmergencyNoticication />
        </span>
      )}
    </header>
  );
};

export default HeaderInner;
