/**
 * @file   src\containers\GenericNotFound.tsx
 * @brief  404 page.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

const GenericNotFound = () => {
  return <div>404 Error</div>;
};

export default GenericNotFound;
