/**
 * @file   src\containers\Login.tsx
 * @brief  Home page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import HeaderLogin from "../components/HeaderLogin";
import "../assets/styles/Login.scss";
import SLInput from "../components/SLInput";
import Strings from "../assets/strings/Strings.json";
import { Alert, Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import FooterLogin from "../components/FooterLogin";
import { useEffect, useRef, useState } from "react";
import { emailValidation } from "../helpers/validations";
import { login, loginWith2FA, send2FAOtp } from "../services/loginService";
import {
  getCookie,
  removeWhiteSpace,
  setCookie,
  setLocalStorage,
} from "../helpers/common";
import {
  HTTP_RESPONSE_STATUS_200,
  USER_INFO_KEY,
  EMAIL,
  ENTER_KEY_CODE,
  MAX_LENGTH_50,
  MAX_LENGTH_25,
  ALERT_DANGER,
  HTTP_RESPONSE_STATUS_406,
  ALERT_SUCCESS,
  MAX_LENGTH_8,
  ZERO,
} from "../constants/common";
import { pageURLs } from "../constants/pageURLs";
import SLInputIcon from "../components/SLInputIcon";
import {
  INVALID_EMAIL,
  ENTER_EMAIL,
  ENTER_PASSWORD,
  ENTER_2FA_OTP,
} from "../constants/validationMessages";
import { LoginForm, LoginError, LoginRequest } from "../interfaces/Login";
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment";
import AppStoreDetails from "../components/AppStoreDetails";
import { ResponseObjects } from "../interfaces/AxiosResponse";
const Login = () => {
  const navigate = useNavigate();

  // Initialise login form data
  const [loginDetails, setLoginDetails] = useState<LoginForm>({
    email: "",
    password: "",
    twoFactorOTP: "",
  });

  // Initialise login form errors
  const [loginError, setLoginError] = useState<LoginError>({
    emailError: "",
    passwordError: "",
    generalError: "",
    twoFactorOTPError: "",
    successOtpMessage: "",
  });

  const initialResentTime = 121; //Seconds
  const [timeLeft, setTimeLeft] = useState(initialResentTime);
  const [is2FAFlow, set2FAFlow] = useState<boolean>(false);
  const [isChecked, setRememberCheck] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false); // Login Button state
  const [resentLoad, setResentLoad] = useState<boolean>(false); // Resent OTP Button state
  //Initialise recaptcha ref
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  //Initialise contants
  const twoFactorCookieKey = "twoFactorCookie";
  const cookieExpiry = 90; //In days

  //Handle text box changes and update into the state
  const onTextChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const currentId = e.currentTarget.id;
    setLoginDetails((loginDetails) => ({
      ...loginDetails,
      [currentId]: currentId !== EMAIL ? removeWhiteSpace(value) : value,
    }));
  };

  //Handle Remember CheckBox Change
  const handleRememberChkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRememberCheck(e.target.checked);
  };

  // This is for validating the form data before submit into backend
  const validateForm = (isResentOTP?: boolean) => {
    setLoginError((loginError) => ({
      ...loginError,
      emailError: "",
      passwordError: "",
      generalError: "",
      twoFactorOTPError: "",
      successOtpMessage: "",
    }));
    let isValid = true;
    const email: string = loginDetails?.email?.trim();
    const pwd: string = loginDetails?.password?.trim();
    if (email) {
      if (!emailValidation(email)) {
        setLoginError((loginError) => ({
          ...loginError,
          emailError: INVALID_EMAIL,
        }));
        isValid = false;
      }
    }
    if (!email && !pwd) {
      setLoginError((loginError) => ({
        ...loginError,
        emailError: ENTER_EMAIL,
        passwordError: ENTER_PASSWORD,
      }));
      isValid = false;
    } else if (!email) {
      setLoginError((loginError) => ({
        ...loginError,
        emailError: ENTER_EMAIL,
      }));
      isValid = false;
    } else if (!pwd) {
      setLoginError((loginError) => ({
        ...loginError,
        passwordError: ENTER_PASSWORD,
      }));
      isValid = false;
    }

    if (is2FAFlow && !isResentOTP) {
      if (!loginDetails?.twoFactorOTP?.trim()) {
        setLoginError((loginError) => ({
          ...loginError,
          twoFactorOTPError: ENTER_2FA_OTP,
        }));
        isValid = false;
      }
    }

    return isValid;
  };

  //This useeffect for watching the timer for resent OTP
  useEffect(() => {
    if (timeLeft <= 0) return; // If time is up, stop the timer
    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timerId);
  }, [timeLeft]);

  //Clear recaptcha token
  useEffect(() => {
    reCaptchaRef?.current?.reset();
  }, []);

  //2FA Api triggeting function
  const handle2FAauthentication = async (
    reqPayload: LoginRequest,
    isResentOPT?: boolean
  ) => {
    const response = await send2FAOtp(reqPayload);
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      !isResentOPT && set2FAFlow(true);

      setLoginError((loginError) => ({
        ...loginError,
        successOtpMessage: response?.message,
      }));
    } else {
      setLoginError((loginError) => ({
        ...loginError,
        generalError: response?.message,
      }));
    }
    setTimeLeft(initialResentTime);
    setLoad(false);
  };

  //Login with 2FA
  const _loginWith2FA = async (reqPayload: LoginRequest) => {
    const response: ResponseObjects = await loginWith2FA(reqPayload);
    handleLoginResponse(response);
  };

  //Login without 2FA
  const _loginWithout2FA = async (reqPayload: LoginRequest) => {
    const response: ResponseObjects = await login(reqPayload);
    handleLoginResponse(response);
  };

  //Handle login response
  const handleLoginResponse = (response: ResponseObjects) => {
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      const responseData: any = response?.data;
      const accessToken: string = responseData?.accessToken;
      const refToken: string = responseData?.refreshToken;
      const firstName: string = responseData?.firstName;
      const lastName: string = responseData?.lastName;
      const userId: string = responseData?.userId;
      const orgId: string = responseData?.orgId;
      setLocalStorage(USER_INFO_KEY, {
        accessToken: accessToken,
        refreshToken: refToken,
        firstName: firstName,
        lastName: lastName,
        userId: userId,
        orgId: orgId,
      });

      //If remember login checked, then we add the cookies values into browser cookies
      if (isChecked) {
        setCookie(
          twoFactorCookieKey,
          {
            email: loginDetails?.email,
            expiry: moment(new Date()).add(cookieExpiry, "days").toDate(),
          },
          cookieExpiry
        );
      }

      navigate(pageURLs.dashboard);
    } else {
      if (response?.status === HTTP_RESPONSE_STATUS_406) {
        set2FAFlow(false);
      }
      setLoginError((loginError) => ({
        ...loginError,
        generalError: response?.message,
      }));
    }
    setLoad(false);
    setRememberCheck(false);
  };

  //Trigger when resent OTP click for 2FA
  const handleResentOTP = async () => {
    const email = loginDetails?.email?.trim();
    const pwd = loginDetails?.password?.trim();
    const reqPayload: LoginRequest = {
      emailId: email,
      password: pwd,
      recaptchaToken: "",
    };

    const isResentOTP = true;
    const isValid = validateForm(isResentOTP);

    if (isValid) {
      setResentLoad(true);
      const recaptchaToken = await buildRecaptchaToken();
      if (recaptchaToken) {
        reqPayload.recaptchaToken = recaptchaToken;
      }
      const isResentOTP = true;
      await handle2FAauthentication(reqPayload, isResentOTP);
    }
    setResentLoad(false);
  };

  //Function for build recaptch token
  const buildRecaptchaToken = async () => {
    let recaptchaToken = "";
    if (reCaptchaRef.current) {
      recaptchaToken = await reCaptchaRef.current.executeAsync();
    }
    return recaptchaToken;
  };

  //Trigger when submit button is click
  const handleSubmit = async () => {
    const email = loginDetails?.email?.trim();
    const pwd = loginDetails?.password?.trim();
    const reqPayload: LoginRequest = {
      emailId: email,
      password: pwd,
      recaptchaToken: "",
    };
    const isValid: boolean = validateForm();

    if (isValid) {
      setLoad(true);

      const recaptchaToken = await buildRecaptchaToken();
      if (recaptchaToken) {
        reqPayload.recaptchaToken = recaptchaToken;
      }
      const twoFactorCookieValue: any = getCookie(twoFactorCookieKey);
      let is2FARequired = false;

      if (!is2FAFlow) {
        if (twoFactorCookieValue?.email) {
          if (twoFactorCookieValue?.email === email) {
            if (twoFactorCookieValue?.isExpired) {
              is2FARequired = true;
            }
          } else {
            is2FARequired = true;
          }
        } else {
          is2FARequired = true;
        }
      }
      if (is2FARequired) {
        await handle2FAauthentication(reqPayload);
      }
      if (is2FAFlow) {
        reqPayload.otp = loginDetails?.twoFactorOTP?.trim();
      }
      if (!is2FARequired) {
        is2FAFlow
          ? await _loginWith2FA(reqPayload)
          : await _loginWithout2FA(reqPayload);
      }
    }
  };

  //This will trigger when enter key pressa after fill the necessary form data
  const submitFormOnKeyEnter = (event: any) => {
    if (event.keyCode === ENTER_KEY_CODE) {
      event.preventDefault();
      handleSubmit();
    }
  };

  //Showing response message on OTP sent
  const AlertMessage = () => {
    let alertVariant = null;
    let message = null;
    if (loginError?.generalError) {
      alertVariant = ALERT_DANGER;
      message = loginError?.generalError;
    } else if (loginError?.successOtpMessage) {
      alertVariant = ALERT_SUCCESS;
      message = loginError?.successOtpMessage;
    }
    if (message) {
      return (
        <Alert variant={alertVariant} dismissible>
          {" "}
          {message}
        </Alert>
      );
    }
    return null;
  };
  return (
    <>
      <HeaderLogin />
      <div className="login-wrap">
        <div className="form-wrap m-auto">
          <h1 className="text-center">
            <span className="text-uppercase">{Strings.Login.Subtitle}</span>
            <br />
            {Strings.Login.Title}
          </h1>
          <AlertMessage />

          <SLInput
            name="email"
            id="email"
            type="email"
            placeholder={Strings.Login.email}
            onChange={onTextChange}
            errorMessage={loginError?.emailError}
            value={loginDetails?.email}
            required
            maxLength={MAX_LENGTH_50}
          />

          <SLInputIcon
            required
            name="password"
            id="password"
            placeholder={Strings.Login.password}
            onChange={onTextChange}
            errorMessage={loginError?.passwordError}
            value={loginDetails?.password}
            onKeyDown={submitFormOnKeyEnter}
            maxLength={MAX_LENGTH_25}
          />
          {is2FAFlow && (
            <>
              {" "}
              <SLInput
                name="twoFactorOTP"
                id="twoFactorOTP"
                type="text"
                placeholder={Strings.Login.twoFactor}
                onChange={onTextChange}
                errorMessage={loginError?.twoFactorOTPError}
                value={loginDetails?.twoFactorOTP}
                onKeyDown={submitFormOnKeyEnter}
                required
                maxLength={MAX_LENGTH_8}
              />
              <div className="w-100 text-center trouble-login pt-2 pb-3">
                {timeLeft !== ZERO ? (
                  <span>
                    {Strings.Login.CountdownText}{" "}
                    {String(Math.floor(timeLeft / 60)).padStart(2, "0")} :{" "}
                    {String(timeLeft % 60).padStart(2, "0")}s
                  </span>
                ) : (
                  <Link
                    onClick={() => {
                      handleResentOTP();
                    }}
                    to="#"
                  >
                    {resentLoad ? (
                      <output className="spinner-border sm"></output>
                    ) : (
                      Strings.Login.ResendOTP
                    )}
                  </Link>
                )}
              </div>
              <div className="w-100 pt-2 pb-3">
                <Form.Check
                  type="checkbox"
                  id="chkRemember"
                  checked={isChecked}
                  label={Strings.Login.RememberLogin}
                  onChange={handleRememberChkChange}
                />
              </div>
            </>
          )}

          <Button
            variant="primary"
            className="w-100"
            type="submit"
            onClick={() => handleSubmit()}
          >
            {load ? (
              <output className="spinner-border sm"></output>
            ) : (
              Strings.Login.Button
            )}
          </Button>

          <div className="w-100 text-center trouble-login">
            <Link to="/troublelogin">{Strings.Login.TroubleTxt}</Link>
          </div>
        </div>
        <AppStoreDetails />
      </div>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
        size="invisible"
        ref={reCaptchaRef}
      />
      <FooterLogin />
    </>
  );
};

export default Login;
