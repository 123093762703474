/**
 * @file   src\components\common\AddEditNote.tsx
 * @brief  Add/Edit Notes.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import NoteModal from "../NoteModal";
import { HTTP_RESPONSE_STATUS_200, NOTE } from "../../constants/common";
import { AddNote } from "../../interfaces/Note";
import { checkIsHtml } from "../../helpers/validations";
import { ENTER_NOTES, INVALID_NOTES } from "../../constants/validationMessages";

const AddEditNote = (props: AddNote) => {
  const { items, handleSave, id } = props;
  const [show, setShow] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number>(null);
  const [notes, setNotes] = useState<string>("");
  const [notesError, setNotesError] = useState<string>("");
  const [saveClicked, setSaveClicked] = useState<boolean>(false);

  const onTextNoteChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setNotes(value);
  };

  const handleClose = () => {
    setItemId(null);
    setNotes("");
    setNotesError("");
    setShow(false);
    setSaveClicked(false);
  };
  const handleShow = (id?: number, value?: string) => {
    setNotes(value);
    setItemId(id);
    setShow(true);
  };
  const validate = () => {
    setNotesError("");
    let isValid = true;
    const note = notes.trim();
    if (!note) {
      setNotesError(ENTER_NOTES);
      isValid = false;
    } else {
      if (!checkIsHtml(note)) {
        setNotesError(INVALID_NOTES);
        isValid = false;
      }
    }
    return isValid;
  };

  const handleSaveNote = async (id: number, value: string, type?: string) => {
    setSaveClicked(true);
    const isValid = validate();
    if (isValid) {
      const resStatus: number = await handleSave(id, value, type);
      setSaveClicked(false);
      if (resStatus === HTTP_RESPONSE_STATUS_200) {
        handleClose();
      }
    } else setSaveClicked(false);
  };

  return (
    <>
      <td className={!items.toggle ? "addnote" : "addnote expand"}>
        {items.notes ? (
          <span className="d-block">{items.notes}</span>
        ) : (
          <Link
            onClick={() => {
              handleShow(id, "");
            }}
            to=""
          >
            +Add Notes
          </Link>
        )}
        {items.notes && items.toggle && items.notes !== "" && (
          <Button
            variant="primary"
            onClick={() => handleShow(id, items?.notes)}
          >
            Edit
          </Button>
        )}
      </td>
      <NoteModal
        show={show}
        handleClose={handleClose}
        notes={notes}
        errorMessage={notesError}
        onTextNoteChange={onTextNoteChange}
        saveClicked={saveClicked}
        setSaveClicked={setSaveClicked}
        handleSave={() => handleSaveNote(itemId, notes, NOTE)}
      />
    </>
  );
};

export default AddEditNote;
