const ICRemove = () => {
  return (
    <svg
      id="remove"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_456"
        data-name="Rectangle 456"
        width="24"
        height="24"
        fill="none"
      />
      <path
        id="Subtraction_5"
        data-name="Subtraction 5"
        d="M-4573.308-427.795a10.776,10.776,0,0,1-7.669-3.177,10.774,10.774,0,0,1-3.177-7.67,10.858,10.858,0,0,1,10.846-10.845,10.774,10.774,0,0,1,7.669,3.177,10.772,10.772,0,0,1,3.177,7.669,10.774,10.774,0,0,1-3.177,7.67A10.774,10.774,0,0,1-4573.308-427.795Zm-.245-9.449h0l2.361,2.361a1,1,0,0,0,.708.292,1,1,0,0,0,.707-.292,1,1,0,0,0,.293-.708.993.993,0,0,0-.293-.706l-2.362-2.362,2.362-2.362a1,1,0,0,0,.292-.708,1,1,0,0,0-.292-.708.992.992,0,0,0-.707-.293,1,1,0,0,0-.708.293l-2.362,2.362-2.362-2.362a.992.992,0,0,0-.707-.293.989.989,0,0,0-.706.293,1,1,0,0,0-.292.708,1,1,0,0,0,.292.708l2.361,2.362-2.361,2.362a.993.993,0,0,0-.293.706,1,1,0,0,0,.293.708.993.993,0,0,0,.707.292.991.991,0,0,0,.706-.292l2.362-2.361Z"
        transform="translate(4585.308 450.641)"
      />
    </svg>
  );
};

export default ICRemove;
