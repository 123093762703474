const ICShowPassword = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <g id="OpenedEye" transform="translate(-860 -426)">
        <rect
          id="Rectangle_458"
          data-name="Rectangle 458"
          width="28"
          height="28"
          transform="translate(860 426)"
          fill="none"
        />
        <path
          id="Path_2579"
          data-name="Path 2579"
          d="M12,20.75a11.764,11.764,0,0,1-9.319-5A5.092,5.092,0,0,1,1.25,12,5.092,5.092,0,0,1,2.681,8.247,11.764,11.764,0,0,1,12,3.25a11.764,11.764,0,0,1,9.319,5A5.092,5.092,0,0,1,22.75,12a5.092,5.092,0,0,1-1.43,3.753A11.764,11.764,0,0,1,12,20.75Zm0-16A10.318,10.318,0,0,0,3.869,9.162,3.7,3.7,0,0,0,2.75,12a3.7,3.7,0,0,0,1.119,2.838A10.319,10.319,0,0,0,12,19.25a10.319,10.319,0,0,0,8.131-4.412A3.7,3.7,0,0,0,21.25,12a3.7,3.7,0,0,0-1.119-2.838A10.319,10.319,0,0,0,12,4.75Z"
          transform="translate(861.75 427.75)"
          fill="#18345a"
        />
        <path
          id="Path_2580"
          data-name="Path 2580"
          d="M12,8.25A3.75,3.75,0,1,1,8.25,12,3.754,3.754,0,0,1,12,8.25Zm0,6A2.25,2.25,0,1,0,9.75,12,2.253,2.253,0,0,0,12,14.25Z"
          transform="translate(861.75 427.75)"
          fill="#18345a"
        />
      </g>
    </svg>
  );
};

export default ICShowPassword;
