/**
 * @file   src\components\Layout.tsx
 * @brief  Main Layout component.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Outlet, useLocation } from "react-router-dom";
import "./Layout.scss";
import HeaderInner from "./HeaderInner";
import SideBar from "./SideBar";
import FooterInner from "./FooterInner";
import { useEffect, useRef, useState } from "react";

const Layout = () => {
  const [isExpanded, setExpand] = useState<boolean>(false);
  const OVERFLOW_AUTO = "auto";
  const OVERFLOW_HIDDEN = "hidden";
  const INNER_WRAPPER_CLASS = "inner-wrapper";
  const MENU_EXPAND_CLASS = " menu-expand";
  const hamburgerRef = useRef<HTMLDivElement>(null);
  const isResponsiveMode = checkHamburgerVisible(hamburgerRef);
  const location = useLocation();
  const pathName = location?.pathname?.toLowerCase();

  // This function is used for checking the hamburger menu is visible in UI, specifically for responsive view.
  function checkHamburgerVisible(ref: React.RefObject<HTMLDivElement>) {
    const [isIntersecting, setIntersecting] = useState(false);
    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      );
      observer.observe(ref.current);
    }, [ref]);
    return isIntersecting;
  }

  useEffect(() => {
    isResponsiveMode && setExpand(false);
  }, [pathName]);

  const innerWrapperClass = () => {
    // Check side bar expanded, if so change class for hide horizontal scroll.
    if (isExpanded) {
      // Check is responsive mode, if so change class for hide horizontal scroll.
      if (!isResponsiveMode) {
        document.body.style.overflowY = OVERFLOW_AUTO;
        return `${INNER_WRAPPER_CLASS} ${MENU_EXPAND_CLASS}`;
      } else {
        document.body.style.overflowY = OVERFLOW_HIDDEN;
      }
    } else {
      document.body.style.overflowY = OVERFLOW_AUTO;
    }
    return INNER_WRAPPER_CLASS;
  };

  return (
    <>
      <HeaderInner
        setExpand={setExpand}
        isExpanded={isExpanded}
        hamburgerRef={hamburgerRef}
      />
      <div className="main-wrapper d-flex">
        <SideBar
          setExpand={setExpand}
          isExpanded={isExpanded}
          isResponsiveMode={isResponsiveMode}
        />
        <div className={innerWrapperClass()}>
          <Outlet />
          <FooterInner />
        </div>
      </div>
    </>
  );
};

export default Layout;
