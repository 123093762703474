import { createAsyncThunk } from "@reduxjs/toolkit";
import { userListing, userDetails } from "../../services/manageUserService";

import {
  FetchUsersPayload,
  GetUserDetailsPayload,
} from "../../interfaces/ManageUsers";

// Action creator to call the userListing function with a payload to invoke the user listing API.
export const fetchUserList = createAsyncThunk(
  "manageUser/fetchUserList",
  (payload: FetchUsersPayload) => {
    return userListing(payload);
  }
);

// Action creator to call the userDetails function with a payload to invoke the user details API.
export const fetchUserDetails = createAsyncThunk(
  "manageUser/fetchUserDetails",
  (payload?: GetUserDetailsPayload) => {
    return userDetails(payload);
  }
);
