import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  adminUsersListing,
  getSafetyOfficer,
} from "../../services/safetyOfficersServices";

// Action creator to call the adminUsersListing function with a payload to invoke the admin-users listing API.
export const fetchAdminUsersList = createAsyncThunk(
  "safetyOfficers/fetchAdminUsersList",
  () => {
    return adminUsersListing();
  }
);
// Action creator to call the getSafetyOfficer function to invoke the safety officers listing API.
export const fetchSafetyOfficersList = createAsyncThunk(
  "safetyOfficers/fetchSafetyOfficersList",
  () => {
    return getSafetyOfficer();
  }
);
