import {
  SIX,
  TEN,
  THREE,
  workaloneModeDetailsPage,
  ZERO,
} from "../constants/common";

export function emailValidation(value: string) {
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  const result: boolean = pattern.test(value);
  return result;
}

export function phoneNumberValidation(value: string) {
  const pattern = new RegExp(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/);
  const result: boolean = pattern.test(value);
  return result;
}

export const passwordValidation = (value: any) => {
  const uppercaseRegExp = /(?=.*?[A-Z])/;
  const lowercaseRegExp = /(?=.*?[a-z])/;
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  const minLengthRegExp = /.{8,20}/;
  const noSpaceRegExp = /^\S*$/;

  const uppercasePassword = uppercaseRegExp.test(value);
  const lowercasePassword = lowercaseRegExp.test(value);
  const specialCharPassword = specialCharRegExp.test(value);
  const minLengthPassword = minLengthRegExp.test(value);
  const space = noSpaceRegExp.test(value);

  const ENTER_VALID_PASSWORD =
    "Password should contain 8-20 characters, at least one uppercase letter, one lowercase letter, a number, and one special character.";
  let errMsg = "";
  if (
    !minLengthPassword ||
    !uppercasePassword ||
    !lowercasePassword ||
    !specialCharPassword ||
    !space
  ) {
    errMsg = ENTER_VALID_PASSWORD;
  }
  return errMsg;
};

export const phoneFormat = (value: string, page?: string) => {
  let formattedPhoneNumber = value;

  if (value && value !== null) {
    if (value.length <= THREE) {
      formattedPhoneNumber = value;
    } else if (value.length > THREE && value.length <= SIX) {
      if (page === workaloneModeDetailsPage) {
        formattedPhoneNumber = `(${value.slice(ZERO, THREE)}) ${value.slice(THREE, SIX)}`;
      } else {
        formattedPhoneNumber = `${value.slice(ZERO, THREE)}-${value.slice(THREE, SIX)}`;
      }
    } else if (value.length > SIX) {
      if (page === workaloneModeDetailsPage) {
        console.log(page);
        formattedPhoneNumber = `(${value.slice(ZERO, THREE)}) ${value.slice(
          THREE,
          SIX
        )}-${value.slice(SIX, TEN)}`;
      } else {
        formattedPhoneNumber = `${value.slice(ZERO, THREE)}-${value.slice(
          THREE,
          SIX
        )}-${value.slice(SIX, TEN)}`;
      }
    }

    return formattedPhoneNumber;
  }
};

export const nameValidation = (value: string) => {
  const specialCharRegExp = /[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/;
  const alphanumericRegx = /[0-9]+/g;
  const alphanumericTest = alphanumericRegx.test(value);
  const specialCharCheck = specialCharRegExp.test(value);
  let errMsg = "";
  if (specialCharCheck) {
    errMsg = "Special Characters are not allowed.";
  } else if (alphanumericTest) {
    errMsg = "Numbers are not allowed.";
  }
  return errMsg;
};

/* eslint-disable */
export const numberSpecialCharValidation = /^[-\d\(\)\[\]\s]+$/;
export const numberValidation = /\D/;
const htmlValidation = /^[^<>;]+$/;
export const numberOnly = /^[0-9\b]+$/;

export const checkIsHtml = (value: string) => {
  const result: boolean = htmlValidation.test(value);
  return result;
};
