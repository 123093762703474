/**
 * @file   src\containers\HowToUseApp.tsx
 * @brief  This page is for showing the app instructions in moble device web view.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { Col, Image } from "react-bootstrap";
import { useEffect, useState } from "react";
import { isMobileDevice } from "../helpers/common";
import reportIncident from "../assets/img/Report_Incident.png";
import workAloneMode from "../assets/img/work_alone_mode.png";
import safetyOfficer from "../assets/img/saftey_officer.png";
import anonymous from "../assets/img/anonymous_suggestion.png";
import emergencyCheckin from "../assets/img/emergency_checkin.png";
import safetyUpdates from "../assets/img/safetyUpdates.png";
import settingsButton from "../assets/img/settings_btn.png";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../constants/pageURLs";

/* eslint-disable */
const HowToUseApp = () => {
  //Initial States
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(false);
    //Restrict this page for accessing only in moble devices.
    if (!isMobileDevice()) {
      navigate(pageURLs.dashboard);
    }
  }, []);
  return (
    <>
      {isLoading ? (
        <Col className="text-center no-record mt-5">
          <output className="spinner-border"></output>
        </Col>
      ) : (
        <div className="terms_conditions">
          <div >
            <h4 className="mt-0">Core Features</h4>

            <div className=" pb-2">
              <div className=" text-center">
                <Image className={"imgWdth"} src={reportIncident} />
              </div>
              <p className="ps-3 pt-3">
                {" "}
                Open the <b>Report an Incident</b> section of the app to quickly
                report an issue, potential hazard, or near miss to your safety
                team. This is a great way to get issues or potential issues on
                the radar. Based on the information you submit, your safety team
                may ask you to complete further action or documentation.{" "}
              </p>
            </div>

            <div className=" pb-2">
              <div className=" text-center">
                <Image className={"imgWdth"} src={workAloneMode} />
              </div>
              <p className="ps-3 pt-3">
                <b>Work Alone Mode </b>creates regular, voice-activated
                check-ins for lone workers in low- or high-risk environments. If
                workers need assistance or become unresponsive, the app
                automatically alerts the safety team. If you would like to use
                voice commands for this feature, you will need to complete a
                10-minute phone setup here:
                <a
                  href="https://spotlightsafetyapp.com/work-alone-setup/"
                  target="_blank"
                >
                  {" "}
                  https://spotlightsafetyapp.com/work-alone-setup/
                </a>
              </p>
            </div>

            <div className="pb-2">
              <div className=" text-center">
                <Image className={"imgWdth"} src={safetyOfficer} />
              </div>
              <p className="ps-3 pt-3">
                If you need to reach out to a<b> safety officer</b> directly for
                any reason, Contact a Safety Officer is a fast and easy way to
                connect.
              </p>
            </div>

            <div className="pb-2">
              <div className=" text-center">
                <Image className={"imgWdth"} src={anonymous} />
              </div>
              <p className="ps-3 pt-3">
                With the <b>Anonymous Suggestion Box, </b>you can submit
                anything from general ideas for improving the workplace to
                personal issues that you would prefer to share privately.
                Submissions are truly anonymized; the safety team cannot see who
                submits them. In some cases, the safety team may be required to
                share anonymous information with HR, company officials, or local
                law enforcement officials, however, every effort will be taken
                to maintain privacy and care with sensitive information.
              </p>
            </div>

            <div className=" pb-2">
              <div className=" text-center">
                <Image className={"imgWdth"} src={emergencyCheckin} />
              </div>
              <div className="ps-3 pt-3">
                <p>
                  <b>The Emergency Check-In System</b> is a digital roll call
                  that helps the entire organization stay informed in the event
                  of an emergency. If the Emergency Check-In System is activated
                  by your safety team, there will be a push notification on your
                  lock screen and a special button in the app asking you to
                  check in.
                </p>
                <p>
                  Remember to check in promptly; the safety team is monitoring
                  all responses, logging who is safe, who needs help, and who
                  has not yet responded so they can make sure everyone is
                  accounted for. If they don’t hear from you, they may reach
                  out.
                </p>
                <p>
                  If you report that you need help, the information you submit
                  may be shared with your supervisors or first responders,
                  depending on the situation.
                </p>
                <p>
                  After you check in, you will be taken to a screen that shows
                  safety updates and rally points. Keep an eye on this page for
                  posts from your safety team - including information about
                  what’s going on and instructions on whether to shelter in
                  place or head to your rally point. Knowledge is power in
                  crisis situations.
                </p>
              </div>
            </div>

            <h4 className="mt-2">Other Helpful Features</h4>

            <div className=" pb-2">
              <div className=" text-center">
                <Image className={"imgWdth"} src={safetyUpdates} />
              </div>
              <div className="ps-3 pt-3">
                <p>
                  In the <b>Safety Updates section,</b> the safety team can keep
                  the entire organization updated with a range of information,
                  from updates about emergencies to day-to-day safety news and
                  tips. Make sure to check this section on a regular basis. You
                  can also access it through the bell icon at the top of your
                  screen.
                </p>
                <p>
                  <b>Important Links</b> is a useful spot for resources. Your
                  safety team may list commonly used forms, documents, manuals,
                  databases, and other useful information here to make things
                  easily accessible.
                </p>
                <p>
                  <b>The Rally Points </b>section displays your organization’s
                  designated assembly or muster locations during an evacuation.
                  These locations are vital in the case of an emergency
                  evacuation.
                </p>
              </div>
            </div>

            <div className="pb-2">
              <div className=" text-center">
                <Image className={"imgWdth"} src={settingsButton} />
              </div>
              <div className="ps-3 pt-3">
                <p>
                  Finally, you can view your information and{" "}
                  <b>contact support </b>through the Settings gear icon in the
                  upper right corner.
                </p>
                <p>
                  By using the Spotlight Safety app on a regular basis, you will
                  help your team respond effectively to issues and emergencies
                  that occur, as well as prevent issues before they arise. This
                  helps your organization build a strong, proactive safety
                  culture.
                </p>
                <div className=" text-center">
                  <a
                    href="https://spotlightsafetyapp.com/support/"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    Contact Support
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HowToUseApp;
