/**
 * @file   src\components\SortableLinks.tsx
 * @brief  SortableLinks component.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import "./SortableLinks.scss";
import ICSort from "../assets/img/icons/Sort";
import ICEditLink from "../assets/img/icons/EditLink";
import ICDeleteLink from "../assets/img/icons/DeleteLink";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { LIST_SAFETY_PAGE, ONE, ZERO } from "../constants/common";
import { formatPhoneNumber } from "../helpers/common";

const SortableLinks = (props: any) => {
  const { action, list, type, setReorderList, updateOrder } = props;

  // This will trigger while we drag an item in the list
  const dragEnd = (params: any) => {
    const sourceIndex: number = params.source.index;
    const destinationIndex: number = params.destination.index;

    let currentList: any = list;
    currentList.splice(
      destinationIndex,
      ZERO,
      currentList.splice(sourceIndex, ONE)
    );

    currentList = currentList.map((item: any) =>
      Array.isArray(item) ? item[ZERO] : item
    );
    currentList = currentList.map((feature: any, index: number) => {
      return { ...feature, orderNo: index + ONE };
    });

    const updatedOrderList = currentList.sort(
      (a: any, b: any) => a.orderNo - b.orderNo
    );

    setReorderList(updatedOrderList);
    updateOrder(updatedOrderList);
  };

  return (
    <DragDropContext
      onDragEnd={(params) => {
        dragEnd(params);
      }}
    >
      <Droppable droppableId="sortableList">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {list
              .sort((a: any, b: any) => a.orderNo - b.orderNo)
              .map((lst: any, index: number) => {
                return (
                  <Draggable
                    key={index + ONE}
                    draggableId={"sortableList-" + index}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        className="sortable-wrapper d-flex align-items-center"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={index}
                      >
                        <div className="icon-wrap">
                          <ICSort />
                        </div>
                        <div className="detail-wrap d-flex justify-content-between align-items-center">
                          <div className="link-dtls">
                            {type === LIST_SAFETY_PAGE ? (
                              <>
                                {lst?.firstName || lst?.user_firstName}{" "}
                                {lst?.lastName || lst?.user_lastName},
                                <span> {lst?.title}</span>
                                <p className="mb-0 mt-3">
                                  {formatPhoneNumber(lst?.phone)}
                                  {lst?.extension && (
                                    <span>
                                      {" "}
                                      <b>&nbsp; Ext:</b> {lst?.extension}
                                    </span>
                                  )}
                                </p>{" "}
                                <p className="mb-0">{lst?.email}</p>
                              </>
                            ) : (
                              <>
                                {lst?.displayText}
                                <span> (points to {lst?.url})</span>
                                <p className="mb-0 mt-2">{lst?.linkDesc}</p>
                              </>
                            )}
                          </div>
                          <div className="link-actions d-flex">
                            <span
                              className="d-flex align-items-center justify-content-center"
                              onClick={() => action(lst, "edit")}
                            >
                              <ICEditLink />
                            </span>
                            <span
                              className="d-flex align-items-center justify-content-center"
                              onClick={() => action(lst)}
                            >
                              <ICDeleteLink />
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SortableLinks;
